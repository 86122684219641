<template>
  <section
    :id="blok.id"
    v-editable="blok"
    class="activities-choice light-peach-gradient relative flex-grow"
  >
    <div class="relative">
      <div class="mx-auto max-w-3xl w-full p-4 pt-8 pb-20">
        <div class="inline text-3xl md:text-4xl mt-3">
          <span
            v-if="isProfileLandlordWithoutDraft && profileDashboardData?.titleNewUser"
          >
            {{ profileDashboardData.titleNewUser }}<span>,</span>
          </span>
          <span v-else-if="profileDashboardData?.title">
            {{ profileDashboardData.title
            }}<span v-if="profile?.firstName">,{{ ' ' }}</span>
          </span>
          <span v-if="profile?.firstName" class="font-medium">
            {{ profile.firstName }}
          </span>
          <span class="mx-2"> &#x1F44B; </span>
        </div>

        <RichText
          v-if="isProfileLandlordWithoutDraft && profileDashboardData?.description"
          :blok="profileDashboardData?.description"
        />

        <div
          class="flex flex-col mt-4 md:mt-8"
          :class="{ 'flex-col-reverse': isProfileLandlordWithoutDraft }"
        >
          <div v-if="profileDashboardData?.links" class="my-2 md:my-4">
            <h3 class="font-medium">{{ profileDashboardData?.linksTitle }}</h3>

            <div class="grid md:grid-cols-3 gap-2 my-4">
              <div
                v-for="(link, index) in profileDashboardData.links"
                :key="index"
                @click="trackResources(link.trackingSpecifier)"
              >
                <r-button
                  border-color="white"
                  class="flex shadow-lg"
                  color="black"
                  :icon="link.icon"
                  icon-class="mr-3 ml-3"
                  inverted
                  :label="link.activityTitle"
                  shape="sharp"
                  :to="link.url?.cached_url"
                  width="full"
                />
              </div>
            </div>
          </div>
          <div v-if="profileDashboardData?.cards" class="my-2 md:my-4">
            <h3 class="font-medium">{{ profileDashboardData?.cardsTitle }}</h3>

            <div
              class="grid gap-2 my-4"
              :class="{ 'md:grid-cols-2': profileDashboardData?.cards.length >= 2 }"
            >
              <div
                v-for="(card, index) in profileDashboardData.cards"
                :key="index"
                class="p-4 bg-peach-100 rounded-lg shadow-lg"
              >
                <div class="flex items-center gap-4 sm:h-full">
                  <div class="w-16 flex-shrink-0">
                    <img
                      :alt="card?.activityIcon.alt"
                      :src="card?.activityIcon.filename"
                    />
                  </div>

                  <div
                    class="w-full h-full flex gap-3"
                    :class="[
                      profileDashboardData?.cards.length >= 2
                        ? 'flex-col'
                        : 'flex-row items-center',
                    ]"
                  >
                    <div class="flex-grow">
                      <div class="font-medium">
                        {{ card.activityTitle }}
                      </div>
                      <RichText
                        :blok="card.activityDescription"
                        class="text-sm -my-3"
                      />
                    </div>
                    <div
                      class="hidden sm:block"
                      @click="trackResources(card.trackingSpecifier)"
                    >
                      <r-button
                        :label="card.buttonText"
                        size="small"
                        :to="
                          card.url?.param && card.url?.cached_url
                            ? $localizedPath(card.url.cached_url) + card.url.param
                            : card.url?.cached_url
                              ? $localizedPath(card.url.cached_url)
                              : ''
                        "
                        :width="
                          profileDashboardData?.cards.length >= 2 ? 'full' : 'default'
                        "
                      />
                    </div>
                  </div>
                </div>

                <div
                  class="sm:hidden mt-2"
                  @click="trackResources(card.trackingSpecifier)"
                >
                  <r-button
                    :label="card.buttonText"
                    size="small"
                    :to="
                      card.url?.param && card.url?.cached_url
                        ? $localizedPath(card.url.cached_url) + card.url.param
                        : card.url?.cached_url
                          ? $localizedPath(card.url.cached_url)
                          : ''
                    "
                    width="full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="profileDashboardData?.resources" class="my-2 md:my-4">
          <h3 class="font-medium">{{ profileDashboardData?.resourcesTitle }}</h3>

          <div class="grid gap-2 my-4">
            <div
              v-for="(resource, index) in profileDashboardData.resources"
              :key="index"
              class="p-4 bg-white rounded-lg shadow-lg flex items-center cursor-pointer hover:bg-gray-50 hover:shadow-md active:bg-gray-50 active:shadow-md"
              @click="
                [
                  activityItemFunctionality(
                    resource.functionality,
                    resource._uid,
                    resource.link,
                  ),
                  trackResources(resource.trackingSpecifier, resource.functionality),
                ]
              "
            >
              <r-icon class="text-lg mr-3" :icon-name="resource.icon" />
              <div>
                <div class="font-medium">
                  {{ resource.activityTitle }}
                </div>
                <RichText
                  v-if="resource.activityDescription"
                  :blok="resource.activityDescription"
                  class="text-gray-600 -my-4 text-sm"
                />
              </div>

              <r-overlay
                bottom-bar
                size="large"
                :visible="showOverlay && activeModal === resource._uid"
                @close="showOverlay = false"
              >
                <div class="p-4 relative">
                  <div v-for="modalBlok in resource.modalContent" :key="modalBlok._uid">
                    <component
                      :is="modalBlok.component"
                      v-if="modalBlok.component"
                      :key="modalBlok._uid"
                      :blok="modalBlok"
                      class="flex flex-col flex-grow"
                    />
                  </div>
                </div>

                <template #bottom-bar>
                  <r-button
                    display="block"
                    :label="$t('buttons.close')"
                    no-background
                    uppercase
                    @click="showOverlay = false"
                  />
                </template>
              </r-overlay>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ActionLine from '~/components/section/ActionLine';
import { userRole } from '~/utils/constants';

const resourceFunctionality = {
  OPEN_CHAT: 'openChat',
  OPEN_MODAL: 'openModal',
  LINK: 'link',
};

export default {
  name: 'ActivitiesChoice',
  components: {
    ActionLine: ActionLine,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userRoles: userRole,
      showOverlay: false,
      activeModal: null,
    };
  },
  computed: {
    ...mapGetters({
      hasSessionUser: 'users/hasSessionUser',
      userRole: 'users/userRole',
      profile: 'users/profile',
      profileAssociation: 'users/profileAssociation',
      showLiveChat: 'liveChat/showLiveChat',
      showLiveChatLauncher: 'liveChat/showLiveChatLauncher',
    }),

    /* Return activity items for specific user role, default role set to LL */
    profileDashboardData() {
      const userRoleData = this.userRole ? this.userRole : userRole.LANDLORD;

      return this.blok.profileDashboard
        ? this.blok.profileDashboard.find((x) => x.role === userRoleData)
        : [];
    },

    isProfileLandlordWithoutDraft() {
      return (
        this.userRole === userRole.LANDLORD &&
        !this.profileAssociation?.withAgreement &&
        !this.profileAssociation?.withAdvertisement
      );
    },
  },

  beforeMount() {
    if (!this.hasSessionUser) {
      // redirect to home page and open login modal
      const path = this.$localizedPath(this.$router.currentRoute.value.path);
      navigateTo(
        {
          path: '/',
          query: {
            login: 'open',
            redirectUrl: path,
          },
        },
        { external: true },
      );
    } else {
      this.actionGetProfileInfo();
    }
  },

  methods: {
    ...mapActions({
      openChat: 'liveChat/openChat',
      closeChat: 'liveChat/closeChat',
      actionGetProfileInfo: 'users/_getProfile',
      trackDashboardActivity: 'tracker/trackDashboardActivity',
    }),

    activityItemFunctionality(functionality, modalContentId, linkObject) {
      if (functionality === resourceFunctionality.OPEN_CHAT) {
        this.toggleLiveChat();
      } else if (functionality === resourceFunctionality.OPEN_MODAL) {
        this.activeModal = modalContentId;
        this.showOverlay = true;
        if (this.showLiveChat && this.showLiveChatLauncher) this.closeChat();
      } else if (functionality === resourceFunctionality.LINK && linkObject) {
        if (linkObject.linktype === 'story') {
          this.$router.push({
            path: linkObject.cached_url,
          });
        } else if (linkObject.linktype === 'url') {
          window.location.href = linkObject.cached_url;
        }
      }
    },

    // PS! Tracking eventSpecifier have to be defined in Storyblok
    trackResources(eventSpecifier, functionality) {
      const role = this.userRole ? this.userRole : userRole.LANDLORD;
      const roleString = role
        .toLowerCase()
        .replace(/\w/, (firstLetter) => firstLetter.toUpperCase());
      const resourcesString = functionality
        ? `Resources ${functionality
            .replace(/([A-Z][a-z]|[A-Z]+(?=[A-Z]|$))/g, ' $1')
            .replace(/./, (m) => m.toUpperCase())
            .trim()}`
        : '';
      const eventName = functionality
        ? `${roleString} Dashboard: ${resourcesString} ${eventSpecifier}`
        : `${roleString} Dashboard: ${eventSpecifier}`;

      return this.trackDashboardActivity({ eventName: eventName });
    },

    toggleLiveChat() {
      if (!this.showLiveChat && this.showLiveChatLauncher) {
        this.openChat();
      } else if (this.showLiveChat && this.showLiveChatLauncher) {
        this.closeChat();
      }
    },
  },
};
</script>
