<template>
  <div v-if="!isTenantView" class="agreement-alerts flex flex-col gap-2 mb-4">
    <!--    #3 Agreement ending and renewal for PL-->
    <r-alert
      v-if="agreementTerminatedEE"
      :title="$t('agreement.activity_cards.termination.title')"
      :type="alertTypes.WARNING"
    >
      <div>
        {{ $t('agreement.activity_cards.termination.content') }}
        <b>{{ constructDateFromIsoString(agreementFromFirebase.terminationDate) }}</b>
      </div>
    </r-alert>

    <r-alert
      v-if="agreementEndsSoonPL"
      :primary-button-label="
        $t('agreement.activity_cards.agreement_end_soon_pl.button')
      "
      :title="$t('agreement.activity_cards.agreement_end_soon_pl.title')"
      :type="alertTypes.INFORMATIVE"
      @click-primary-button="onClickOpenRenewal"
    >
      <div>
        {{
          $t('agreement.activity_cards.agreement_end_soon_pl.content', {
            endDate: constructDateFromIsoString(agreementFromFirebase.endDate),
          })
        }}
      </div>
    </r-alert>

    <r-alert
      v-if="agreementAnnexInSigningPL"
      :primary-button-label="$t('agreement.activity_cards.annex_in_signing.button')"
      :title="$t('agreement.activity_cards.annex_in_signing.title')"
      :type="alertTypes.INFORMATIVE"
      @click-primary-button="onClickOpenRenewal(annexInSigning.id)"
    >
      <div
        class="whitespace-break-spaces"
        v-html="
          $t('agreement.activity_cards.annex_in_signing.content', {
            annexSignDate: datePlus3Days(annexInSigning?.created),
          })
        "
      ></div>
    </r-alert>

    <r-alert
      v-if="agreementAnnexSignedPL"
      :title="$t('agreement.activity_cards.annex_signed.title')"
      :type="alertTypes.CONFIRMATIVE"
    >
      <div>
        {{
          $t('agreement.activity_cards.annex_signed.content', {
            endDate: constructDateFromIsoString(agreementFromFirebase.endDate),
          })
        }}
      </div>
    </r-alert>

    <!--    #2 Handover accepted with or without feedback PL and EE -->
    <r-alert
      v-if="handoverConfirmedWithFeedback"
      :primary-button-label="
        $t('agreement.activity_cards.handover_has_feedback.button')
      "
      :title="$t('agreement.activity_cards.handover_has_feedback.title')"
      :type="alertTypes.CONFIRMATIVE"
      @click-primary-button="onClickOpenHandoverProtocol"
    >
      <div>{{ $t('agreement.activity_cards.handover_has_feedback.content') }}</div>
    </r-alert>
    <r-alert
      v-if="handoverConfirmed"
      :title="$t('agreement.activity_cards.handover_done.title')"
      :type="alertTypes.CONFIRMATIVE"
    >
      <div>{{ $t('agreement.activity_cards.handover_done.content') }}</div>
    </r-alert>

    <!--    #1 Should clear the mess with agreement statuses but should be calculated server side-->
    <r-alert
      v-if="agreementSignedButNotActiveEE"
      :title="$t('agreement.activity_cards.not_active_yet.title')"
      :type="alertTypes.INFORMATIVE"
    >
      <div>{{ $t('agreement.activity_cards.not_active_yet.content') }}</div>

      <div class="mt-4">
        {{ $t('agreement.activity_cards.not_active_yet.content_data') }}
        <b>{{ constructDateFromIsoString(agreementFromFirebase.startDate) }}</b>
      </div>
    </r-alert>
  </div>
</template>
<script>
import { DateTime } from 'luxon';
import { alertTypes } from '~/components/r-interface-components/utils/constants';
import { constructDateFromIsoString } from '~/utils/dateFromIsoStringConstructor';
import { annexStatus, handoverStatuses } from '~/utils/constants';

export default {
  name: 'AgreementAlerts',

  props: {
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
    agreementHandover: {
      type: Object,
      default: null,
    },
    agreementAnnexes: {
      type: Array,
      default: null,
    },
    isTenantView: {
      type: Boolean,
      default: true,
    },
    isAgreementTypePl: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['clickOpenHandoverProtocol', 'clickOpenRenewal'],

  setup() {
    return {
      constructDateFromIsoString,
    };
  },

  data() {
    return {
      alertTypes,
      handoverStatuses,
    };
  },

  computed: {
    // #1 is EE,
    agreementSignedButNotActiveEE() {
      // TODO Kadri - front expecting status from server side
      return false;
    },
    // #2 handover accepted
    handoverConfirmedWithFeedback() {
      return (
        this.agreementHandover?.handover?.status === handoverStatuses.FINISHED &&
        this.agreementHandover?.feedback &&
        this.isDeadlineDateInRange(this.agreementHandover?.handover?.updated, 7, false)
      );
    },
    handoverConfirmed() {
      return (
        this.agreementHandover?.handover?.status === handoverStatuses.FINISHED &&
        !this.agreementHandover?.feedback &&
        this.isDeadlineDateInRange(this.agreementHandover?.handover?.updated, 7, false)
      );
    },

    // #3 agreement termination
    agreementTerminatedEE() {
      return (
        !this.isAgreementTypePl &&
        this.isDeadlineDateInRange(this.agreementFromFirebase?.terminationDate, 7, true)
      );
    },

    // Pl, 30 days before and no annexes in for current termination date,
    agreementEndsSoonPL() {
      return (
        this.isAgreementTypePl &&
        this.isDeadlineDateInRange(this.agreementFromFirebase?.endDate, 30, true) &&
        !this.hasAnnexForCurrentAgreementEndDate
      );
    },
    // PL has annex in signing
    agreementAnnexInSigningPL() {
      return this.isAgreementTypePl && !!this.annexInSigning;
    },

    agreementAnnexSignedPL() {
      return (
        this.isAgreementTypePl &&
        !!this.agreementAnnexes?.find(
          (annex) =>
            annex?.status === annexStatus.ACTIVE &&
            this.isDeadlineDateInRange(annex?.fileSigned?.created, 7, false),
        )
      );
    },

    hasAnnexForCurrentAgreementEndDate() {
      return this.agreementAnnexes?.some(
        (annex) =>
          new Date(annex.new.endDate) > new Date(this.agreementFromFirebase?.endDate),
      );
    },

    annexInSigning() {
      return this.agreementAnnexes?.find(
        (annex) => annex?.status === annexStatus.IN_SIGNING,
      );
    },
  },

  methods: {
    datePlus3Days(isoDate) {
      const date = DateTime.fromISO(isoDate).setZone('Europe/Warsaw').startOf('day');
      const deadlineDate = date.plus({ days: 3 });

      return isoDate ? this.constructDateFromIsoString(deadlineDate) : '';
    },

    isDeadlineDateInRange(deadlineDateISO, numberOfDays, beforeDeadline) {
      const deadlineDate = DateTime.fromISO(deadlineDateISO);
      const today = DateTime.now().setZone('Europe/Warsaw');
      let startDate, endDate;

      if (beforeDeadline) {
        startDate = deadlineDate.minus({ days: numberOfDays });
        endDate = deadlineDate;
      } else {
        startDate = deadlineDate;
        endDate = deadlineDate.plus({ days: numberOfDays });
      }

      return today >= startDate && today <= endDate;
    },

    onClickOpenHandoverProtocol() {
      this.$emit('clickOpenHandoverProtocol');
    },
    onClickOpenRenewal(annexId) {
      this.$emit('clickOpenRenewal', annexId);
    },
  },
};
</script>
