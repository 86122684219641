<template>
  <section class="profile-agreements bg-gray-50 relative flex-grow">
    <!--                Loading               -->
    <template
      v-if="
        getAgreementsInProgress &&
        savedAgreementsData.length === 0 &&
        savedAgreementsDataComputing
      "
    >
      <r-loader
        class="min-h-1/2-screen flex-grow flex justify-center items-center"
        :phrases="getLoaderArrayString"
        type="animatedLogo"
      />
    </template>
    <!--                Failing               -->
    <template v-else-if="getAgreementsFailed">
      <div
        class="min-h-1/2-screen flex-grow flex justify-center items-center text-error"
      >
        {{ $t('errors.something_wrong') }}
      </div>
    </template>
    <!--                Listing               -->
    <template v-else-if="getAgreementsResponse">
      <!--                Background               -->
      <r-banner :images="blok.headerImage" />

      <div v-if="getAgreementsInProgress" class="relative z-10">
        <r-loader class="fixed w-full" type="animatedBar" />
      </div>

      <div v-editable="blok" class="relative px-4 py-10 md:py-20">
        <!--                Header               -->
        <div
          v-if="blok.headerTitle"
          class="mx-auto max-w-3xl w-full bg-white bg-opacity-75 p-4 mb-8 rounded-lg text-center"
          data-testid="header.profile-agreements.title"
        >
          <h1 v-if="blok.headerTitle" class="text-4xl md:text-5xl font-bold">
            {{ blok.headerTitle }}
          </h1>
          <RichText
            v-if="blok.headerContent"
            :blok="blok.headerContent"
            class="mt-4 md:text-lg font-light -mb-4"
          />
        </div>

        <!--                Agreements add new button            -->
        <div
          v-if="
            blok.agreementsProfileRole.includes('creator') ||
            blok.agreementsProfileRole.includes('landlord')
          "
          class="max-w-5xl m-auto bg-white rounded-lg shadow-lg p-2 mb-2"
        >
          <div class="flex justify-between">
            <r-button
              :color="searchAndFilterOpen ? 'orange' : 'black'"
              icon="filter-list"
              icon-class="px-3"
              :label="$t('sort_filter.sort_and_filter')"
              label-class="font-normal hidden sm:block"
              no-background
              no-padding
              @click="toggleSearchAndFilter()"
            />
            <component
              :is="theBlok.component"
              v-for="theBlok in blok.addNewButton.filter((item) =>
                ['ButtonBlok'].includes(item.component),
              )"
              :key="theBlok._uid"
              :blok="theBlok"
            />
          </div>

          <div v-if="searchAndFilterOpen" class="sm:flex border-t pt-1 mt-1">
            <div class="w-full">
              <r-input
                v-model="agreementSearchString"
                icon="search"
                name="search"
                no-border
                :placeholder="$t('sort_filter.search_by_address_tenant_landlord')"
                @input="
                  agreementSearchString.length >= 2
                    ? emitAgreementTrackerEvent(
                        agreementTrackingEventNames.SEARCH,
                        trackingProcessNames.AGREEMENT,
                        { searchString: agreementSearchString },
                      )
                    : null
                "
              />
            </div>

            <div class="w-full sm:max-w-xs sm:pl-8">
              <r-select
                v-model="selectedSorting"
                hide-keyboard
                name="agreementSorting"
                no-border
                option-label="text"
                :options="selectedSortingOptions"
                @change="
                  emitAgreementTrackerEvent(
                    agreementTrackingEventNames.SELECT_FILTER,
                    trackingProcessNames.AGREEMENT,
                    { sorting: selectedSorting.trackingValue },
                  )
                "
              />
            </div>
          </div>
        </div>
        <!--                Agreements listing           -->
        <div v-if="savedAgreementsData.length > 0" class="max-w-5xl m-auto">
          <div v-if="blok.agreementsProfileRole.includes('tenant')">
            <div
              v-for="agreement in agreementsWhereProfileIsTenant"
              :key="agreement.id"
            >
              <r-agreement-card
                additional-button1-icon="triangle-exclamation"
                :additional-button1-text="
                  signingStatus(agreement.status) ? $t('buttons.problem') : ''
                "
                :address="agreement.addressFirstLine"
                :agreement-created-date="
                  constructDateFromIsoString(agreement.createdDate)
                "
                :agreement-ended-date="
                  constructDateFromIsoString(agreement.terminationDate)
                "
                :agreement-signed-date="
                  constructDateFromIsoString(agreement.signingDate)
                "
                :apartment-number="agreement.addressApartmentNumber"
                :city="agreement.addressCity"
                class="my-4"
                :contract-id="agreement.agreementId"
                :landlord-email="
                  agreement.landlord && agreement.landlord.email
                    ? agreement.landlord.email
                    : ''
                "
                :landlord-name="constructAgreementName(agreement?.landlord)"
                :landlord-phone="
                  agreement.landlord
                    ? constructPhoneNr(
                        agreement.landlord.phoneCountryCode,
                        agreement.landlord.phoneNumber,
                      )
                    : null
                "
                :show-landlord="agreement.landlord !== null"
                :status="agreement.status"
                :view-agreement-text="$t('buttons.view')"
                :view-agreement-url="
                  $localizedPath('/dashboard/agreement/' + agreement.id)
                "
                @additional-button1-click="
                  signingStatus(agreement.status) ? openProblemModal() : null
                "
              />
            </div>
          </div>
          <div
            v-if="
              blok.agreementsProfileRole.includes('landlord') ||
              blok.agreementsProfileRole.includes('creator')
            "
          >
            <div
              v-for="agreement in filteredAgreementsForSearch(
                agreementsWhereProfileIsLandlordAndOrCreator,
              )"
              :key="agreement.id"
            >
              <r-agreement-card
                :additional-button1-text="$t('buttons.duplicate')"
                :additional-button2-icon="
                  beforeSigningStatus(agreement.status)
                    ? 'trash'
                    : signingStatus(agreement.status)
                      ? 'triangle-exclamation'
                      : ''
                "
                :additional-button2-text="
                  beforeSigningStatus(agreement.status)
                    ? $t('buttons.delete')
                    : signingStatus(agreement.status)
                      ? $t('buttons.problem')
                      : ''
                "
                :address="agreement.addressFirstLine"
                :agreement-created-date="
                  constructDateFromIsoString(agreement.createdDate)
                "
                :agreement-ended-date="
                  constructDateFromIsoString(agreement.terminationDate)
                "
                :agreement-signed-date="
                  constructDateFromIsoString(agreement.signingDate)
                "
                :apartment-number="agreement.addressApartmentNumber"
                :city="agreement.addressCity"
                class="my-4"
                :contract-id="agreement.agreementId"
                :landlord-email="
                  agreement.landlord && agreement.landlord.email
                    ? agreement.landlord.email
                    : ''
                "
                :landlord-name="constructAgreementName(agreement?.landlord)"
                :landlord-phone="
                  agreement.landlord
                    ? constructPhoneNr(
                        agreement.landlord.phoneCountryCode,
                        agreement.landlord.phoneNumber,
                      )
                    : null
                "
                :show-landlord="
                  agreement.landlord !== null &&
                  agreement.landlordProfileId !== profileId
                "
                :show-tenant="agreement.tenant !== null"
                :show-tenant-disclaimer="agreement.tenant === null"
                :status="agreement.status"
                :tenant-email="
                  agreement.tenant && agreement.tenant.email
                    ? agreement.tenant.email
                    : ''
                "
                :tenant-name="constructAgreementName(agreement?.tenant)"
                :tenant-phone="
                  agreement.tenant
                    ? constructPhoneNr(
                        agreement.tenant.phoneCountryCode,
                        agreement.tenant.phoneNumber,
                      )
                    : null
                "
                :view-agreement-text="$t('buttons.view')"
                :view-agreement-url="
                  $localizedPath('/dashboard/agreement/' + agreement.id)
                "
                @additional-button1-click="openDuplicateAgreementModal(agreement)"
                @additional-button2-click="
                  beforeSigningStatus(agreement.status)
                    ? openDeleteAgreementModal(agreement)
                    : signingStatus(agreement.status)
                      ? openProblemModal()
                      : null
                "
              />
            </div>
          </div>
        </div>

        <!--                No Agreements for profile role    -->
        <div
          v-if="agreementsCounter === 0 && !savedAgreementsDataComputing"
          class="max-w-5xl m-auto bg-white rounded-lg shadow-lg px-4 py-8 text-center"
        >
          <img
            alt="error"
            class="mx-auto w-52"
            src="/illustrations/rendin-characters/empty-table-woman-standing.png"
          />

          <h3 class="md:text-lg font-medium my-4">
            {{ blok.errorTitle }}
          </h3>
          <RichText
            v-if="blok.errorText"
            :blok="blok.errorText"
            class="mb-6 md:text-lg font-light max-w-2xl mx-auto"
          />
          <component
            :is="theBlok.component"
            v-for="theBlok in blok.errorCtaButton.filter((item) =>
              ['ButtonBlok'].includes(item.component),
            )"
            :key="theBlok._uid"
            :blok="theBlok"
          />
        </div>
      </div>

      <r-overlay
        :prevent-close="copyAgreementInProgress || copyAgreementWithTenantsInProgress"
        size="small"
        :visible="showOverlay"
        @close="closeOverlay()"
      >
        <div class="px-4 sm:px-8 pt-10 pb-4">
          <div v-if="duplicateAgreementData" class="text-center">
            <h3 class="text-xl font-medium mb-2">
              {{ $t('agreement.dialog.duplicate_agreement.title') }}
            </h3>

            <div
              v-if="
                duplicatedAgreementStatusIsSigned &&
                getCountry.toUpperCase() === workflow.POLAND
              "
            >
              <p class="break-words whitespace-pre-line">
                {{
                  $t(
                    'agreement.dialog.duplicate_agreement.duplicate_with_tenant_content',
                  )
                }}
              </p>

              <div
                class="flex flex-col justify-center items-center gap-4 md:gap-2 mt-8 mb-4"
              >
                <r-button
                  border-color="light-gray"
                  color="black"
                  inverted
                  :is-loading="copyAgreementInProgress"
                  size="small"
                  width="full"
                  @click="duplicateAgreement(duplicateAgreementData)"
                  >{{
                    $t('agreement.dialog.duplicate_agreement.start_with_new_tenant')
                  }}
                </r-button>
                <r-button
                  :is-loading="copyAgreementWithTenantsInProgress"
                  size="small"
                  width="full"
                  @click="duplicateAgreement(duplicateAgreementData, true)"
                  >{{
                    $t(
                      'agreement.dialog.duplicate_agreement.duplicate_with_current_tenant',
                    )
                  }}
                </r-button>
              </div>
            </div>

            <div v-else>
              <p class="break-words whitespace-pre-line">
                {{ $t('agreement.dialog.duplicate_agreement.content') }}
              </p>

              <div class="flex justify-center items-center gap-6 mt-8 mb-4">
                <r-button
                  v-if="!copyAgreementInProgress"
                  no-background
                  no-padding
                  size="small"
                  @click="closeOverlay()"
                  >{{ $t('buttons.cancel') }}
                </r-button>
                <r-button
                  :is-loading="copyAgreementInProgress"
                  size="small"
                  @click="duplicateAgreement(duplicateAgreementData)"
                  >{{ $t('buttons.duplicate') }}
                </r-button>
              </div>
            </div>
          </div>
          <div v-else-if="deleteAgreementData" class="text-center">
            <r-icon class="text-2xl text-rendin-500" icon-name="trash" />
            <div v-if="deletedAgreementConfirmation">
              <h3 class="text-xl font-medium mt-8 mb-2">
                {{ $t('agreement.dialog.delete_agreement.confirmation') }}
              </h3>
              <div class="mt-8 mb-4">
                <r-button no-background no-padding @click="closeOverlay()"
                  >{{ $t('buttons.close') }}
                </r-button>
              </div>
            </div>
            <div v-else>
              <h3 class="text-xl font-medium mt-8 mb-2">
                {{ $t('agreement.dialog.delete_agreement.title') }}
              </h3>
              <p>{{ $t('agreement.dialog.delete_agreement.content') }}</p>
              <div class="flex justify-center items-center gap-6 mt-8 mb-4">
                <r-button
                  v-if="!deleteAgreementInProgress"
                  no-background
                  no-padding
                  @click="closeOverlay()"
                  >{{ $t('buttons.cancel') }}
                </r-button>
                <r-button
                  :is-loading="deleteAgreementInProgress"
                  size="small"
                  @click="deleteAgreement(deleteAgreementData)"
                  >{{ $t('buttons.delete') }}
                </r-button>
              </div>
            </div>
          </div>
          <div v-else-if="isProblem" class="text-center">
            <r-icon class="text-2xl text-rendin-500" icon-name="triangle-exclamation" />
            <h3 class="text-xl font-medium mt-8 mb-2">
              {{ $t('agreement.dialog.problem.title') }}
            </h3>
            <p>{{ $t('agreement.dialog.problem.content') }}</p>

            <div class="flex justify-center items-center gap-6 mt-8 mb-4">
              <r-button no-background no-padding @click="closeOverlay()"
                >{{ $t('buttons.cancel') }}
              </r-button>
              <r-button size="small" @click="copy($t('agreement.dialog.problem.cta'))">
                <span ref="copy">{{ $t('buttons.copy_email') }}</span>
              </r-button>
            </div>
          </div>
        </div>
      </r-overlay>
    </template>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import { constructPhoneNr } from '~/utils/phoneNumberConstructor';
import { constructDateFromIsoString } from '~/utils/dateFromIsoStringConstructor';
import {
  agreementTrackingEventNames,
  trackingProcessNames,
  sortingOptionsForTracking,
} from '~/utils/trackerConstants';
import { agreementStatuses, sortingOptions, workflow } from '~/utils/constants';
import { constructAgreementName } from '~/utils/agreementNameConstructor';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProfileAgreements',
  scrollToTop: true,
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      constructPhoneNr,
      constructAgreementName,
      constructDateFromIsoString,
    };
  },

  data: (vm) => {
    return {
      // Component level saved getAgreementResponse data to avoid flickering
      savedAgreementsData: [],
      agreementsWhereProfileIsTenant: [],
      agreementsWhereProfileIsLandlordAndOrCreator: [],
      agreementsCounter: 0,
      showOverlay: false,
      deleteAgreementData: null,
      deletedAgreementConfirmation: false,
      duplicateAgreementData: null,
      copyAgreementWithTenantsInProgress: false,
      isProblem: false,
      savedAgreementsDataComputing: true,
      agreementTrackingEventNames: agreementTrackingEventNames,
      trackingProcessNames: trackingProcessNames,
      agreementSearchString: null,
      searchAndFilterOpen: false,
      selectedSorting: {
        text: vm.$t('sort_filter.newest_first'),
        value: sortingOptions.NEWEST_FIRST,
        trackingValue: sortingOptionsForTracking.NEWEST_FIRST,
      },
      selectedSortingOptions: [
        {
          text: vm.$t('sort_filter.newest_first'),
          value: sortingOptions.NEWEST_FIRST,
          trackingValue: sortingOptionsForTracking.NEWEST_FIRST,
        },
        {
          text: vm.$t('sort_filter.oldest_first'),
          value: sortingOptions.OLDEST_FIRST,
          trackingValue: sortingOptionsForTracking.OLDEST_FIRST,
        },
        {
          text: vm.$t('sort_filter.active_newest_first'),
          value: sortingOptions.ACTIVE_NEWEST_FIRST,
          trackingValue: sortingOptionsForTracking.ACTIVE_NEWEST_FIRST,
        },
        {
          text: vm.$t('sort_filter.active_oldest_first'),
          value: sortingOptions.ACTIVE_OLDEST_FIRST,
          trackingValue: sortingOptionsForTracking.ACTIVE_OLDEST_FIRST,
        },
        {
          text: vm.$t('sort_filter.draft_newest_first'),
          value: sortingOptions.DRAFT_NEWEST_FIRST,
          trackingValue: sortingOptionsForTracking.DRAFT_NEWEST_FIRST,
        },
        {
          text: vm.$t('sort_filter.draft_oldest_first'),
          value: sortingOptions.DRAFT_OLDEST_FIRST,
          trackingValue: sortingOptionsForTracking.DRAFT_OLDEST_FIRST,
        },
        {
          text: vm.$t('sort_filter.in_signing_newest_first'),
          value: sortingOptions.IN_SIGNING_NEWEST_FIRST,
          trackingValue: sortingOptionsForTracking.IN_SIGNING_NEWEST_FIRST,
        },
        {
          text: vm.$t('sort_filter.in_signing_oldest_first'),
          value: sortingOptions.IN_SIGNING_OLDEST_FIRST,
          trackingValue: sortingOptionsForTracking.IN_SIGNING_OLDEST_FIRST,
        },
      ],
      workflow,
    };
  },

  computed: {
    ...mapGetters({
      getAgreementsResponse: 'agreements/getAgreementsResponse',
      getAgreementsInProgress: 'agreements/getAgreementsInProgress',
      getAgreementsFailed: 'agreements/getAgreementsFailed',

      copyAgreementResponse: 'agreements/postAgreementCreateCopyResponse',
      copyAgreementInProgress: 'agreements/postAgreementCreateCopyInProgress',
      copyAgreementFailed: 'agreements/postAgreementCreateCopyFailed',

      deleteAgreementResponse: 'agreements/deleteAgreementResponse',
      deleteAgreementInProgress: 'agreements/deleteAgreementInProgress',
      deleteAgreementFailed: 'agreements/deleteAgreementFailed',

      profileId: 'users/profileId',
      hasSessionUser: 'users/hasSessionUser',

      getLoaderArrayString: 'storyBlokGlobals/getLoaderArrayString',
      getCountry: 'getCountry',
    }),

    duplicatedAgreementStatusIsSigned() {
      const activeStatus = [
        agreementStatuses.READY_TO_SIGN,
        agreementStatuses.PRE_SIGNING,
        agreementStatuses.SIGNED,
        agreementStatuses.ACTIVE,
        agreementStatuses.FINISHED,
      ];

      return activeStatus.includes(this.duplicateAgreementData?.status);
    },
  },

  watch: {
    getAgreementsInProgress(val) {
      if (!val) this.sortAgreementsByProfiles();
    },
  },

  beforeMount() {
    if (!this.hasSessionUser) {
      // redirect to login page and open SignInModal
      const path = this.$localizedPath(this.$router.currentRoute.value.path);
      navigateTo(
        {
          path: '/',
          query: {
            login: 'open',
            redirectUrl: path,
          },
        },
        { external: true },
      );
    }

    this.actionSetLoaderArrayStringFromStoryblok();
  },

  mounted() {
    if (!this.getAgreementsInProgress) this.getAgreements();

    if (this.getAgreementsResponse?.length) {
      this.sortAgreementsByProfiles();
    }
  },

  methods: {
    ...mapActions({
      actionGetAgreements: 'agreements/getAgreements',
      actionCopyAgreement: 'agreements/copyAgreement',
      actionDeleteAgreement: 'agreements/deleteAgreement',
      actionPutAgreement: 'agreements/putAgreement',
      actionAgreementCoTenantAdd: 'agreements/agreementCoTenantAdd',

      actionSetLoaderArrayStringFromStoryblok:
        'storyBlokGlobals/setLoaderArrayStringFromStoryblok',
      actionTrackAgreementFlowEvent: 'tracker/trackAgreementFlowEvent',
    }),

    getAgreements() {
      this.actionGetAgreements()
        .then(() => this.sortAgreementsByProfiles())
        .then(() => (this.savedAgreementsDataComputing = false));
    },

    sortAgreementsByProfiles() {
      this.savedAgreementsData = this.getAgreementsResponse;

      if (this.profileId) {
        this.agreementsWhereProfileIsTenant = [];
        this.agreementsWhereProfileIsLandlordAndOrCreator = [];

        this.savedAgreementsData.forEach((agreement) => {
          if (
            agreement.landlordProfileId === this.profileId &&
            this.blok.agreementsProfileRole.includes('landlord')
          ) {
            this.agreementsCounter++;
            this.agreementsWhereProfileIsLandlordAndOrCreator.push(agreement);
          } else if (
            agreement.creatorProfileId === this.profileId &&
            this.blok.agreementsProfileRole.includes('creator')
          ) {
            this.agreementsCounter++;
            this.agreementsWhereProfileIsLandlordAndOrCreator.push(agreement);
          } else if (
            agreement.tenantProfileId === this.profileId &&
            this.blok.agreementsProfileRole.includes('tenant')
          ) {
            this.agreementsCounter++;
            this.agreementsWhereProfileIsTenant.push(agreement);
          }
        });
      }
    },

    filteredAgreementsForSearch(agreements) {
      let filteredAgreements = agreements;
      if (this.agreementSearchString?.length >= 2) {
        filteredAgreements = agreements.filter((obj) => {
          return (
            obj.addressFirstLine
              ?.toLowerCase()
              .includes(this.agreementSearchString.toLowerCase()) ||
            obj.addressCity
              ?.toLowerCase()
              .includes(this.agreementSearchString.toLowerCase()) ||
            obj.addressStreet
              ?.toLowerCase()
              .includes(this.agreementSearchString.toLowerCase()) ||
            obj.addressHouseNumber
              ?.toLowerCase()
              .includes(this.agreementSearchString.toLowerCase()) ||
            obj.tenant?.firstName
              ?.toLowerCase()
              .includes(this.agreementSearchString.toLowerCase()) ||
            obj.tenant?.lastName
              ?.toLowerCase()
              .includes(this.agreementSearchString.toLowerCase()) ||
            obj.landlord?.firstName
              ?.toLowerCase()
              .includes(this.agreementSearchString.toLowerCase()) ||
            obj.landlord?.lastName
              ?.toLowerCase()
              .includes(this.agreementSearchString.toLowerCase())
          );
        });
      }

      if (this.selectedSorting?.value) {
        const sortedAgreements = cloneDeep(filteredAgreements);

        if (this.selectedSorting.value === sortingOptions.NEWEST_FIRST) {
          return sortedAgreements.sort(
            (a, b) => new Date(b.createdDate) - new Date(a.createdDate),
          );
        } else if (this.selectedSorting.value === sortingOptions.OLDEST_FIRST) {
          return sortedAgreements.sort(
            (a, b) => new Date(a.createdDate) - new Date(b.createdDate),
          );
        } else if (this.selectedSorting.value === sortingOptions.ACTIVE_NEWEST_FIRST) {
          const activeSortedAgreements = sortedAgreements.filter(
            (draft) => draft.status === agreementStatuses.ACTIVE,
          );
          return activeSortedAgreements.sort(
            (a, b) => new Date(b.startDate) - new Date(a.startDate),
          );
        } else if (this.selectedSorting.value === sortingOptions.ACTIVE_OLDEST_FIRST) {
          const activeSortedAgreements = sortedAgreements.filter(
            (draft) => draft.status === agreementStatuses.ACTIVE,
          );
          return activeSortedAgreements.sort(
            (a, b) => new Date(a.startDate) - new Date(b.startDate),
          );
        } else if (this.selectedSorting.value === sortingOptions.DRAFT_NEWEST_FIRST) {
          const activeSortedAgreements = sortedAgreements.filter(
            (draft) => draft.status === agreementStatuses.DRAFT,
          );
          return activeSortedAgreements.sort(
            (a, b) => new Date(b.startDate) - new Date(a.startDate),
          );
        } else if (this.selectedSorting.value === sortingOptions.DRAFT_OLDEST_FIRST) {
          const activeSortedAgreements = sortedAgreements.filter(
            (draft) => draft.status === agreementStatuses.DRAFT,
          );
          return activeSortedAgreements.sort(
            (a, b) => new Date(a.startDate) - new Date(b.startDate),
          );
        } else if (
          this.selectedSorting.value === sortingOptions.IN_SIGNING_NEWEST_FIRST
        ) {
          const activeSortedAgreements = sortedAgreements.filter(
            (draft) => draft.status === agreementStatuses.READY_TO_SIGN,
          );
          return activeSortedAgreements.sort(
            (a, b) => new Date(b.startDate) - new Date(a.startDate),
          );
        } else if (
          this.selectedSorting.value === sortingOptions.IN_SIGNING_OLDEST_FIRST
        ) {
          const activeSortedAgreements = sortedAgreements.filter(
            (draft) => draft.status === agreementStatuses.READY_TO_SIGN,
          );
          return activeSortedAgreements.sort(
            (a, b) => new Date(a.startDate) - new Date(b.startDate),
          );
        }
      }

      return filteredAgreements;
    },

    toggleSearchAndFilter() {
      this.searchAndFilterOpen = !this.searchAndFilterOpen;

      if (this.searchAndFilterOpen) {
        this.emitAgreementTrackerEvent(
          this.agreementTrackingEventNames.OPEN_SORT_AND_FILTER,
          this.trackingProcessNames.AGREEMENT,
        );
      } else {
        this.emitAgreementTrackerEvent(
          this.agreementTrackingEventNames.CLOSE_SORT_AND_FILTER,
          this.trackingProcessNames.AGREEMENT,
        );
      }
    },

    async duplicateAgreement(agreementData, duplicateWithTenants) {
      if (duplicateWithTenants) this.copyAgreementWithTenantsInProgress = true;

      const copyAgreementRequest = await this.actionCopyAgreement({
        id: agreementData.id,
      });
      const duplicatedAgreementId = copyAgreementRequest.response;

      if (duplicateWithTenants) {
        const putTenantToAgreement = () => {
          const agreementWithTenant = {
            tenantProfileId: agreementData.tenantProfileId,
          };

          return this.actionPutAgreement({
            id: duplicatedAgreementId,
            agreement: { ...agreementWithTenant },
          });
        };

        const putTenantPromise = putTenantToAgreement();

        const addCoTenantToAgreement = (coTenantId) => {
          return this.actionAgreementCoTenantAdd({
            agreementId: duplicatedAgreementId,
            profileId: coTenantId,
          });
        };

        const coTenantsProfileIds =
          agreementData?.coTenants?.map((coTenant) => coTenant.profileId) || [];

        const coTenantPromises = coTenantsProfileIds.map((coTenantId) =>
          addCoTenantToAgreement(coTenantId),
        );

        // We can add all id-s simultaneously, so we set them up first and then wait for all to be completed
        await Promise.all([putTenantPromise, ...coTenantPromises]);
      }

      const customProperties = {
        oldAgreementId: agreementData.id,
        newAgreementId: duplicatedAgreementId,
        creationFlow: duplicateWithTenants ? 'DUPLICATE WITH TENANTS' : 'DUPLICATE',
      };

      const agreementDataForNew = {
        id: duplicatedAgreementId,
        creatorProfileId: this.profileId,
        workflow: agreementData.workflow,
      };

      this.emitAgreementTrackerEvent(
        this.agreementTrackingEventNames.CREATE_NEW,
        this.trackingProcessNames.AGREEMENT,
        customProperties,
        agreementDataForNew,
      );
      this.copyAgreementWithTenantsInProgress = false;

      this.$router.push({
        path: this.$localizedPath(`/dashboard/agreement/${duplicatedAgreementId}`),
      });
    },

    openDuplicateAgreementModal(agreementData) {
      this.showOverlay = true;
      this.duplicateAgreementData = agreementData;
    },

    openDeleteAgreementModal(agreementData) {
      this.showOverlay = true;
      this.deleteAgreementData = agreementData;
    },

    openProblemModal() {
      this.showOverlay = true;
      this.isProblem = true;
    },

    closeOverlay() {
      this.showOverlay = false;
      this.deleteAgreementData = null;
      this.duplicateAgreementData = null;
      this.isProblem = false;
      this.deletedAgreementConfirmation = false;
    },

    deleteAgreement(agreementData) {
      // remove deleted agreement from lists & silently update agreements later
      this.agreementsWhereProfileIsLandlordAndOrCreator =
        this.agreementsWhereProfileIsLandlordAndOrCreator.filter(
          (agreement) => agreement.id !== agreementData.id,
        );

      this.actionDeleteAgreement({ id: agreementData.id })
        .then(() => (this.deletedAgreementConfirmation = true))
        .then(() => this.getAgreements());

      const customProperties = {
        deletedAgreementId: agreementData.id,
      };

      this.emitAgreementTrackerEvent(
        this.agreementTrackingEventNames.DELETE,
        this.trackingProcessNames.AGREEMENT,
        customProperties,
        agreementData,
      );
    },

    beforeSigningStatus(status) {
      return status === 'NEW' || status === 'DRAFT';
    },

    signingStatus(status) {
      return status === 'READY_TO_SIGN' || status === 'SIGNED' || status === 'ACTIVE';
    },

    scrollPageUp() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },

    copy(email) {
      const copyTooltip = this.$tippy(this.$refs.copy, {
        trigger: 'manual',
        duration: 500,
        animation: 'fade',
        content: this.$t('notifications.copied_to_clipboard'),
        onShow(instance) {
          setTimeout(function () {
            instance.hide();
          }, 2000);
        },
      });

      navigator.clipboard.writeText(email).then(function () {
        setTimeout(() => copyTooltip.show());
      });
    },

    emitAgreementTrackerEvent(event, processName, customProperties, agreement) {
      this.actionTrackAgreementFlowEvent({
        eventName: event,
        processName: processName,
        agreement: agreement || null,
        props: customProperties,
      });
    },
  },
});
</script>
