<template>
  <r-overlay
    body-classes="flex-h-screen sm:flex-h-auto"
    :bottom-bar="showModal"
    :prevent-close="postAnnexQueueLocked"
    size="small"
    :visible="showModal"
    @close="closeOverlay()"
  >
    <div class="flex flex-col text-center h-full px-4 sm:px-8 pt-10 pb-4">
      <h3 class="font-medium text-lg mb-4">{{ modalTitle }}</h3>
      <div class="whitespace-pre-line text-center">{{ modalContent }}</div>
    </div>
    <template #bottom-bar>
      <div class="p-4 sm:px-12 flex gap-2 flex-col">
        <r-button
          border-color="light-gray"
          :color="postAnnexResponseSuccess !== null ? 'orange' : 'black'"
          display="block"
          inverted
          :label="
            postAnnexResponseSuccess !== null
              ? $t('buttons.close')
              : $t('buttons.cancel')
          "
          size="small"
          @click="closeOverlay"
        />
        <r-button
          v-if="postAnnexResponseSuccess === null"
          display="block"
          :is-loading="postAnnexQueueLocked"
          :label="$t('agreement.annexing.sign_annex')"
          size="small"
          @click="postAnnex"
        />
      </div>
    </template>
  </r-overlay>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { annexStatus } from '~/utils/constants';
import {
  agreementTrackingEventNames,
  trackingProcessNames,
} from '~/utils/trackerConstants';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AnnexSignModal',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
    annexEndDate: {
      type: String,
      default: null,
    },
  },

  emits: ['closeOverlay', 'closeAnnexView'],

  data: () => ({
    modalTitle: null,
    modalContent: null,
    postAnnexResponseSuccess: null,
  }),

  computed: {
    ...mapGetters({
      postAnnexQueueLocked: 'annex/postAnnexQueueLocked',
    }),
  },

  watch: {
    showModal(val) {
      if (val) this.resetModalCopy();
    },
  },

  mounted() {
    this.resetModalCopy();
  },

  methods: {
    ...mapActions({
      actionPostAnnex: 'annex/postAnnex',
      actionGetAnnex: 'annex/getAnnex',
      reportErrorToSentry: 'tracker/reportErrorToSentry',
      actionTrackAgreementFlowEvent: 'tracker/trackAgreementFlowEvent',
    }),

    resetModalCopy() {
      this.modalTitle = this.$t('agreement.annexing.ready_to_sign');
      this.modalContent = this.$t('agreement.annexing.three_days');

      this.postAnnexResponseSuccess = null;
    },

    postAnnex() {
      this.actionPostAnnex({
        agreementId: this.agreementFromFirebase.id,
        endDate: this.annexEndDate,
      }).then((response) => {
        this.postAnnexResponseSuccess = response?.data?.success;

        if (response?.data?.success) {
          this.$emit('closeAnnexView');
          this.emitAgreementTrackerEvent(
            agreementTrackingEventNames.ANNEX_READY_TO_SIGN,
          );

          this.actionGetAnnex({ agreementId: this.agreementFromFirebase.id }).then(
            (getAnnexResponse) => {
              if (
                getAnnexResponse.data?.success &&
                getAnnexResponse.data?.data?.some(
                  (annex) => annex?.status === annexStatus.IN_PROCESSING,
                )
              ) {
                this.pollAnnexStatus();
              }
            },
          );
        } else if (response?.data?.error?.message === 'ANNEX_IN_SIGNING_FOUND') {
          this.modalTitle = this.$t('agreement.annexing.already_signing.title');
          this.modalContent = this.$t('agreement.annexing.already_signing.content');
        } else if (response?.data?.error?.message === 'SECURITY_TENANT_INELIGIBLE') {
          this.modalTitle = this.$t('agreement.annexing.renewal_tenant_bad.title');
          this.modalContent = this.$t('agreement.annexing.renewal_tenant_bad.content');
        } else if (response?.data?.error?.message === 'SECURITY_TENANT_INELIGIBLE') {
          this.modalTitle = this.$t('agreement.annexing.renewal_tenant_bad.title');
          this.modalContent = this.$t('agreement.annexing.renewal_tenant_bad.content');
        } else {
          this.modalTitle = this.$t('agreement.annexing.renewal_something_wrong.title');
          this.modalContent = this.$t(
            'agreement.annexing.renewal_something_wrong.content',
          );
        }
      });
    },

    pollAnnexStatus() {
      let times = 1;

      this.interval = setInterval(async () => {
        if (times < 20) {
          try {
            const response = await this.actionGetAnnex({
              agreementId: this.agreementFromFirebase.id,
            });
            if (
              response?.data?.success &&
              response?.data?.data?.every(
                (annex) => annex?.status !== annexStatus.IN_PROCESSING,
              )
            ) {
              clearInterval(this.interval);
            }
          } catch (error) {
            await this.reportErrorToSentry(
              new Error(
                `ERROR: polling getAnnex for annex status after 'IN_PROCESSING': ${error}`,
              ),
            );
          }
          times++;
        } else {
          clearInterval(this.interval);
        }
      }, 3000);
    },

    closeOverlay() {
      this.$emit('closeOverlay');
    },

    emitAgreementTrackerEvent(event) {
      this.actionTrackAgreementFlowEvent({
        eventName: event,
        processName: trackingProcessNames.AGREEMENT,
        agreement: this.agreementFromFirebase,
      });
    },
  },
});
</script>
