<template>
  <section class="handover-tenant-feedback-view max-w-5xl mx-auto px-4">
    <div class="bg-white shadow-lg rounded-lg p-4 sm:p-8">
      <h3 class="font-medium text-2xl md:text-3xl my-4">
        {{ $t('agreement.handover_v2.feedback_edit.title') }}
      </h3>
      <p class="md:text-xl my-4 md:my-6">
        {{ $t('agreement.handover_v2.feedback_edit.description') }}
      </p>

      <r-textarea
        v-model="feedbackDescription"
        :label="$t('agreement.handover_v2.feedback_edit.description_label')"
        name="tenant-feedback"
        :placeholder="$t('agreement.handover_v2.feedback_edit.description_placeholder')"
        @blur="putHandoverFeedback()"
      />

      <div v-if="savedImagesData?.length > 0" class="flex justify-end my-8">
        <RImageUpload
          :accept="acceptedImageTypes"
          :add-photo-button="$t('image_upload.add_photo_button')"
          @selected-image-data="handleSelectedImages"
        />
      </div>

      <div class="grid md:grid-cols-2 gap-6 my-8">
        <div v-for="file in savedImagesData" :key="file.entityId">
          <RImageUploadCard
            :image-description="file.description"
            :image-description-label="$t('agreement.handover_v2.image_comments')"
            :img-src="file.fileUrl"
            @delete-button-click="deleteFeedbackImage(file.entityId)"
            @on-blur-description="putImageDescription(file.entityId, $event)"
          />
        </div>

        <RImageUpload
          :accept="acceptedImageTypes"
          :browse-button-text="$t('image_upload.click_to_upload')"
          :browse-label-sub-text="$t('image_upload.allowed_file_types')"
          :browse-label-text="$t('image_upload.or_drag_and_drop')"
          class="h-64 sm:h-80"
          :class="{ 'md:col-span-2': !savedImagesData?.length }"
          is-drag-and-drop
          @selected-image-data="handleSelectedImages"
        />
      </div>
    </div>

    <div class="print:hidden flex flex-col sm:flex-row justify-end gap-4 mt-6">
      <r-button
        class="w-full sm:w-auto"
        :label="$t('buttons.save_and_close')"
        @click="saveClick()"
      />
    </div>

    <ImageUploadModal
      :list-of-failed-upload-images="listOfFailedUploadImages"
      :max-image-size-error="
        $t('agreement.handover_v2.image_uploading_modal.max_image_size_error')
      "
      :number-of-loaded-images="numberOfLoadedCount"
      :show-images-max-size-error="showImagesMaxSizeError"
      :show-modal="showImageLoadingModal"
      :total-number-of-images="totalNumberOfImages"
      :uploading-finished="uploadingFinished"
      @cancel-loading="cancelUploading()"
      @close-overlay="closeUploadingModal()"
    />
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { handoverStatuses, handoverEntryTypes } from '~/utils/constants';
import ImageUploadModal from '~/components/section/handover/ImageUploadModal';
import {
  agreementTrackingEventNames,
  trackingProcessNames,
} from '~/utils/trackerConstants';
import { prepareDataForHandoverApiSubmit } from '~/utils/handoverDataConstructor';

export default {
  name: 'HandoverTenantFeedbackView',

  components: {
    ImageUploadModal,
  },
  props: {
    agreementId: {
      type: String,
      default: null,
    },
    agreementHandover: {
      type: Object,
      default: null,
    },
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
    isTenantView: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['saveClick'],

  data: () => ({
    feedbackDescription: null,
    savedImagesData: [],
    totalNumberOfImages: 0,
    numberOfLoadedCount: 0,
    showImageLoadingModal: false,
    handoverStatuses,
    maxImageUploadSize: 7.5 * 1024 * 1024,
    showImagesMaxSizeError: false,
    listOfFailedUploadImages: [],
    uploadingFinished: false,
    feedbackEntityIdFromPost: null,
    // postHandoverImage allowed extension types
    acceptedImageTypes:
      'image/bmp,image/gif,image/jpg,image/jpe,image/jpeg,image/png,.heic,.heif',
  }),

  computed: {
    ...mapGetters({
      postHandoverImageQueueLocked: 'handover/postHandoverImageQueueLocked',
    }),

    handoverStatusCompleted() {
      return this.agreementHandover?.handover?.status === handoverStatuses.COMPLETED;
    },
    feedbackEntityId() {
      return (
        this.agreementHandover?.feedback?.entityId || this.feedbackEntityIdFromPost
      );
    },
  },

  watch: {
    agreementHandover: {
      handler() {
        this.fillEditModeData();
      },
    },
  },

  mounted() {
    this.getHandover();
    this.fillEditModeData();

    this.postHandoverFeedback();
  },

  methods: {
    prepareDataForHandoverApiSubmit,
    ...mapActions({
      actionPostHandover: 'handover/postHandover',
      actionPostHandoverImage: 'handover/postHandoverImage',
      actionPutHandoverImage: 'handover/putHandoverImage',
      actionPutHandover: 'handover/putHandover',
      actionGetHandover: 'handover/getHandover',
      actionDeleteHandover: 'handover/deleteHandover',
      reportErrorToSentry: 'tracker/reportErrorToSentry',
      actionTrackAgreementFlowEvent: 'tracker/trackAgreementFlowEvent',
    }),

    fillEditModeData() {
      this.feedbackDescription = this.agreementHandover?.feedback?.description;

      this.savedImagesData = this.agreementHandover?.feedback?.files?.map((item) => ({
        entityId: item.entityId,
        fileUrl: item.jpeg92FileUrl || item.fileUrl,
        description: item.description,
      }));
    },

    getHandover() {
      return this.actionGetHandover({ agreementId: this.agreementId });
    },

    putHandoverFeedback() {
      const descriptionData = {
        description: this.feedbackDescription,
      };
      const includedData = this.prepareDataForHandoverApiSubmit(
        descriptionData,
        this.feedbackEntityId,
      );

      if (this.feedbackEntityId)
        this.actionPutHandover({
          entityId: this.feedbackEntityId,
          includedData: includedData,
        }).then(() => {
          this.emitAgreementTrackerEvent(agreementTrackingEventNames.HANDOVER_FEEDBACK);
        });
    },

    postHandoverFeedback() {
      // Feedback can be posted only if handover status is COMPLETED and feedback does not exist jet
      const type = handoverEntryTypes.FEEDBACK;
      const descriptionData = {
        description: this.feedbackDescription,
      };
      const includedData = this.prepareDataForHandoverApiSubmit(descriptionData);

      if (this.handoverStatusCompleted && !this.feedbackEntityId)
        this.actionPostHandover({
          type: type,
          agreementId: this.agreementId,
          includedData: includedData,
        }).then((response) => {
          if (response?.data?.success) {
            this.feedbackEntityIdFromPost = response?.data?.data?.entityId;
            this.getHandover();
          }
        });
    },

    postHandoverImage(asBase64, extension, description) {
      const includedData = {
        file: {
          asBase64: asBase64,
          extension: extension,
        },
        description: description,
      };

      return this.actionPostHandoverImage({
        entityId: this.feedbackEntityId,
        includedData,
      });
    },

    deleteFeedbackImage(deletedEntityId) {
      // remove deleted image from display
      this.savedImagesData = this.savedImagesData.filter(
        (img) => img.entityId !== deletedEntityId,
      );

      this.actionDeleteHandover({ entityId: deletedEntityId }).then((response) => {
        if (response.data.success) this.getHandover();
      });
    },

    async handleSelectedImages(imagesData) {
      if (!this.feedbackEntityId) return;

      this.totalNumberOfImages = imagesData?.length;
      this.numberOfLoadedCount = 0;
      this.stopUploadingProcess = false;
      this.showImageLoadingModal = true;
      this.uploadingFinished = false;

      try {
        for (let index = 0; index < imagesData.length; index++) {
          const item = imagesData[index];

          if (this.stopUploadingProcess) {
            break;
          }
          if (item.size > this.maxImageUploadSize) {
            this.showImagesMaxSizeError = true;
            this.listOfFailedUploadImages.push(item.fileName);
            this.emitAgreementTrackerEvent(
              agreementTrackingEventNames.HANDOVER_FILE_SIZE,
            );

            continue;
          }

          const response = await this.postHandoverImage(
            item.base64Data,
            item.fileExtension,
          );
          if (response.data.success) {
            this.numberOfLoadedCount++;
          }
        }

        await this.getHandover()
          .then(() => {
            this.uploadingFinished = true;
          })
          .then(() => {
            this.emitAgreementTrackerEvent(
              agreementTrackingEventNames.HANDOVER_FEEDBACK,
            );
          });
      } catch (error) {
        this.reportErrorToSentry(error);
      }
    },

    cancelUploading() {
      this.stopUploadingProcess = true;
      this.closeUploadingModal();
    },

    closeUploadingModal() {
      this.showImageLoadingModal = false;
      this.showImagesMaxSizeError = false;
      this.listOfFailedUploadImages = [];
      this.totalNumberOfImages = 0;
      this.numberOfLoadedCount = 0;
    },

    saveClick() {
      this.$emit('saveClick');
    },

    emitAgreementTrackerEvent(event) {
      this.actionTrackAgreementFlowEvent({
        eventName: event,
        processName: trackingProcessNames.AGREEMENT,
        agreement: this.agreementFromFirebase,
      });
    },

    putImageDescription(fileId, description) {
      const imageDescription = {
        description: description,
      };
      const includedData = this.prepareDataForHandoverApiSubmit(
        imageDescription,
        fileId,
      );
      this.actionPutHandoverImage({ entityId: fileId, includedData: includedData });
    },
  },
};
</script>
