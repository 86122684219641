<template>
  <r-overlay
    class="handover-tenant-notes-info-modal"
    size="medium"
    :visible="showModal"
    @close="closeOverlay()"
  >
    <div class="bg-peach-200 p-4 pt-12">
      <img
        :alt="$t('agreement.button.add_landlord')"
        class="mx-auto w-52"
        src="public/illustrations/agreed.svg"
      />
    </div>
    <section class="p-4 md:px-8">
      <h2 class="font-medium text-xl mt-8">
        {{ $t('agreement.handover_v2.tenant_notes_info_modal.title_1') }}
      </h2>
      <p class="text-gray-600 break-words whitespace-pre-line mt-2 mb-4">
        {{ $t('agreement.handover_v2.tenant_notes_info_modal.text_1') }}
      </p>

      <h2 class="font-medium text-xl mt-8">
        {{ $t('agreement.handover_v2.tenant_notes_info_modal.title_2') }}
      </h2>
      <p class="text-gray-600 break-words whitespace-pre-line mb-4">
        {{ $t('agreement.handover_v2.tenant_notes_info_modal.text_2') }}
      </p>
    </section>

    <div class="p-4 max-w-sm mx-auto w-full">
      <r-button :label="$t('buttons.got_it')" width="full" @click="closeOverlay()" />
    </div>
  </r-overlay>
</template>
<script>
export default {
  name: 'HandoverTenantNotesInfoModal',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closeOverlay'],

  methods: {
    closeOverlay() {
      this.$emit('closeOverlay');
    },
  },
};
</script>
