<template>
  <section class="agreement-edit-view-signing">
    <div
      v-if="!agreementFromFirebase.signingDate"
      class="bg-white shadow-lg rounded-lg font-bold md:text-lg px-4 sm:px-8 py-6 mb-2"
    >
      {{ $t('agreement.signing_dialog.main_disclaimer') }}
    </div>

    <div class="bg-white shadow-lg rounded-lg p-4 sm:px-8 pb-8 mb-2">
      <div class="text-rendin-700 uppercase font-medium my-2">
        {{ $t('agreement.label.agreement_signing') }}
      </div>

      <div class="font-medium sm:text-lg my-4">
        {{ $t('agreement.signing_dialog.contract_sent_title') }}
      </div>

      <div class="text-gray-600 my-4">
        {{ $t('agreement.signing_dialog.contract_sent_explanation') }}
      </div>

      <div class="border-t border-b py-2">
        <div class="text-sm text-gray-600 mb-2">
          {{ $t('agreement.card.label.landlord') }}
        </div>
        {{ agreementFromFirebase?.landlord?.firstName }}
        {{ agreementFromFirebase?.landlord?.lastName }}

        <div
          v-if="
            agreementFromFirebase.signingDetails &&
            agreementFromFirebase.signingDetails.agreement &&
            agreementFromFirebase.signingDetails.agreement.landlord &&
            agreementFromFirebase.signingDetails.agreement.landlord.signingDate
          "
          class="text-green-500 mt-1"
        >
          {{ $t('agreement.card.label.agreement_signed') }}:
          {{
            constructDateFromIsoString(
              agreementFromFirebase.signingDetails.agreement.landlord.signingDate,
            )
          }}
        </div>
        <div v-else class="text-gray-600 mt-1">
          {{ $t('agreement.label.waiting_to_be_sign') }}
        </div>
      </div>

      <div class="border-b py-2">
        <div class="text-sm text-gray-600 mb-2">
          {{ $t('agreement.card.label.tenant') }}
        </div>
        {{ agreementFromFirebase?.tenant?.firstName }}
        {{ agreementFromFirebase?.tenant?.lastName }}

        <div
          v-if="
            agreementFromFirebase.signingDetails &&
            agreementFromFirebase.signingDetails.agreement &&
            agreementFromFirebase.signingDetails.agreement.tenant &&
            agreementFromFirebase.signingDetails.agreement.tenant.signingDate
          "
          class="text-green-500 mt-1"
        >
          {{ $t('agreement.card.label.agreement_signed') }}:
          {{
            constructDateFromIsoString(
              agreementFromFirebase.signingDetails.agreement.tenant.signingDate,
            )
          }}
        </div>
        <div v-else class="text-gray-600 mt-1">
          {{ $t('agreement.label.waiting_to_be_sign') }}
        </div>
      </div>
    </div>

    <div
      v-if="
        agreementFromFirebase.documents &&
        Object.keys(agreementFromFirebase.documents).length > 0
      "
      class="bg-white shadow-lg rounded-lg p-4 sm:px-8 mb-2"
    >
      <div class="text-rendin-700 uppercase font-medium my-2">
        {{ $t('agreement.label.documents') }} ({{
          Object.keys(agreementFromFirebase.documents).length
        }})
      </div>

      <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-2 mt-6">
        <a
          v-if="agreementFromFirebase.documents.agreement"
          class="bg-white shadow-sm rounded p-3 transform duration-100 ease-in-out hover:scale-105 hover:text-rendin-500 focus:text-rendin-500 text-center"
          :href="agreementFromFirebase.documents.agreement.url"
          target="_blank"
          @click="
            emitAgreementTrackerEvent(agreementTrackingEventNames.DOWNLOAD_DOCUMENT, {
              documentType: 'Agreement',
            })
          "
        >
          <r-icon class="text-rendin-500 text-3xl" icon-name="file-pdf" />
          <div class="my-3 text-md">{{ $t('agreement.label.agreement') }}</div>
        </a>

        <a
          v-if="agreementFromFirebase.documents.certificate"
          class="bg-white shadow-sm rounded p-3 transform duration-100 ease-in-out hover:scale-105 hover:text-rendin-500 focus:text-rendin-500 text-center"
          :href="agreementFromFirebase.documents.certificate.url"
          target="_blank"
          @click="
            emitAgreementTrackerEvent(agreementTrackingEventNames.DOWNLOAD_DOCUMENT, {
              documentType: 'Certificate',
            })
          "
        >
          <r-icon class="text-rendin-500 text-3xl" icon-name="file-pdf" />
          <div class="my-3 text-md">{{ $t('agreement.label.certificate') }}</div>
        </a>
        <a
          v-if="agreementFromFirebase.documents.surety"
          class="bg-white shadow-sm rounded p-3 transform duration-100 ease-in-out hover:scale-105 hover:text-rendin-500 focus:text-rendin-500 text-center"
          :href="agreementFromFirebase.documents.surety.url"
          target="_blank"
          @click="
            emitAgreementTrackerEvent(agreementTrackingEventNames.DOWNLOAD_DOCUMENT, {
              documentType: 'Surety',
            })
          "
        >
          <r-icon class="text-rendin-500 text-3xl" icon-name="file-pdf" />
          <div class="my-3 text-md">{{ $t('agreement.label.surety') }}</div>
        </a>
        <a
          v-if="agreementFromFirebase.documents.handover"
          class="bg-white shadow-sm rounded p-3 transform duration-100 ease-in-out hover:scale-105 hover:text-rendin-500 focus:text-rendin-500 text-center"
          :href="agreementFromFirebase.documents.handover.url"
          target="_blank"
          @click="
            emitAgreementTrackerEvent(agreementTrackingEventNames.DOWNLOAD_DOCUMENT, {
              documentType: 'Handover',
            })
          "
        >
          <r-icon class="text-rendin-500 text-3xl" icon-name="file-pdf" />
          <div class="my-3 text-md">{{ $t('agreement.label.handover_act') }}</div>
        </a>
        <a
          v-if="agreementFromFirebase.documents.container"
          class="bg-white shadow-sm rounded p-3 transform duration-100 ease-in-out hover:scale-105 hover:text-rendin-500 focus:text-rendin-500 text-center"
          :href="agreementFromFirebase.documents.container.url"
          target="_blank"
          @click="
            emitAgreementTrackerEvent(agreementTrackingEventNames.DOWNLOAD_DOCUMENT, {
              documentType: 'Container',
            })
          "
        >
          <r-icon class="text-rendin-500 text-3xl" icon-name="file-signature" />
          <div class="my-3 text-md">{{ $t('agreement.label.document.container') }}</div>
        </a>
      </div>
    </div>

    <!--  Agreement termination accordion -->
    <r-accordion
      class="my-4 shadow-lg"
      icon="file-excel"
      :title="$t('agreement.signing_dialog.agreement_termination_title')"
      title-class="uppercase"
    >
      <div class="sm:px-4">
        <div class="mb-8">
          {{ $t('agreement.signing_dialog.agreement_termination_text') }}
        </div>

        <div
          v-if="
            getGlobalAppButtonLinksAndImages &&
            getGlobalAppButtonLinksAndImages.bloks &&
            getGlobalAppButtonLinksAndImages.bloks.AppButtonBlok
          "
          class="text-center max-w-xs mx-auto mt-4"
        >
          <AppButtonBlok :blok="getGlobalAppButtonLinksAndImages.bloks.AppButtonBlok" />
          <div class="text-gray-600 text-sm mt-4 mb-6">
            {{ $t('agreement.signing_dialog.agreement_termination_disclaimer') }}
          </div>
          <r-button
            :href="
              getGlobalAppButtonLinksAndImages.bloks.AppButtonBlok.web_app_login_link
            "
            :label="$t('agreement.handover_dialog.webapp_link_text')"
            no-background
            no-padding
            target="_blank"
          />
        </div>
      </div>
    </r-accordion>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { constructDateFromIsoString } from '~/utils/dateFromIsoStringConstructor';
import {
  trackingProcessNames,
  agreementTrackingEventNames,
} from '~/utils/trackerConstants';

export default {
  name: 'AgreementEditViewSigning',
  props: {
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
  },
  setup() {
    return {
      constructDateFromIsoString,
    };
  },

  data: () => ({
    agreementTrackingEventNames: agreementTrackingEventNames,
    trackingProcessNames: trackingProcessNames,
  }),

  computed: {
    ...mapGetters({
      getGlobalAppButtonLinksAndImages:
        'storyBlokGlobals/getGlobalAppButtonLinksAndImages',
    }),
  },

  mounted() {
    this.actionGetGlobalAppButtonLinksAndImagesFromStoryblok();
  },

  methods: {
    ...mapActions({
      actionGetGlobalAppButtonLinksAndImagesFromStoryblok:
        'storyBlokGlobals/getGlobalAppButtonLinksAndImagesFromStoryblok',
      actionTrackAgreementFlowEvent: 'tracker/trackAgreementFlowEvent',
    }),

    emitAgreementTrackerEvent(event, customProperties) {
      this.actionTrackAgreementFlowEvent({
        eventName: event,
        processName: this.trackingProcessNames.AGREEMENT,
        agreement: this.agreementFromFirebase,
        props: customProperties,
      });
    },
  },
};
</script>
