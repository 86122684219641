<template>
  <section class="agreement-edit-view">
    <client-only>
      <div v-if="!showPreview">
        <div class="bg-white">
          <div
            class="flex justify-between items-center gap-2 mx-auto max-w-5xl px-4 py-4"
          >
            <div class="sm:hidden">
              <r-button
                border-color="light-gray"
                color="black"
                hover="hover:text-rendin-500 hover:shadow-md"
                icon="arrow-left-long"
                icon-class="pr-3"
                inverted
                :label="$t('buttons.back')"
                :to="$localizedPath('/dashboard/landlord-agreements')"
                width="small"
              />
            </div>
            <div class="hidden sm:block">
              <r-button
                border-color="light-gray"
                color="black"
                hover="hover:text-rendin-500 hover:shadow-md"
                icon="arrow-left-long"
                icon-class="pr-3"
                inverted
                :label="$t('buttons.back_to_agreements')"
                :to="$localizedPath('/dashboard/landlord-agreements')"
                width="small"
              />
            </div>
          </div>
        </div>

        <div class="md:pb-20 mx-auto max-w-5xl px-4 pb-10 pt-4">
          <!--        agreementType select for PL only-->
          <div v-if="openAgreementTypeSelect">
            <AgreementTypeEdit
              :agreement-from-firebase="agreementFromFirebase"
              :agreement-id="agreementId"
              :button-label="$t('buttons.continue')"
              @selected-agreement-type-updated="(type) => selectedAgreementType(type)"
            />
          </div>
          <div v-else>
            <RTabs
              v-model="tabValue"
              data-testid="component.agreement-edit-view.r-tabs"
              sticky-header
              sticky-header-class="agreement-edit-view-tabs-sticky"
              :tabs="constructTabsData"
              @input="scrollPageUp()"
            >
              <template #object>
                <LazyServerErrorsForAgreementStatusChangeToSigning
                  :agreement-validations-from-server="
                    propertyTabValidationServer.errors
                  "
                />
                <AgreementEditViewProperty
                  ref="property"
                  :agreement-from-firebase="agreementFromFirebase"
                  :agreement-id="agreementId"
                  :post-new-agreement-request="postNewAgreement"
                  :selected-agreement-type="selectedAgreementTypeData"
                  :validate="validateProperty"
                  :validation-extra-attention="sentToSigningButtonClicked"
                  @agreement-property-form-validation="
                    (response) => (propertyTabValidation = response)
                  "
                  @request-server-validation="getAgreementValidation"
                />
              </template>

              <template v-if="agreementId" #agreement>
                <LazyServerErrorsForAgreementStatusChangeToSigning
                  :agreement-validations-from-server="
                    agreementTabValidationServer.errors
                  "
                />
                <AgreementEditViewDetails
                  ref="details"
                  :agreement-from-firebase="agreementFromFirebase"
                  :agreement-id="agreementId"
                  :agreement-services-and-utilities-from-firebase="
                    agreementServicesAndUtilitiesFromFirebase
                  "
                  :force-open-utilities-and-services="
                    forceOpenDetailsUtilitiesAndServices
                  "
                  :validate="validateDetails"
                  :validation-extra-attention="sentToSigningButtonClicked"
                  @agreement-details-form-validation="
                    (response) => (agreementTabValidation = response)
                  "
                  @agreement-type-changed="sentToSigningButtonClicked = true"
                  @move-to-tab="(tabName) => moveToTab(tabName)"
                  @request-server-validation="getAgreementValidation"
                  @request-tabs-validation="validateTabs()"
                  @utilities-and-services-modal-closed="
                    forceOpenDetailsUtilitiesAndServices = false
                  "
                />
              </template>

              <template v-if="agreementId" #parties>
                <LazyServerErrorsForAgreementStatusChangeToSigning
                  :agreement-validations-from-server="partiesTabValidationServer.errors"
                />
                <AgreementEditViewParties
                  ref="parties"
                  :agreement-from-firebase="agreementFromFirebase"
                  :agreement-id="agreementId"
                  :validate="validateParties"
                  :validation-extra-attention="sentToSigningButtonClicked"
                  @agreement-parties-form-validation="
                    (response) => (partiesTabValidation = response)
                  "
                  @request-server-validation="getAgreementValidation"
                  @request-tabs-validation="validateTabs()"
                />
              </template>

              <template v-if="agreementId" #signing-info>
                <AgreementEditViewSigningInfo
                  ref="signingInfo"
                  :agreement-from-firebase="agreementFromFirebase"
                  :agreement-id="agreementId"
                  :selected-agreement-type-data="selectedAgreementTypeData"
                  @open-sharing-modal="openSharingTutorialModal()"
                />
              </template>

              <template v-if="agreementId" #preview>
                <AgreementPreview
                  :agreement-from-firebase="agreementFromFirebase"
                  :agreement-id="agreementId"
                  :agreement-services-and-utilities-from-firebase="
                    agreementServicesAndUtilitiesFromFirebase
                  "
                />
              </template>

              <template v-if="agreementId" #signing>
                <AgreementEditViewSigning
                  :agreement-from-firebase="agreementFromFirebase"
                />
              </template>

              <template v-if="agreementId" #handover>
                <AgreementEditViewHandover
                  :agreement-from-firebase="agreementFromFirebase"
                  :agreement-handover="agreementHandover"
                  :agreement-id="agreementId"
                  :is-tenant-view="isTenantView"
                  :validate="validateHandover"
                  @agreement-handover-form-validation="
                    (response) => (handoverTabValidation = response)
                  "
                  @open-handover-edit="$emit('openHandoverEdit')"
                  @open-handover-protocol="$emit('openHandoverProtocol')"
                />
              </template>
            </RTabs>

            <div
              v-if="isAgreementStatusDraft"
              class="flex flex-col sm:flex-row justify-between items-center gap-4 pt-6"
            >
              <r-button
                border-color="white"
                class="w-full sm:w-auto"
                color="black"
                :disabled="tabValue === tabsName.OBJECT"
                inverted
                :label="$t('buttons.back')"
                @click="previousTab()"
              />
              <r-button
                v-if="
                  !agreementFromFirebase || agreementFromFirebase?.status === 'DRAFT'
                "
                class="w-full sm:w-auto"
                data-testid="button.agreement.finalize"
                :is-loading="validationLoading"
                :label="
                  tabValue === tabsName.SIGNING_INFO
                    ? $t('buttons.preview_and_sign')
                    : $t('buttons.next')
                "
                width="wide"
                @click="
                  tabValue === tabsName.SIGNING_INFO
                    ? validateFormsAndProceed()
                    : nextTab()
                "
              />
            </div>

            <div
              v-else-if="!agreementId"
              class="flex flex-col sm:flex-row justify-between items-center gap-4 pt-6"
            >
              <r-button
                class="w-full sm:w-auto"
                icon="file-pdf"
                icon-class="mr-2"
                inverted
                :is-loading="pdfButtonLoading"
                :label="$t('buttons.pdf_preview')"
                target="_blank"
                width="wide"
                @click="linkToAgreementPreviewPdf()"
              />
              <r-button
                v-if="
                  !agreementFromFirebase || agreementFromFirebase?.status === 'DRAFT'
                "
                class="w-full sm:w-auto"
                data-testid="button.agreement.continue"
                :is-loading="validationLoading || postAgreementInProgress"
                :label="$t('buttons.continue')"
                width="wide"
                @click="
                  agreementFromFirebase
                    ? validateFormsAndProceed()
                    : validatePropertyAndPostNewAgreement()
                "
              />
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="showPreview" class="mx-auto max-w-5xl px-4 py-4">
        <r-button
          border-color="light-gray"
          class="mb-6"
          color="black"
          hover="hover:text-rendin-500 hover:shadow-md"
          icon="arrow-left-long"
          icon-class="sm:pr-3"
          inverted
          :label="$t('buttons.back_to_agreement')"
          @click="[(showPreview = false), scrollPageUp()]"
        />

        <div class="bg-white p-4 rounded-lg shadow-lg my-2">
          <h3 class="mb-4 font-medium">
            {{ $t('agreement.landlord_dialog.preview_disclaimer_title') }}
          </h3>
          <p>{{ $t('agreement.landlord_dialog.preview_disclaimer') }}</p>
        </div>
        <agreement-preview
          :agreement-from-firebase="
            agreementFromFirebase ? agreementFromFirebase : null
          "
          :agreement-id="agreementId ? agreementId : null"
          :agreement-services-and-utilities-from-firebase="
            agreementServicesAndUtilitiesFromFirebase
          "
        />

        <div class="bg-white p-4 rounded-lg shadow-lg my-2">
          <h3 class="mb-4 font-medium">
            {{ $t('agreement.landlord_dialog.preview_next_title') }}
          </h3>
          <p>
            {{ $t('agreement.landlord_dialog.preview_next') }}
          </p>
        </div>

        <div class="sm:max-w-sm m-auto my-4">
          <span
            @click="emitAgreementTrackerEvent(agreementTrackingEventNames.OPEN_PREVIEW)"
          >
            <r-button
              v-if="agreementFromFirebase.agreementId"
              border-color="light-gray"
              color="black"
              :href="agreementPreviewPdfTrustfulLink()"
              icon="file-lines"
              icon-class="pr-2"
              inverted
              :label="$t('buttons.agreement_preview')"
              target="_blank"
              width="full"
            />
          </span>
        </div>

        <div
          class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 sm:pt-6"
        >
          <r-button
            class="w-full sm:w-auto"
            :label="$t('buttons.back_to_editing')"
            no-background
            @click="[(showPreview = false), scrollPageUp()]"
          />

          <r-button
            class="w-full sm:w-auto"
            data-testid="button.agreement.sign-agreement"
            :label="$t('buttons.sign_agreement')"
            @click="showFinalizeModal = true"
          />
        </div>
      </div>

      <LazyCantProceedModal
        :show-modal="showCantProceedModal"
        @close-overlay="cantProceedModalClose()"
      />

      <LazyFinalizeModal
        :show-modal="showFinalizeModal"
        @close-overlay="showFinalizeModal = false"
        @send-agreement-to-signing-click="agreementStatusChangeToSigning()"
      />

      <LazySentToSigningModal
        :loading="sentToSigningModalLoading"
        :prevent-close-modal="sentToSigningModalPreventClose"
        :show-modal="showSentToSigningModal"
        @close-overlay="showSentToSigningModal = false"
      />

      <!-- Tutorial modal for sharing agreement PDF -->
      <LazyAgreementSharingTutorialModal
        v-if="sharingTutorialModalOpen"
        :show-modal="sharingTutorialModalOpen"
        @close-overlay="sharingTutorialModalOpen = false"
        @next-button-click="openSharingModal()"
      />

      <!-- Sharing modal for agreement PDF -->
      <LazyRSharingModal
        v-if="agreementFromFirebase"
        :description="parseFullAddressFromAddressParameters(agreementFromFirebase)"
        :modal-button-text="$t('web_sharing_modal.copy_link')"
        :modal-title="$t('web_sharing_modal.share_this_link')"
        :selected-networks="sharingModalNetworksForAgreement"
        :show-modal="sharingModalOpen"
        :title="parseFullAddressFromAddressParameters(agreementFromFirebase)"
        :url="agreementPreviewPdfTrustfulLink()"
        @close-overlay="sharingModalOpen = false"
        @copy-link-click="
          trackInviteLinkCopyEvent({
            objectType: trackingProcessNames.AGREEMENT_DRAFTING,
            openingLocation: sharingModalLocation,
            channel: 'Link',
          })
        "
        @custom-web-sharing-modal-open="
          trackInviteLinkOpenSharingEvent({
            objectType: trackingProcessNames.AGREEMENT_DRAFTING,
            openingLocation: sharingModalLocation,
            modalType: sharingModalTypes.DESKTOP_SHARE_MODAL,
          })
        "
        @native-web-sharing-modal-open="
          trackInviteLinkOpenSharingEvent({
            objectType: trackingProcessNames.AGREEMENT_DRAFTING,
            openingLocation: sharingModalLocation,
            modalType: sharingModalTypes.MOBILE_NATIVE_SHARE,
          })
        "
        @social-network-popup-open="
          (network) =>
            trackInviteLinkCopyEvent({
              objectType: trackingProcessNames.AGREEMENT_DRAFTING,
              openingLocation: sharingModalLocation,
              channel: network,
            })
        "
      />
    </client-only>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { parseFullAddressFromAddressParameters } from '~/utils/addressCollector';
import AgreementEditViewProperty from '~/components/section/agreement-components/AgreementEditViewProperty';
import AgreementEditViewParties from '~/components/section/agreement-components/AgreementEditViewParties';
import AgreementEditViewDetails from '~/components/section/agreement-components/AgreementEditViewDetails';
import AgreementEditViewSigningInfo from '~/components/section/agreement-components/AgreementEditViewSigningInfo';
import AgreementPreview from '~/components/section/agreement-components/AgreementPreview';
import AgreementEditViewHandover from '~/components/section/agreement-components/AgreementEditViewHandover';
import AgreementEditViewSigning from '~/components/section/agreement-components/AgreementEditViewSigning';
import CantProceedModal from '~/components/section/agreement-components/CantProceedModal';
import FinalizeModal from '~/components/section/agreement-components/FinalizeModal';
import SentToSigningModal from '~/components/section/agreement-components/SentToSigningModal';
import ServerErrorsForAgreementStatusChangeToSigning from '~/components/section/agreement-components/ServerErrorsForAgreementStatusChangeToSigning';
import AgreementTypeEdit from '~/components/section/agreement-components/AgreementTypeEdit.vue';
import AgreementSharingTutorialModal from '~/components/section/agreement-components/AgreementSharingTutorialModal';
import {
  trackingProcessNames,
  agreementTrackingEventNames,
} from '~/utils/trackerConstants';
import {
  agreementTypes,
  agreementStatuses,
  sharingModalTypes,
  workflow,
  isoLanguageCode,
  sharingModalNetworksForAgreement,
  agreementSignedStatuses,
} from '~/utils/constants';
import { agreementValidationErrorTranslation } from '~/composables/agreementValidationTranslationsConstructor';
import { defineComponent } from 'vue';

const tabsName = {
  OBJECT: 'object',
  PARTIES: 'parties',
  AGREEMENT: 'agreement',
  SIGNING_INFO: 'signing-info',
  PREVIEW: 'preview',
  SIGNING: 'signing',
  HANDOVER: 'handover',
};

export default defineComponent({
  name: 'AgreementEditView',

  components: {
    AgreementEditViewParties,
    AgreementEditViewProperty,
    AgreementEditViewDetails,
    AgreementEditViewSigningInfo,
    AgreementPreview,
    AgreementEditViewSigning,
    AgreementEditViewHandover,
    AgreementTypeEdit,
    FinalizeModal,
    CantProceedModal,
    ServerErrorsForAgreementStatusChangeToSigning,
    SentToSigningModal,
    AgreementSharingTutorialModal,
  },
  scrollToTop: true,

  props: {
    agreementId: {
      type: String,
      default: null,
    },
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
    agreementServicesAndUtilitiesFromFirebase: {
      type: Array,
      default: null,
    },
    agreementHandover: {
      type: Object,
      default: null,
    },
    isTenantView: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['openHandoverProtocol', 'openHandoverEdit'],

  setup() {
    return {
      parseFullAddressFromAddressParameters,
    };
  },

  data() {
    return {
      propertyTabValidation: {
        success: false,
        error: '',
      },
      partiesTabValidation: {
        success: false,
        error: '',
      },
      agreementTabValidation: {
        success: false,
        error: '',
      },
      handoverTabValidation: {
        success: false,
        error: '',
      },

      showPreview: false,
      showCantProceedModal: false,
      showFinalizeModal: false,
      showSentToSigningModal: false,
      sentToSigningModalLoading: false,
      sentToSigningModalPreventClose: true,

      validateProperty: 0,
      validateParties: 0,
      validateDetails: 0,
      validateHandover: 0,

      sentToSigningButtonClicked: false,
      agreementTrackingEventNames,
      trackingProcessNames,

      selectedAgreementTypeData: null,
      agreementTypes,
      sharingTutorialModalOpen: false,
      sharingModalOpen: false,
      sharingModalTypes,
      forceOpenDetailsUtilitiesAndServices: false,
      sharingModalLocation: 'Agreement Edit Header',
      pdfButtonLoading: false,
      validationLoading: false,
      postNewAgreement: 0,
      sharingModalNetworksForAgreement,
      tabsName,
      propertyTabValidationServer: {
        success: true,
        errors: [],
        error: '',
      },
      partiesTabValidationServer: {
        success: true,
        errors: [],
        error: '',
      },
      agreementTabValidationServer: {
        success: true,
        errors: [],
        error: '',
      },
    };
  },

  computed: {
    ...mapGetters({
      getAgreementResponse: 'agreements/getAgreementResponse',
      getAgreementInProgress: 'agreements/getAgreementInProgress',
      postAgreementInProgress: 'agreements/postAgreementInProgress',
      isENVIsProduction: 'isENVIsProduction',
      country: 'getCountry',
      getLocale: 'getLocale',
    }),

    openAgreementTypeSelect() {
      return (
        (!this.agreementFromFirebase ||
          this.agreementFromFirebase?.status === agreementStatuses.DRAFT) &&
        !this.selectedAgreementTypeData &&
        this.country === 'pl'
      );
    },

    isAgreementStatusDraft() {
      return (
        this.agreementFromFirebase?.status === agreementStatuses.NEW ||
        this.agreementFromFirebase?.status === agreementStatuses.DRAFT
      );
    },

    constructTabsData() {
      let tabs = null;
      if (!this.agreementId || this.isAgreementStatusDraft) {
        tabs = [
          {
            id: tabsName.OBJECT,
            title: this.$t('agreement.label.property'),
            titleClass:
              this.propertyTabValidation.success &&
              this.propertyTabValidationServer.success
                ? 'text-rendin-500'
                : '',
            iconClass:
              this.propertyTabValidation.success &&
              this.propertyTabValidationServer.success
                ? 'agreementTabValid'
                : '',
            icon: 'building',
            text:
              this.agreementValidationErrorTranslation(
                this.propertyTabValidationServer.error,
              ) || this.propertyTabValidation.error,
            textClass:
              this.propertyTabValidation.success &&
              this.propertyTabValidationServer.success
                ? 'text-gray-900'
                : this.sentToSigningButtonClicked
                  ? 'text-error'
                  : 'text-gray-600',
          },
          {
            id: tabsName.PARTIES,
            title: this.$t('agreement.label.add_parties'),
            titleClass:
              this.partiesTabValidation.success &&
              this.partiesTabValidationServer.success
                ? 'text-rendin-500'
                : '',
            iconClass:
              this.partiesTabValidation.success &&
              this.partiesTabValidationServer.success
                ? 'agreementTabValid'
                : '',
            icon: 'user-group',
            disabled: !this.agreementFromFirebase,
            text:
              this.agreementValidationErrorTranslation(
                this.partiesTabValidationServer.error,
              ) || this.partiesTabValidation.error,
            textClass:
              this.partiesTabValidation.success &&
              this.partiesTabValidationServer.success
                ? 'text-gray-900'
                : this.sentToSigningButtonClicked
                  ? 'text-error'
                  : 'text-gray-600',
          },
          {
            id: tabsName.AGREEMENT,
            title: this.$t('agreement.label.agreement'),
            titleClass:
              this.agreementTabValidation.success &&
              this.agreementTabValidationServer.success
                ? 'text-rendin-500'
                : '',
            icon: 'file-contract',
            iconClass:
              this.agreementTabValidation.success &&
              this.agreementTabValidationServer.success
                ? 'agreementTabValid'
                : '',
            disabled: !this.agreementFromFirebase,
            text:
              this.agreementValidationErrorTranslation(
                this.agreementTabValidationServer.error,
              ) || this.agreementTabValidation.error,
            textClass:
              this.agreementTabValidation.success &&
              this.agreementTabValidationServer.success
                ? 'text-gray-900'
                : this.sentToSigningButtonClicked
                  ? 'text-error'
                  : 'text-gray-600',
          },
          {
            id: tabsName.SIGNING_INFO,
            title: this.$t('agreement.label.signing'),
            icon: 'file-signature',
            disabled: !this.agreementFromFirebase,
            text: this.agreementId ? this.$t('agreement.signing_tab.signing_sub') : '',
            textClass: 'text-gray-600',
          },
        ];
      } else {
        tabs = [
          {
            id: tabsName.PREVIEW,
            title: this.$t('agreement.label.agreement'),
            icon: 'file-contract',
            iconClass: 'agreementTabValid',
            text: this.$t('agreement.label.agreement_you_are_signing'),
            titleClass: 'text-rendin-500',
          },
          {
            id: tabsName.SIGNING,
            title: this.$t('agreement.label.signing'),
            icon: 'pen',
            iconClass:
              this.agreementFromFirebase.status === 'SIGNED' ? 'agreementTabValid' : '',
            text: !this.agreementFromFirebase.signingDate
              ? this.$t('agreement.label.add_signatures_to_proceed')
              : this.$t('agreement.label.agreement_signed'),
            titleClass:
              this.agreementFromFirebase.status === 'SIGNED' ? 'text-rendin-500' : '',
          },
          {
            id: tabsName.HANDOVER,
            title: this.$t('agreement.label.handover_act'),
            titleClass: this.handoverTabValidation.success ? 'text-rendin-500' : '',
            icon: 'handshake-angle',
            iconClass: this.handoverTabValidation.success ? 'agreementTabValid' : '',
            text: this.handoverTabValidation.error,
          },
        ];
      }

      return tabs;
    },
    tabValue: {
      get() {
        if (
          this.agreementFromFirebase?.status === agreementStatuses.READY_TO_SIGN ||
          this.agreementFromFirebase?.status === agreementStatuses.PRE_SIGNING
        ) {
          return this.$route.query.tab || tabsName.SIGNING;
        } else if (
          agreementSignedStatuses.includes(this.agreementFromFirebase?.status)
        ) {
          return this.$route.query.tab || tabsName.HANDOVER;
        }
        return this.$route.query.tab || tabsName.OBJECT;
      },
      set(value) {
        const query = {
          tab: value,
        };

        this.$router
          .replace({
            name: this.$route.name,
            params: { ...this.$route.params },
            query: { ...this.$route.query, ...query },
          })
          .catch(() => {
            /* DUMMY CATCH */
            // TODO - better solution, needed because we don't check if query has changed or not (same path throws error from Vue router)
          });
      },
    },
  },

  watch: {
    tabValue(val, oldVal) {
      this.actionGetAgreement({ id: this.agreementId }).then(() => {
        this.validateTabs();
      });

      this.emitAgreementTrackerEvent(
        this.tabEventName(val),
        this.previousTabProperty(oldVal),
      );
    },
  },

  mounted() {
    if (this.agreementFromFirebase) {
      // if agreementTypeFromAgreement not set for PL show type select
      if (this.agreementFromFirebase.agreementTypeFromAgreement) {
        this.selectedAgreementTypeData =
          this.agreementFromFirebase.agreementTypeFromAgreement;
      } else if (this.country === 'ee') {
        if (
          this.agreementFromFirebase.agreementTypeFromResolver &&
          this.agreementFromFirebase.agreementTypeFromResolver !==
            this.agreementTypes.UNKNOWN
        ) {
          this.selectedAgreementTypeData =
            this.agreementFromFirebase.agreementTypeFromResolver;
        } else {
          this.selectedAgreementTypeData = this.agreementTypes.EE_BASIC;
        }

        if (this.agreementId) {
          const typeData = { agreementType: this.selectedAgreementTypeData };
          this.actionPutAgreement({
            id: this.agreementId,
            agreement: { ...typeData },
          }).then(() => this.actionGetAgreement({ id: this.agreementId }));
        }
      }
    }

    if (this.openAgreementTypeSelect) {
      this.emitAgreementTrackerEvent(
        this.agreementTrackingEventNames.OPEN_AGREEMENT_TYPE_CHOICE,
        { location: 'New' },
      );
    }
  },

  methods: {
    agreementValidationErrorTranslation,
    ...mapActions({
      actionPutAgreementStatus: 'agreements/putAgreementStatus',
      actionGetAgreement: 'agreements/getAgreement',
      actionTrackAgreementFlowEvent: 'tracker/trackAgreementFlowEvent',
      actionReportErrorToSentry: 'tracker/reportErrorToSentry',
      actionPutAgreement: 'agreements/putAgreement',
      trackInviteLinkOpenSharingEvent: 'tracker/trackInviteLinkOpenSharingEvent',
      trackInviteLinkCopyEvent: 'tracker/trackInviteLinkCopyEvent',
      actionGetAgreementValidation: 'agreements/getAgreementValidation',
    }),

    validateFormsAndProceed() {
      this.validationLoading = true;
      let property = null;
      let details = null;
      let parties = null;
      let server = null;

      if (this.agreementId)
        this.actionGetAgreement({ id: this.agreementId })
          .then(async () => {
            property = await this.$refs.property.validateForm();
            details = await this.$refs.details.validateForm();
            parties = this.$refs.parties.validateAgreementParties();
            server = await this.getAgreementValidation();

            return (
              property.success && details.success && parties.success && server.success
            );
          })
          .then((success) => {
            this.validationLoading = false;
            this.scrollPageUp();
            if (success) {
              this.showPreview = true;
            } else {
              this.showCantProceedModal = true;
              this.sentToSigningButtonClicked = true;

              // front validation failed tracking
              const properties = {
                validationErrors: {
                  property: property.success,
                  parties: parties.success,
                  details: details.success,
                },
              };
              this.emitAgreementTrackerEvent(
                this.agreementTrackingEventNames.READY_TO_SIGN_VALIDATION_FAILED,
                properties,
              );
            }
          })
          .finally(() => {
            this.validationLoading = false;
          });
    },

    validatePropertyAndPostNewAgreement() {
      this.validationLoading = true;
      this.validateProperty++;
      this.sentToSigningButtonClicked = true;

      if (this.propertyTabValidation.success) {
        this.postNewAgreement++;
      } else {
        this.scrollPageUp();
        this.validationLoading = false;
      }
    },

    agreementStatusChangeToSigning() {
      this.showFinalizeModal = false;
      this.showSentToSigningModal = true;
      this.sentToSigningModalLoading = true;

      this.actionPutAgreementStatus({
        agreementId: this.agreementId,
        toPreSigning: true,
      })
        .then((response) => {
          // if response without errors
          if (!response.response) {
            this.sentToSigningModalLoading = false;
            this.emitAgreementTrackerEvent(
              this.agreementTrackingEventNames.READY_TO_SIGN,
            );

            this.actionGetAgreement({ id: this.agreementId }).then(
              (agreementResponse) => {
                if (
                  agreementResponse.response[0].status ===
                    agreementStatuses.READY_TO_SIGN ||
                  agreementResponse.response[0].status === agreementStatuses.PRE_SIGNING
                )
                  this.tabValue = tabsName.SIGNING;
                if (agreementResponse.response[0].status === agreementStatuses.SIGNED)
                  this.tabValue = tabsName.HANDOVER;
              },
            );
          } else {
            this.showSentToSigningModal = false;
            const properties = {
              validationErrors: response.response,
            };
            this.emitAgreementTrackerEvent(
              this.agreementTrackingEventNames.READY_TO_SIGN_VALIDATION_FAILED,
              properties,
            );

            throw new Error(
              `putAgreementStatus toPreSigning response - ${JSON.stringify(response.response)}`,
            );
          }
        })
        .catch((error) => {
          this.actionReportErrorToSentry(error);
        })
        .finally(() => {
          this.sentToSigningModalPreventClose = false;
          this.showPreview = false;
          this.scrollPageUp();
        });
    },

    scrollPageUp() {
      window.scrollTo({
        top: 80,
      });
    },

    linkToAgreementPreviewPdf() {
      if (this.agreementId && this.agreementFromFirebase?.agreementId) {
        window.open(this.agreementPreviewPdfTrustfulLink(), '_blank');
      } else if (this.agreementId) {
        this.pdfButtonLoading = true;
        this.actionGetAgreement({ id: this.agreementId }).then(() => {
          this.pdfButtonLoading = false;

          if (this.agreementFromFirebase?.agreementId) {
            window.open(this.agreementPreviewPdfTrustfulLink(), '_blank');
          } else {
            window.open(this.agreementDemoPdfLink(), '_blank');
          }
        });
      } else {
        window.open(this.agreementDemoPdfLink(), '_blank');
      }

      this.emitAgreementTrackerEvent(this.agreementTrackingEventNames.OPEN_PREVIEW);
    },

    agreementDemoPdfLink() {
      const getCloudHttpRequestURLApiType =
        'https://europe-west1-rendin.cloudfunctions.net/getDocumentPreview/AGREEMENT/';
      const lang = isoLanguageCode[this.getLocale];
      const country = this.country.toUpperCase();
      const format = 'format=html';

      if (this.selectedAgreementTypeData && this.country === 'pl') {
        return `${getCloudHttpRequestURLApiType + lang}?workflow=${country}&specifier=${this.selectedAgreementTypeData}&${format}`;
      } else {
        return `${getCloudHttpRequestURLApiType + lang}?${format}`;
      }
    },

    agreementPreviewPdfTrustfulLink() {
      if (this.agreementId && this.agreementFromFirebase?.agreementId) {
        const host = window.location.host;
        const agreementId = this.agreementFromFirebase.agreementId;
        const id = this.agreementId.substring(0, 7);
        const secureProtocol = host.includes('localhost:') ? 'http://' : 'https://';
        return `${secureProtocol + host}/agreement_preview/${agreementId}/${id}`;
      }
    },

    moveToTab(tabName) {
      this.tabValue = tabName;
    },

    validateTabs() {
      this.validateProperty++;
      this.validateParties++;
      this.validateDetails++;
      this.validateHandover++;

      // validate agreement server
      if (this.sentToSigningButtonClicked) this.getAgreementValidation();
    },

    tabEventName(tabName) {
      let eventName = '';

      if (tabName) {
        if (tabName === tabsName.OBJECT)
          eventName = this.agreementTrackingEventNames.OPEN_PROPERTY_TAB;
        else if (tabName === tabsName.AGREEMENT)
          eventName = this.agreementTrackingEventNames.OPEN_AGREEMENT_DETAILS_TAB;
        else if (tabName === tabsName.PARTIES)
          eventName = this.agreementTrackingEventNames.OPEN_PARTIES_TAB;
        else if (tabName === tabsName.SIGNING_INFO)
          eventName = this.agreementTrackingEventNames.OPEN_SIGNING_INFO;
        else if (tabName === tabsName.PREVIEW)
          eventName = this.agreementTrackingEventNames.OPEN_AGREEMENT_TAB;
        else if (tabName === tabsName.SIGNING)
          eventName = this.agreementTrackingEventNames.OPEN_SIGNING_TAB;
        else if (tabName === tabsName.HANDOVER)
          eventName = this.agreementTrackingEventNames.OPEN_HANDOVER_TAB;
      }

      return eventName;
    },

    previousTabProperty(previousTab) {
      let previousTabName = '';

      if (previousTab) {
        if (previousTab === tabsName.OBJECT)
          previousTabName = this.agreementTrackingEventNames.PROPERTY_TAB;
        else if (previousTab === tabsName.AGREEMENT)
          previousTabName = this.agreementTrackingEventNames.DETAILS_TAB;
        else if (previousTab === tabsName.PARTIES)
          previousTabName = this.agreementTrackingEventNames.PARTIES_TAB;
        else if (previousTab === tabsName.PREVIEW)
          previousTabName = this.agreementTrackingEventNames.AGREEMENT_TAB;
        else if (previousTab === tabsName.SIGNING)
          previousTabName = this.agreementTrackingEventNames.SIGNING_TAB;
        else if (previousTab === tabsName.HANDOVER)
          previousTabName = this.agreementTrackingEventNames.HANDOVER_TAB;
      }

      return { previousTab: previousTabName };
    },

    emitAgreementTrackerEvent(event, customProperties) {
      this.actionTrackAgreementFlowEvent({
        eventName: event,
        processName:
          this.agreementFromFirebase &&
          (this.agreementFromFirebase.status === agreementStatuses.READY_TO_SIGN ||
            this.agreementFromFirebase.status === agreementStatuses.PRE_SIGNING ||
            this.agreementFromFirebase.status === agreementStatuses.SIGNED)
            ? trackingProcessNames.AGREEMENT
            : trackingProcessNames.AGREEMENT_DRAFTING,
        agreement: this.agreementFromFirebase,
        props: customProperties,
      });
    },

    selectedAgreementType(type) {
      this.selectedAgreementTypeData = type;
    },

    openSharingTutorialModal() {
      this.sharingTutorialModalOpen = true;
      this.emitAgreementTrackerEvent(
        this.agreementTrackingEventNames.OPEN_AGREEMENT_SHARING_MODAL,
      );
    },

    openSharingModal() {
      this.sharingModalOpen = true;
    },

    cantProceedModalClose() {
      this.showCantProceedModal = false;
      this.showPreview = false;

      // TODO add better UX and validation errors
      // Check services  and utilities are missing open services and utilities modal
      if (
        !this.agreementServicesAndUtilitiesFromFirebase?.length &&
        this.agreementFromFirebase.workflow === workflow.ESTONIA
      ) {
        this.tabValue = tabsName.AGREEMENT;
        this.forceOpenDetailsUtilitiesAndServices = true;
      }
    },

    previousTab() {
      const activeTabIndex = this.constructTabsData.findIndex(
        (el) => el.id === this.tabValue,
      );
      const tab = activeTabIndex > 1 ? activeTabIndex - 1 : 0;
      this.tabValue = this.constructTabsData[tab].id;
      this.scrollPageUp();
    },

    nextTab() {
      const maxActiveTab = this.constructTabsData.length - 1;
      const activeTabIndex = this.constructTabsData.findIndex(
        (el) => el.id === this.tabValue,
      );
      const tab = activeTabIndex < maxActiveTab ? activeTabIndex + 1 : maxActiveTab;
      this.tabValue = this.constructTabsData[tab].id;
      this.scrollPageUp();
    },

    getAgreementValidation() {
      return this.actionGetAgreementValidation({ agreementId: this.agreementId })
        .then((response) => {
          return response?.response?.success
            ? this.divideServerValidationIntoTabs(response?.response?.data?.validation)
            : false;
        })
        .catch((err) => this.actionReportErrorToSentry(err));
    },

    divideServerValidationIntoTabs(data) {
      const objectErrors = [];
      const partiesErrors = data?.parties?.length > 0 ? data?.parties : [];
      const agreementErrors = [];

      if (data?.data?.length > 0) {
        data?.data.forEach((error) => {
          if (error.type.includes('address')) {
            objectErrors.push(error);
          } else {
            agreementErrors.push(error);
          }
        });
      }
      if (data?.logic?.length > 0) agreementErrors.push(...data?.logic);

      this.propertyTabValidationServer.success = objectErrors?.length === 0;
      this.propertyTabValidationServer.errors = objectErrors;
      this.propertyTabValidationServer.error = objectErrors?.[0];

      this.partiesTabValidationServer.success = partiesErrors?.length === 0;
      this.partiesTabValidationServer.errors = partiesErrors;
      this.partiesTabValidationServer.error = partiesErrors?.[0];

      this.agreementTabValidationServer.success = agreementErrors?.length === 0;
      this.agreementTabValidationServer.errors = agreementErrors;
      this.agreementTabValidationServer.error = agreementErrors?.[0];

      const noErrorsFromServer =
        objectErrors.length === 0 &&
        partiesErrors.length === 0 &&
        agreementErrors.length === 0;

      return {
        success: noErrorsFromServer,
      };
    },
  },
});
</script>

<style lang="scss">
.agreementTabValid {
  position: relative;

  &::after {
    content: '';
    background-image: url('../../../assets/icons/checkmark.svg');
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: center;
    width: 16px;
    height: 16px;
    position: absolute;
    background-color: #10b981;
    border-radius: 50%;
    color: white;
    top: -6px;
    right: -10px;
    display: block;
  }
}

.agreement-edit-view-tabs-sticky {
  max-width: calc(100vw - 2rem);

  //for component with wrapper .max-w-5xl .px-4
  @media screen and (min-width: 64rem) {
    max-width: calc(64rem - 2rem);
  }

  @apply bg-gray-100;
}
</style>
