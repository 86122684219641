<template>
  <client-only>
    <div class="py-4 relative">
      <div
        v-if="localizationBlok"
        class="flex justify-between items-center gap-1 py-3 px-4"
      >
        <p class="text-lg font-medium">{{ localizationBlok.title }}</p>
        <button
          class="text-sm font-medium"
          :class="
            notifications && notifications.length > 0
              ? 'text-rendin-500 hover:text-rendin-300 cursor-pointer'
              : 'text-gray-600 cursor-default'
          "
          @click="clearAll"
        >
          {{ localizationBlok.buttonClearAll }}
        </button>
      </div>
      <!--  Notification  -->
      <div>
        <template v-if="notifications.length === 0">
          <div v-if="localizationBlok" class="flex flex-col items-center p-4 pt-8">
            <img
              alt="Bell"
              src="/images/buddy-bell.png"
              style="height: 125px; width: 100px"
            />
            <p class="text-2xl text-center pt-8">
              {{ localizationBlok.noNotificationsTitle }}
            </p>
            <p class="text-md text-center pt-6 text-gray-600">
              {{ localizationBlok.noNotificationsSubtitle }}
            </p>
          </div>
        </template>
        <template v-else>
          <template
            v-for="notification in notifications"
            :key="notification.notificationId"
          >
            <NotificationNewCandidate
              v-if="notification.notificationType === notificationTypes.NEW_CANDIDATE"
              :notification="notification"
              :notification-blok="
                getNotificationLocalization('NotificationNewCandidate')
              "
              @click="onClickNotification(notification)"
            />
            <NotificationFillProfile
              v-else-if="
                notification.notificationType === notificationTypes.FILL_PROFILE
              "
              :notification="notification"
              :notification-blok="
                getNotificationLocalization('NotificationFillProfile')
              "
              @click="onClickNotification(notification)"
            />
          </template>
          <template v-if="fetchNotificationsInProgress">
            <div v-for="i in 10" :key="i">
              <div class="flex-1 p-4 flex gap-4">
                <div class="flex flex-1 flex-col gap-2 items-stretch">
                  <div class="bg-gray-300 animate-pulse h-4 rounded-full"></div>
                  <div class="bg-gray-300 animate-pulse h-4 rounded-full"></div>
                </div>
                <div class="bg-gray-300 animate-pulse h-4 rounded-full w-8"></div>
              </div>
            </div>
          </template>
        </template>
      </div>
      <div
        ref="lazyLoadObserver"
        class="absolute bottom-0 w-full"
        style="height: 20%; z-index: -9999"
      ></div>
    </div>
  </client-only>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { notificationTypes } from '~/utils/constants';
import NotificationNewCandidate from '~/components/snippets/notifications/NotificationNewCandidate.vue';
import NotificationFillProfile from '~/components/snippets/notifications/NotificationFillProfile.vue';

export default {
  name: 'ActivityHub',
  components: { NotificationFillProfile, NotificationNewCandidate },
  emits: ['close'],
  data() {
    return {
      notificationTypes: notificationTypes,
      observer: undefined,
    };
  },
  computed: {
    ...mapGetters({
      /**
       * @type {Notification[]}
       */
      notifications: 'notifications/notifications',
      uninteractedNotifications: 'notifications/uninteractedNotifications',
      fetchNotificationsInProgress: 'notifications/fetchNotificationsInProgress',
      hasFetchedAllAvailableNotifications:
        'notifications/hasFetchedAllAvailableNotifications',
      localizationBlok: 'notifications/localizationBlok',
      locale: 'getLocale',
      profile: 'users/profile',
    }),
  },
  watch: {
    /* refresh localizations on locale change */
    locale() {
      this.fetchLocalizations();
    },
    profile() {
      /* Attach listener when profile becomes available */
      this.init();
    },
  },
  mounted() {
    this.$nextTick(() => {
      /* Observer will be responsible for loading new events, when user has scrolled down 70% */
      this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach(() => {
            this.onBottomVisible();
          });
        },
        {
          root: null,
          threshold: 0,
        },
      );

      this.observer.observe(this.$refs.lazyLoadObserver);
    });

    this.init();
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    ...mapActions({
      fetchNotifications: 'notifications/fetchNotifications',
      fetchNewNotifications: 'notifications/fetchNewNotifications',
      fetchLocalizations: 'notifications/fetchLocalizations',
      markNotificationsAsInteracted: 'notifications/markNotificationsAsInteracted',
      trackActivityHubClearAll: 'tracker/trackActivityHubClearAll',
      trackActivityHubClickNotification: 'tracker/trackActivityHubClickNotification',
      init: 'notifications/init',
    }),
    /* For future reference - currently decided not to cache this / make this computed. Please observe what happens with hundreds of different notifications */
    getNotificationLocalization(notificationName) {
      if (!this.localizationBlok || !this.localizationBlok.notifications) return {};
      const blok = this.localizationBlok.notifications.find(
        (blok) => blok.component === notificationName,
      );

      return blok || {};
    },
    clearAll() {
      this.trackActivityHubClearAll();

      const uninteractedNotificationIds = this.uninteractedNotifications.map(
        (notification) => notification.notificationId,
      );
      this.markNotificationsAsInteracted({
        notificationIds: uninteractedNotificationIds,
      });

      this.$emit('close');
    },
    onClickNotification(notification) {
      this.trackActivityHubClickNotification({
        type: notification.notificationType,
        notificationId: notification.notificationId,
        created: notification.created,
        firstInteractedTime: notification.data.notification.firstInteractedTime,
      });

      if (!notification.data.notification.firstInteractedTime) {
        this.markNotificationsAsInteracted({
          notificationIds: [notification.notificationId],
        });
      }

      this.$emit('close');
    },
    onBottomVisible() {
      if (
        !this.hasFetchedAllAvailableNotifications &&
        !this.fetchNotificationsInProgress
      ) {
        this.fetchNotifications();
      }
    },
  },
};
</script>
