<template>
  <r-overlay
    body-classes="flex-h-screen sm:flex-h-auto"
    class="landlord-handover-confirmation-modal"
    :prevent-close="isPutHandoverInProgress"
    size="small"
    :visible="showModal"
    @close="closeOverlay()"
  >
    <div
      v-if="agreementHandover?.handover?.status === handoverStatuses.READY_TO_SEND"
      class="mt-8 flex flex-col text-center h-full p-4 sm:px-12"
    >
      <div class="flex-grow flex flex-col justify-center mb-8">
        <r-icon class="text-4xl text-rendin-500" icon-name="circle-exclamation" />

        <h3 class="font-medium text-xl mt-6 mb-4">
          {{ $t('agreement.handover_v2.landlord_confirm.title') }}
        </h3>
        <div class="inline text-gray-600">
          {{ $t('agreement.handover_v2.landlord_confirm.content') }}
          <span
            class="text-rendin-500 underline hover:text-rendin-700 focus:text-rendin-700"
            @click="openHandoverTenantNotesInfoModalClick"
          >
            {{ $t('agreement.handover_v2.landlord_confirm.content_here') }}</span
          >.
        </div>
      </div>
      <div class="flex gap-2 flex-col">
        <r-button
          display="block"
          inverted
          :label="$t('agreement.handover_v2.landlord_confirm.button.back')"
          @click="openHandoverEdit()"
        />
        <r-button
          display="block"
          :is-loading="isPutHandoverInProgress"
          :label="$t('agreement.handover_v2.landlord_confirm.button.send')"
          @click="putHandoverStatusCompleted()"
        />
      </div>
    </div>
    <div
      v-else-if="agreementHandover?.handover?.status === handoverStatuses.COMPLETED"
      class="h-full flex flex-col"
    >
      <div class="bg-peach-200 p-4 pt-12">
        <img
          :alt="$t('agreement.button.add_landlord')"
          class="mx-auto w-52"
          src="public/illustrations/person-with-check.svg"
        />
      </div>
      <div class="flex flex-col grow text-center p-4 sm:px-12">
        <div class="flex flex-col grow mb-8">
          <h3 class="text-2xl md:text-2xl mt-6 mb-4">
            {{ $t('agreement.handover_v2.landlord_confirm.success_title') }}
          </h3>
          <div class="inline text-gray-600">
            {{ $t('agreement.handover_v2.landlord_confirm.success_content') }}
          </div>
        </div>
        <div class="flex gap-2 flex-col">
          <r-button
            display="block"
            :label="$t('buttons.close')"
            @click="closeOverlay()"
          />
        </div>
      </div>
    </div>
  </r-overlay>
</template>
<script>
import { mapActions } from 'vuex';
import { handoverStatuses } from '~/utils/constants';
import {
  trackingProcessNames,
  agreementTrackingEventNames,
} from '~/utils/trackerConstants';

export default {
  name: 'LandlordHandoverConfirmationModal',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    agreementHandover: {
      type: Object,
      default: null,
    },
    agreementId: {
      type: String,
      default: null,
    },
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
  },
  emits: [
    'closeOverlay',
    'openHandoverEdit',
    'openHandoverStatusView',
    'openHandoverTenantNotesInfoModal',
  ],

  data: () => ({
    isPutHandoverInProgress: false,
    handoverStatuses,
  }),
  methods: {
    ...mapActions({
      actionGetHandover: 'handover/getHandover',
      actionPutHandover: 'handover/putHandover',
      actionTrackAgreementFlowEvent: 'tracker/trackAgreementFlowEvent',
    }),

    closeOverlay() {
      this.$emit('closeOverlay');
    },

    openHandoverEdit() {
      this.$emit('openHandoverEdit');
      this.closeOverlay();
    },
    openHandoverStatus() {
      this.$emit('openHandoverStatusView');
    },

    openHandoverTenantNotesInfoModalClick() {
      this.$emit('openHandoverTenantNotesInfoModal');
    },

    putHandoverStatusCompleted() {
      const handoverEntityId = this.agreementHandover?.handover?.entityId;
      const status = { status: handoverStatuses.COMPLETED };

      this.isPutHandoverInProgress = true;

      this.actionPutHandover({ entityId: handoverEntityId, includedData: status })
        .then(() => {
          this.emitAgreementTrackerEvent(
            agreementTrackingEventNames.HANDOVER_COMPLETED,
          );
        })
        .then(() => {
          this.actionGetHandover({ agreementId: this.agreementId });
        })
        .then(() => {
          this.openHandoverStatus();
        })
        .finally(() => {
          this.isPutHandoverInProgress = false;
        });
    },

    emitAgreementTrackerEvent(event) {
      this.actionTrackAgreementFlowEvent({
        eventName: event,
        processName: trackingProcessNames.AGREEMENT,
        agreement: this.agreementFromFirebase,
      });
    },
  },
};
</script>
