<template>
  <div class="relative mx-auto max-w-5xl my-6">
    <!--      Preferences form in desktop-->
    <div class="hidden lg:block w-full bg-white rounded-lg px-6 py-3 my-3">
      <div class="flex flex-col gap-4">
        <div class="grid grid-cols-6 flex-grow text-gray-400 divide-x divide-current">
          <!--      Location-->
          <div class="pr-6 col-span-1">
            <label class="text-xs">{{ $t('preferencesFilter.label.location') }}</label>
            <div
              class="hover:opacity-70 focus:opacity-70 cursor-pointer flex"
              @click="[(editLocation = true), (editActive = true)]"
            >
              <span
                v-if="form.districts && form.districts.length > 0"
                class="font-bold text-sm text-gray-800"
              >
                {{ selectedDistrictsString }}
              </span>
              <span v-else-if="form.city" class="font-bold text-sm text-gray-800">
                {{ form.city }}
              </span>
              <span v-else class="font-bold text-sm">
                {{ $t('preferencesFilter.emptyInputText.location') }}
              </span>
            </div>
          </div>

          <!--      Property Type-->
          <div class="px-6 col-span-1">
            <label class="text-xs">{{
              $t('preferencesFilter.label.property_types')
            }}</label>
            <div
              class="hover:opacity-70 focus:opacity-70 cursor-pointer flex justify-between"
              @click="[(editPropertyTypes = true), (editActive = true)]"
            >
              <span
                v-if="form.propertyTypes && form.propertyTypes.length > 0"
                class="font-bold text-sm text-gray-800"
              >
                {{ selectedPropertyTypesString }}
              </span>
              <span v-else class="font-bold text-sm">
                {{ $t('preferencesFilter.emptyInputText.property_types') }}
              </span>
              <r-icon class="inline-block" icon-name="chevron-down" />
            </div>
          </div>

          <!--      Rooms-->
          <div class="px-6">
            <label class="text-xs">{{ $t('preferencesFilter.label.rooms') }}</label>
            <div
              class="hover:opacity-70 focus:opacity-70 cursor-pointer flex justify-between"
              :class="{ 'text-gray-700': editRooms }"
              @click="[(editRooms = true), (editActive = true)]"
            >
              <span
                v-if="form.roomsMax && form.roomsMax === form.roomsMin"
                class="font-bold text-sm text-gray-800"
              >
                {{ maxRoomsTitle }}
              </span>
              <span
                v-else-if="form.roomsMin || form.roomsMax"
                class="font-bold text-sm text-gray-800"
              >
                <span v-if="form.roomsMin">
                  {{ minRoomsTitle }}
                </span>
                <span v-else> ... </span>
                <span> - </span>
                <span v-if="form.roomsMax">
                  {{ maxRoomsTitle }}
                </span>
                <span v-else> ... </span>
              </span>
              <span v-else class="font-bold text-sm">
                {{ $t('preferencesFilter.emptyInputText.rooms') }}
              </span>
              <r-icon class="inline-block" icon-name="chevron-down" />
            </div>
          </div>

          <!--      Price-->
          <div class="px-6">
            <label class="text-xs"
              >{{ $t('preferencesFilter.label.price') }} ({{ currencyLabel }})</label
            >
            <div
              class="hover:opacity-70 focus:opacity-70 cursor-pointer flex justify-between"
              :class="{ 'text-gray-700': editPrice }"
              @click="[(editPrice = true), (editActive = true)]"
            >
              <span
                v-if="form.priceMin || form.priceMax"
                class="font-bold text-sm text-gray-800"
              >
                <span v-if="form.priceMin">
                  {{ form.priceMin }}
                </span>
                <span v-else> ... </span>
                <span> - </span>
                <span v-if="form.priceMax">
                  {{ form.priceMax }}
                </span>
                <span v-else> ... </span>
              </span>
              <span v-else class="font-bold text-sm">
                {{ $t('preferencesFilter.emptyInputText.price') }}
              </span>

              <r-icon class="inline-block" icon-name="chevron-down" />
            </div>
          </div>

          <!--      Area-->
          <div class="px-6">
            <label class="text-xs">{{ $t('preferencesFilter.label.area') }}</label>
            <div
              class="hover:opacity-70 focus:opacity-70 cursor-pointer flex justify-between"
              :class="{ 'text-gray-700': editArea }"
              @click="[(editArea = true), (editActive = true)]"
            >
              <span
                v-if="form.areaMin || form.areaMax"
                class="font-bold text-sm text-gray-800"
              >
                <span v-if="form.areaMin">
                  {{ form.areaMin }}
                </span>
                <span v-else> ... </span>
                <span> - </span>
                <span v-if="form.areaMax">
                  {{ form.areaMax }}
                </span>
                <span v-else> ... </span>
              </span>
              <span v-else class="font-bold text-sm">
                {{ $t('preferencesFilter.emptyInputText.area') }}
              </span>

              <r-icon class="inline-block" icon-name="chevron-down" />
            </div>
          </div>

          <!--      Floor-->
          <div class="px-6">
            <label class="text-xs">{{ $t('preferencesFilter.label.floor') }}</label>
            <div
              class="hover:opacity-70 focus:opacity-70 cursor-pointer flex justify-between"
              :class="{ 'text-gray-700': editFloor }"
              @click="[(editFloor = true), (editActive = true)]"
            >
              <span
                v-if="form.floorMin || form.floorMax"
                class="font-bold text-sm text-gray-800"
              >
                <span v-if="form.floorMin">
                  {{ form.floorMin }}
                </span>
                <span v-else> ... </span>
                <span> - </span>
                <span v-if="form.floorMax">
                  {{ form.floorMax }}
                </span>
                <span v-else> ... </span>
              </span>
              <span v-else class="font-bold text-sm">
                {{ $t('preferencesFilter.emptyInputText.floor') }}
              </span>

              <r-icon class="inline-block" icon-name="chevron-down" />
            </div>
          </div>
        </div>
        <div class="flex flex-row justify-between">
          <div class="flex flex-row justify-start items-center gap-4">
            <div>
              <r-checkbox
                id="petsAllowed"
                v-model="form.petsAllowed"
                :label="$t('preferencesFilter.label.pets_friendly')"
                name="petsAllowed"
                no-padding
              />
            </div>
            <div>
              <r-checkbox id="hasParking" v-model="form.hasParking" name="hasParking">
                {{ $t('preferencesFilter.label.parking_spot') }}
              </r-checkbox>
            </div>
            <div>
              <r-checkbox id="hasStorage" v-model="form.hasStorage" name="hasStorage">
                {{ $t('preferencesFilter.label.storage_room') }}
              </r-checkbox>
            </div>
          </div>
          <div class="flex flex-row justify-end items-center">
            <div class="w-max">
              <r-button
                color="black"
                :disabled="isFormValuesEmpty"
                no-background
                size="small"
                @click="isFormValuesEmpty ? null : emitClickClearEvent()"
              >
                {{ $t('preferencesFilter.button.reset') }}
              </r-button>
            </div>
            <r-button size="small" width="wide" @click="emitClickSearchEvent()">
              {{ $t('preferencesFilter.button.search') }}
            </r-button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="editActive"
      v-click-outside="closeEditView"
      class="hidden lg:block absolute mx-0 bg-white rounded-xl p-4 shadow-xl z-10 -bottom-12 border-1"
      :class="{
        'lg:left-32': editRooms,
        'lg:left-64': editPrice,
        'lg:left-[24rem]': editArea,
        'lg:left-[38rem]': editFloor,
      }"
      @keyup.enter="onEnter"
    >
      <!--      Location-->
      <div
        class="location-modal my-4 lg:my-0 w-96"
        :class="{ 'lg:hidden': !editLocation }"
      >
        <label class="font-bold text-gray-600">{{
          $t('preferencesFilter.label.city')
        }}</label>
        <r-select
          v-model="selectedRegion"
          clearable
          :get-option-label="(option) => `${option.city} (${option.adsCount})`"
          :no-options-text="$t('form.validation.select_no_options')"
          option-label="city"
          :options="availableGeoRegions"
          :placeholder="$t('preferencesFilter.placeholder.city')"
        />
        <div v-if="selectedRegion && selectedRegion.showDistricts" class="mt-3">
          <label class="font-bold text-gray-600">{{
            $t('preferencesFilter.label.districts')
          }}</label>
          <r-select
            v-model="form.districts"
            clearable
            :close-on-select="false"
            deselect-from-dropdown
            :max-displayed-values="maxDisplayedValues"
            multiple
            :no-options-text="$t('form.validation.select_no_options')"
            :options="selectedRegion.districts"
            :placeholder="$t('preferencesFilter.placeholder.districts')"
          />
        </div>
      </div>

      <!-- Property Type -->
      <div class="my-4 lg:my-0 w-96" :class="{ 'lg:hidden': !editPropertyTypes }">
        <label class="font-bold text-gray-600">{{
          $t('preferencesFilter.label.property_types')
        }}</label>
        <r-select
          v-model="form.propertyTypes"
          clearable
          :close-on-select="false"
          deselect-from-dropdown
          :max-displayed-values="maxDisplayedValues"
          multiple
          :no-options-text="$t('form.validation.select_no_options')"
          :options="propertyTypeOptions"
          :placeholder="$t('preferencesFilter.placeholder.property_types')"
          :reduce="(propertyType) => propertyType.value"
        />
      </div>

      <!--      Rooms-->
      <div class="my-4 lg:my-0" :class="{ 'lg:hidden': !editRooms }">
        <r-range-select
          :data="roomsRangeSelectData"
          :max-value-index="form.roomsMax"
          :min-value-index="form.roomsMin"
          :title="$t('preferencesFilter.label.rooms')"
          title-class="font-bold text-gray-600"
          @update-max-value="(value) => (form.roomsMax = value)"
          @update-min-value="(value) => (form.roomsMin = value)"
        />
      </div>

      <!--      Area-->
      <div class="my-4 lg:my-0" :class="{ 'lg:hidden': !editArea }">
        <label class="font-bold text-gray-600"
          >{{ $t('preferencesFilter.label.area') }} ({{ 'm2' }})</label
        >
        <div class="flex gap-2">
          <r-input
            v-model="form.areaMin"
            add-on="m2"
            class="flex-grow"
            field-type="number"
            for="areaMin"
            name="areaMin"
            :placeholder="$t('preferencesFilter.placeholder.from')"
          />
          <span class="mt-4 text-gray-600"> — </span>
          <r-input
            v-model="form.areaMax"
            add-on="m2"
            class="flex-grow"
            field-type="number"
            for="areaMax"
            :min="form.areaMin"
            name="areaMax"
            :placeholder="$t('preferencesFilter.placeholder.to')"
          />
        </div>
      </div>

      <!--      Price-->
      <div class="my-4 lg:my-0" :class="{ 'lg:hidden': !editPrice }">
        <label class="font-bold text-gray-600"
          >{{ $t('preferencesFilter.label.price') }} ({{ currencyLabel }})</label
        >
        <div class="flex gap-2">
          <r-input
            v-model="form.priceMin"
            :add-on="currencyLabel"
            class="flex-grow"
            field-type="number"
            for="priceMin"
            name="priceMin"
            :placeholder="$t('preferencesFilter.placeholder.from')"
          />
          <span class="mt-4 text-gray-600"> — </span>
          <r-input
            v-model="form.priceMax"
            :add-on="currencyLabel"
            class="flex-grow"
            field-type="number"
            for="priceMax"
            :min="form.priceMin"
            name="priceMax"
            :placeholder="$t('preferencesFilter.placeholder.to')"
          />
        </div>
      </div>

      <!--      Floor-->
      <div class="my-4 lg:my-0" :class="{ 'lg:hidden': !editFloor }">
        <label class="font-bold text-gray-600">{{
          $t('preferencesFilter.label.floor')
        }}</label>
        <div class="flex gap-2">
          <r-input
            v-model="form.floorMin"
            class="flex-grow"
            field-type="number"
            for="floorMin"
            name="floorMin"
            :placeholder="$t('preferencesFilter.placeholder.from')"
          />
          <span class="mt-4 text-gray-600"> — </span>
          <r-input
            v-model="form.floorMax"
            class="flex-grow"
            field-type="number"
            for="floorMax"
            :min="form.floorMin"
            name="floorMax"
            :placeholder="$t('preferencesFilter.placeholder.to')"
          />
        </div>
      </div>
    </div>

    <!-- Button to open preferences in mobile-->
    <div v-if="!editMobileActive" class="lg:hidden bg-gray-100 p-4 -mx-4 my-6">
      <r-button inverted width="full" @click="editMobileActive = true">
        <r-icon class="inline mr-2" icon-name="chevron-down" />
        {{ $t('preferencesFilter.button.openTitleMobile') }}
      </r-button>
    </div>

    <!-- Preferences form in mobile-->
    <div
      class="lg:hidden bg-gray-100 -mx-4 p-4 my-6"
      :class="{ hidden: !editMobileActive }"
    >
      <div class="flex justify-between">
        <h3 class="font-bold text-xl text-gray-600 mb-4">
          {{ $t('preferencesFilter.button.openTitleMobile') }}
        </h3>
        <r-icon
          class="inline-block"
          icon-name="xmark"
          @click="editMobileActive = false"
        />
      </div>

      <!--      Mobile Location-->
      <div class="my-4">
        <label class="font-bold text-gray-600">{{
          $t('preferencesFilter.label.city')
        }}</label>
        <r-select
          v-model="selectedRegion"
          clearable
          :get-option-label="(option) => `${option.city} (${option.adsCount})`"
          :no-options-text="$t('form.validation.select_no_options')"
          option-label="city"
          :options="availableGeoRegions"
          :placeholder="$t('preferencesFilter.placeholder.city')"
        />
        <div v-if="selectedRegion && selectedRegion.showDistricts" class="mt-3">
          <label class="font-bold text-gray-600">{{
            $t('preferencesFilter.label.districts')
          }}</label>
          <r-select
            v-model="form.districts"
            clearable
            :close-on-select="false"
            deselect-from-dropdown
            :max-displayed-values="maxDisplayedValues"
            multiple
            :no-options-text="$t('form.validation.select_no_options')"
            :options="selectedRegion.districts"
            :placeholder="$t('preferencesFilter.label.districts')"
          />
        </div>
      </div>

      <!--      Mobile Property Type -->
      <div class="my-4">
        <label class="font-bold text-gray-600">{{
          $t('preferencesFilter.label.property_types')
        }}</label>
        <r-select
          v-model="form.propertyTypes"
          clearable
          :close-on-select="false"
          deselect-from-dropdown
          :max-displayed-values="maxDisplayedValues"
          multiple
          :no-options-text="$t('form.validation.select_no_options')"
          :options="propertyTypeOptions"
          :placeholder="$t('preferencesFilter.placeholder.property_types')"
          :reduce="(propertyType) => propertyType.value"
        />
      </div>

      <!--      Mobile Rooms-->
      <div class="my-4 flex justify-center w-full">
        <r-range-select
          class="w-full"
          :data="roomsRangeSelectData"
          :max-value-index="form.roomsMax"
          :min-value-index="form.roomsMin"
          :title="$t('preferencesFilter.label.rooms')"
          title-class="font-bold text-gray-600"
          @update-max-value="(value) => (form.roomsMax = value)"
          @update-min-value="(value) => (form.roomsMin = value)"
        />
      </div>

      <!--      Mobile Price-->
      <div class="my-4">
        <label class="font-bold text-gray-600"
          >{{ $t('preferencesFilter.label.price') }} ({{ currencyLabel }})</label
        >
        <div class="flex gap-2">
          <r-input
            v-model="form.priceMin"
            :add-on="currencyLabel"
            class="flex-grow"
            field-type="number"
            for="priceMin"
            name="priceMin"
            :placeholder="$t('preferencesFilter.placeholder.from')"
          />
          <span class="mt-4 text-gray-600"> — </span>
          <r-input
            v-model="form.priceMax"
            :add-on="currencyLabel"
            class="flex-grow"
            field-type="number"
            for="priceMax"
            :min="form.roomsMin"
            name="priceMax"
            :placeholder="$t('preferencesFilter.placeholder.to')"
          />
        </div>
      </div>

      <!--      Mobile area-->
      <div class="my-4">
        <label class="font-bold text-gray-600"
          >{{ $t('preferencesFilter.label.area') }} (m2)</label
        >
        <div class="flex gap-2">
          <r-input
            v-model="form.areaMin"
            add-on="m2"
            class="flex-grow"
            field-type="number"
            for="areaMin"
            name="areaMin"
            :placeholder="$t('preferencesFilter.placeholder.from')"
          />
          <span class="mt-4 text-gray-600"> — </span>
          <r-input
            v-model="form.areaMax"
            add-on="m2"
            class="flex-grow"
            field-type="number"
            for="areaMax"
            :min="form.areaMin"
            name="areaMax"
            :placeholder="$t('preferencesFilter.placeholder.to')"
          />
        </div>
      </div>

      <!--      Mobile floor-->
      <div class="my-4">
        <label class="font-bold text-gray-600">{{
          $t('preferencesFilter.label.floor')
        }}</label>
        <div class="flex gap-2">
          <r-input
            v-model="form.floorMin"
            class="flex-grow"
            field-type="number"
            for="floorMin"
            name="floorMin"
            :placeholder="$t('preferencesFilter.placeholder.from')"
          />
          <span class="mt-4 text-gray-600"> — </span>
          <r-input
            v-model="form.floorMax"
            class="flex-grow"
            field-type="number"
            for="floorMax"
            :min="form.floorMin"
            name="floorMax"
            :placeholder="$t('preferencesFilter.placeholder.to')"
          />
        </div>
      </div>

      <div class="flex flex-col gap-4">
        <r-checkbox
          id="petsAllowed"
          v-model="form.petsAllowed"
          :label="$t('form.label.petsAllowed')"
          name="petsAllowed"
          no-padding
        />
        <r-checkbox
          id="hasParking"
          v-model="form.hasParking"
          :label="$t('form.label.parking_space')"
          name="hasParking"
          no-padding
        />
        <r-checkbox
          id="hasStorage"
          v-model="form.hasStorage"
          :label="$t('form.label.hasStorage')"
          name="hasStorage"
          no-padding
        />
      </div>

      <!--      Mobile buttons -->
      <div class="mt-8">
        <r-button size="small" width="full" @click="emitClickSearchEvent()">
          {{ $t('preferencesFilter.button.search') }}
        </r-button>
        <r-button
          color="black"
          :disabled="isFormValuesEmpty"
          no-background
          size="small"
          width="full"
          @click="isFormValuesEmpty ? null : emitClickClearEvent()"
        >
          {{ $t('preferencesFilter.button.reset') }}
        </r-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { adPropertyTypes } from '~/utils/constants';
import { defaultRoomsRangeSelectData } from '~/utils/objectStructures';

export default {
  name: 'PropertiesPreferenceFilter',
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    filterOpenInMobile: {
      type: Boolean,
      default: true,
    },
    availableGeoRegions: {
      type: Array,
      required: true,
    },
  },
  emits: ['clickClear', 'clickSearch'],

  data() {
    return {
      form: this.modelValue || null,
      editMobileActive: this.filterOpenInMobile,
      maxDisplayedValues: 3,
      editActive: false,
      editLocation: false,
      editPropertyTypes: false,
      editRooms: false,
      editPrice: false,
      editArea: false,
      editFloor: false,
      roomsRangeSelectData: defaultRoomsRangeSelectData(),
    };
  },
  computed: {
    ...mapGetters({
      country: 'getCountry',
    }),
    selectedDistrictsString() {
      if (!Array.isArray(this.form.districts)) return '';
      if (this.form.districts.length <= this.maxDisplayedValues) {
        return this.form.districts.join(', ');
      } else {
        return `${this.form.districts.slice(0, this.maxDisplayedValues).join(', ')} + ${this.form.districts.length - this.maxDisplayedValues}`;
      }
    },
    selectedPropertyTypesString() {
      if (!Array.isArray(this.form.propertyTypes)) return '';

      const propertyTypeLabels = this.form.propertyTypes.map(
        (type) =>
          this.propertyTypeOptions.find((option) => type === option.value).label,
      );
      if (propertyTypeLabels.length <= this.maxDisplayedValues) {
        return propertyTypeLabels.join(', ');
      } else {
        return `${propertyTypeLabels.slice(0, this.maxDisplayedValues).join(', ')} + ${propertyTypeLabels.length - this.maxDisplayedValues}`;
      }
    },
    ...mapGetters({
      currencyLabel: 'getCurrencyLabel',
    }),

    minRoomsTitle() {
      return this.roomsRangeSelectData.find((obj) => obj.value === this.form.roomsMin)
        .title;
    },

    maxRoomsTitle() {
      return this.roomsRangeSelectData.find((obj) => obj.value === this.form.roomsMax)
        .title;
    },

    isFormValuesEmpty() {
      const formValues = Object.values(this.form);
      if (!formValues || formValues.length === 0) {
        return true;
      }
      const isAnyValueSet = formValues.every((value) => {
        if (value === null || value === undefined || value === '') {
          return true;
        }
        return false;
      });

      return isAnyValueSet;
    },
    selectedRegion: {
      get() {
        /* If we have regions and proper city name, try to find corresponding region by city, otherwise return null */
        const regions = this.availableGeoRegions;
        if (!Array.isArray(regions) || regions.length === 0) return null;
        if (!this.form || !this.form.city) return null;

        return regions.find((x) => x.city === this.form.city) || null;
      },
      set(value) {
        this.form.city = value ? value.city : '';
        this.form.districts = [];
      },
    },
    propertyTypeOptions() {
      const options = [];

      options.push({
        value: adPropertyTypes.APARTMENT,
        label: this.$t(`offer.property_type.${adPropertyTypes.APARTMENT}`),
      });

      options.push({
        value: adPropertyTypes.HOUSE,
        label: this.$t(`offer.property_type.${adPropertyTypes.HOUSE}`),
      });

      if (this.country === 'pl') {
        options.push({
          value: adPropertyTypes.ROOM,
          label: this.$t(`offer.property_type.${adPropertyTypes.ROOM}`),
        });
      }

      return options;
    },
  },
  methods: {
    emitClickClearEvent(event) {
      this.$emit('clickClear', event);
    },

    emitClickSearchEvent(event) {
      this.$emit('clickSearch', event);
    },

    closeEditView() {
      this.editActive = false;
      this.editLocation = false;
      this.editPropertyTypes = false;
      this.editRooms = false;
      this.editPrice = false;
      this.editArea = false;
      this.editFloor = false;
    },

    onEnter() {
      // Hacky option to submit search event click on enter
      this.emitClickSearchEvent();
      this.closeEditView();
    },
  },
};
</script>
<style scoped>
>>> {
  --vs-dropdown-option--active-color: #eeeeee;
}
</style>
