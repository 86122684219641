<template>
  <r-overlay
    body-classes="flex-h-screen sm:flex-h-auto"
    class="tenant-handover-confirmation-modal"
    :prevent-close="isPutHandoverInProgress"
    size="small"
    :visible="showModal"
    @close="closeOverlay()"
  >
    <div
      v-if="agreementHandover?.handover?.status === handoverStatuses.FINISHED"
      class="flex flex-col h-full"
    >
      <div class="bg-peach-200 p-4 pt-12">
        <img
          :alt="$t('agreement.button.add_landlord')"
          class="mx-auto w-52"
          src="public/illustrations/person-with-check.svg"
        />
      </div>
      <div class="flex flex-col h-full p-4 sm:px-12">
        <div class="flex-grow mb-8 text-center">
          <h3 class="font-medium text-xl my-4 mb-4">
            {{ $t('agreement.handover_v2.tenant_finished.title') }}
          </h3>
          <p
            v-if="
              agreementHandover?.feedback?.files?.length ||
              agreementHandover?.feedback?.description
            "
            class="text-gray-600"
          >
            {{ $t('agreement.handover_v2.tenant_finished.additional_comments') }}
          </p>
          <p v-else class="text-gray-600">
            {{ $t('agreement.handover_v2.tenant_finished.no_comments') }}
          </p>
        </div>
        <r-button
          display="block"
          :label="$t('buttons.close')"
          @click="closeOverlay()"
        />
      </div>
    </div>

    <div v-else-if="isPutHandoverInProgress" class="h-full">
      <r-loader class="flex justify-center items-center h-full" type="animatedLogo" />
    </div>
    <div v-else class="mt-8 flex flex-col text-center p-4 sm:px-12 h-full">
      <div class="flex-grow flex flex-col justify-center mb-8">
        <r-icon class="text-4xl text-rendin-500" icon-name="circle-exclamation" />

        <h3 class="font-medium text-xl mt-6 mb-4">
          {{ $t('agreement.handover_v2.tenant_confirm.title') }}
        </h3>
        <p class="text-gray-600">
          {{ $t('agreement.handover_v2.tenant_confirm.content') }}
        </p>
      </div>
      <div class="flex gap-2 flex-col">
        <r-button
          display="block"
          inverted
          :label="$t('agreement.handover_v2.tenant_confirm.button.cancel')"
          @click="closeOverlay()"
        />
        <r-button
          display="block"
          :label="$t('agreement.handover_v2.tenant_confirm.button.submit')"
          @click="putHandoverStatusFinished()"
        />
      </div>
    </div>
  </r-overlay>
</template>
<script>
import { mapActions } from 'vuex';
import { handoverStatuses } from '~/utils/constants';
import {
  trackingProcessNames,
  agreementTrackingEventNames,
} from '~/utils/trackerConstants';

export default {
  name: 'TenantHandoverConfirmationModal',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    agreementHandover: {
      type: Object,
      default: null,
    },
    agreementId: {
      type: String,
      default: null,
    },
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
  },
  emits: ['closeOverlay', 'handoverStatusChangeFinished'],

  data: () => ({
    isPutHandoverInProgress: false,
    handoverStatuses,
  }),
  methods: {
    ...mapActions({
      actionGetHandover: 'handover/getHandover',
      actionPutHandover: 'handover/putHandover',
      actionTrackAgreementFlowEvent: 'tracker/trackAgreementFlowEvent',
    }),

    closeOverlay() {
      this.$emit('closeOverlay');
    },

    putHandoverStatusFinished() {
      const handoverEntityId = this.agreementHandover?.handover?.entityId;
      const status = { status: handoverStatuses.FINISHED };

      this.isPutHandoverInProgress = true;

      this.actionPutHandover({ entityId: handoverEntityId, includedData: status })
        .then(() => {
          this.emitAgreementTrackerEvent(agreementTrackingEventNames.HANDOVER_FINISHED);
        })
        .then(() => {
          const includeData = {
            handover: true,
            feedback: true,
          };
          this.actionGetHandover({
            agreementId: this.agreementId,
            includeData: includeData,
          });
        })
        .finally(() => {
          this.isPutHandoverInProgress = false;
          this.$emit('handoverStatusChangeFinished');
        });
    },

    emitAgreementTrackerEvent(event) {
      this.actionTrackAgreementFlowEvent({
        eventName: event,
        processName: trackingProcessNames.AGREEMENT,
        agreement: this.agreementFromFirebase,
      });
    },
  },
};
</script>
