<template>
  <section class="handover-edit-view flex flex-col flex-grow">
    <!--    Handover Introduction view -->
    <div
      v-if="activeEditStepsIndex === handoverEditViewActiveSteps.START"
      class="max-w-5xl mx-auto px-4 pt-4 pb-8 md:pt-12 md:pb-16 flex-grow flex items-center"
    >
      <div class="bg-white rounded-lg shadow-lg p-4 md:p-8">
        <div class="grid md:grid-cols-5 items-center gap-4">
          <h3 class="font-medium text-2xl md:text-3xl my-4 md:col-span-2">
            {{ $t('agreement.handover_v2.edit_intro.title') }}
          </h3>
          <ul class="md:col-span-3">
            <li class="flex gap-2 items-center py-6 border-b">
              <r-icon
                class="text-rendin-700 md:text-lg w-8 shrink-0"
                icon-name="coins"
              />
              <div class="text-gray-600">
                {{ $t('agreement.handover_v2.edit_intro.reason_1') }}
              </div>
            </li>
            <li class="flex gap-2 items-center py-6 border-b">
              <r-icon
                class="text-rendin-700 md:text-lg w-8 shrink-0"
                icon-name="comments"
              />
              <div class="text-gray-600">
                {{ $t('agreement.handover_v2.edit_intro.reason_2') }}
              </div>
            </li>
            <li class="flex gap-2 items-center py-6">
              <r-icon
                class="text-rendin-700 md:text-lg w-8 shrink-0"
                icon-name="file-check"
              />
              <div class="text-gray-600">
                {{ $t('agreement.handover_v2.edit_intro.reason_3') }}
                <span
                  class="text-rendin-700 font-medium cursor-pointer hover:text-rendin-500 focus:text-rendin-500"
                  @click="openHandoverTenantNotesInfoModalClick"
                  >{{ $t('agreement.handover_v2.edit_intro.reason_3_here') }}.
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!--    Steps -->
    <div
      v-if="activeEditStepsIndex > handoverEditViewActiveSteps.START"
      class="flex-grow"
    >
      <div class="handover-edit-view__steps max-w-4xl mx-auto px-4 md:px-8 my-8">
        <div
          v-for="(step, index) in editSteps"
          :key="index"
          :class="stepClass(index + 1)"
          @click="moveToStep(index + 1)"
        >
          <div class="w-full h-12">
            <r-icon
              class="handover-edit-view__steps__step__icon"
              :icon-name="stepIconName(index + 1)"
              prefix="solid"
            />
          </div>
          <div
            class="text-xs md:text-sm font-bold text-gray-700 max-w-full text-center overflow-hidden text-ellipsis"
          >
            {{ step }}
          </div>
        </div>
      </div>

      <div class="max-w-3xl mx-auto px-4 mb-12">
        <div
          v-if="activeEditStepsIndex < handoverEditViewActiveSteps.CLOSURE"
          class="bg-white rounded-lg shadow-lg p-4 md:p-8"
        >
          <div class="text-sm mb-2">
            {{ activeEditStepsIndex }}/{{ editSteps.length }}
          </div>

          <!--    Steps views -->
          <HandoverEditViewConditionOrCleaning
            v-if="activeEditStepsIndex === handoverEditViewActiveSteps.CONDITION"
            :agreement-handover="agreementHandover"
            :agreement-id="agreementId"
            :handover-data-type="handoverDataTypes.CONDITION"
            :validate="validateStep"
            @input-data-for-entity="validateConditionAndCleaningFrontData"
          />

          <HandoverEditViewConditionOrCleaning
            v-else-if="activeEditStepsIndex === handoverEditViewActiveSteps.CLEANING"
            :agreement-handover="agreementHandover"
            :agreement-id="agreementId"
            :handover-data-type="handoverDataTypes.CLEANING"
            :validate="validateStep"
            @input-data-for-entity="validateConditionAndCleaningFrontData"
          />

          <HandoverEditViewEntity
            v-else-if="activeEditStepsIndex === handoverEditViewActiveSteps.METERS"
            :agreement-from-firebase="agreementFromFirebase"
            :agreement-handover="agreementHandover"
            :agreement-id="agreementId"
            :handover-entity-type="handoverDataTypes.METERS"
          />

          <HandoverEditViewEntity
            v-else-if="activeEditStepsIndex === handoverEditViewActiveSteps.ROOMS"
            :agreement-from-firebase="agreementFromFirebase"
            :agreement-handover="agreementHandover"
            :agreement-id="agreementId"
            :handover-entity-type="handoverDataTypes.ROOMS"
          />

          <HandoverEditViewEntity
            v-else-if="activeEditStepsIndex === handoverEditViewActiveSteps.OTHERS"
            :agreement-from-firebase="agreementFromFirebase"
            :agreement-handover="agreementHandover"
            :agreement-id="agreementId"
            :handover-entity-type="handoverDataTypes.OTHERS"
          />
        </div>

        <!--    Closure -->
        <div v-if="activeEditStepsIndex === 6" class="flex-grow">
          <div class="bg-white rounded-lg shadow-lg p-4 md:p-8">
            <div class="flex flex-col sm:flex-row items-center gap-4">
              <div class="w-full max-w-1/2">
                <img
                  :alt="$t('agreement.handover_v2.edit_closure.title')"
                  src="/illustrations/sammy-line-message-sent.svg"
                />
              </div>

              <div class="flex flex-col">
                <h3 class="font-medium text-2xl md:text-4xl my-4">
                  {{ $t('agreement.handover_v2.edit_closure.title') }}
                </h3>

                <div
                  v-if="cleaningIsValid && conditionIsValid"
                  class="md:text-lg"
                  v-html="$t('agreement.handover_v2.edit_closure.text')"
                ></div>

                <div v-if="!conditionIsValid" class="text-error md:text-lg">
                  {{ $t('agreement.handover_v2.condition.how_condition') }}
                </div>
                <div v-if="!cleaningIsValid" class="text-error">
                  {{ $t('agreement.handover_v2.cleanliness.how_clean') }}
                </div>

                <div class="flex flex-col md:flex-row gap-4 mt-8">
                  <r-button
                    border-color="light-gray"
                    class="w-full md:w-auto md:text-nowrap"
                    color="black"
                    inverted
                    :label="$t('buttons.preview')"
                    @click="openHandoverProtocolClick()"
                  />
                  <r-button
                    class="w-full md:w-auto md:text-nowrap"
                    :disabled="!cleaningIsValid || !conditionIsValid"
                    :is-loading="putHandoverQueueLocked"
                    :label="$t('buttons.proceed_to_final_step')"
                    @click="
                      cleaningIsValid && conditionIsValid ? changeStatusOrClose() : null
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white">
      <div class="max-w-5xl mx-auto bg-white px-4 py-6">
        <!--        Steps actions buttons-->
        <div
          v-if="activeEditStepsIndex < handoverEditViewActiveSteps.CLOSURE"
          class="flex justify-between gap-4"
        >
          <r-button
            border-color="light-gray"
            color="black"
            :icon="
              activeEditStepsIndex > handoverEditViewActiveSteps.START
                ? 'arrow-left-long'
                : null
            "
            icon-class="ml-1"
            inverted
            :label="
              activeEditStepsIndex > handoverEditViewActiveSteps.START
                ? $t('buttons.back')
                : $t('buttons.cancel')
            "
            :label-class="
              activeEditStepsIndex > handoverEditViewActiveSteps.START
                ? 'hidden sm:block'
                : ''
            "
            @click="prevStep()"
          />
          <r-button
            v-if="!agreementHandover?.handover"
            :is-loading="postHandoverQueueLocked"
            :label="$t('agreement.handover_v2.start_creating_protocol')"
            @click="postHandover()"
          />
          <r-button
            v-else
            :disabled="!activeConditionOrCleaningStepIsValid"
            :label="$t('buttons.next')"
            @click="validateEntityAndNextStep()"
          />
        </div>
        <!--        Closure actions buttons-->
        <div
          v-else
          class="flex flex-col sm:flex-row justify-end md:justify-between gap-4"
        >
          <r-button
            border-color="light-gray"
            class="hidden md:block"
            color="black"
            :icon="
              activeEditStepsIndex > handoverEditViewActiveSteps.START
                ? 'arrow-left-long'
                : null
            "
            inverted
            :label="
              activeEditStepsIndex > handoverEditViewActiveSteps.START
                ? $t('buttons.back')
                : $t('buttons.cancel')
            "
            @click="prevStep()"
          />
        </div>
      </div>
    </div>

    <HandoverSkipStepModal
      :active-entity-validation-data="activeEntityValidationData"
      :show-modal="showHandoverSkipStepModal"
      @click-next-step="nextStep"
      @close-overlay="showHandoverSkipStepModal = false"
    />
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
// import HandoverEditViewConditionOrCleaning from '~/components/section/handover/HandoverEditViewConditionOrCleaning';
// import HandoverEditViewEntity from '~/components/section/handover/HandoverEditViewEntity';
// import HandoverSkipStepModal from '~/components/section/handover/HandoverSkipStepModal';
import {
  handoverDataTypes,
  handoverEntryTypes,
  handoverStatuses,
  handoverEditViewActiveSteps,
  handoverEntityValidationErrors,
} from '~/utils/constants';
import {
  agreementTrackingEventNames,
  trackingProcessNames,
} from '~/utils/trackerConstants';

export default {
  name: 'HandoverEditView',
  components: {
    // HandoverEditViewConditionOrCleaning,
    // HandoverEditViewEntity,
    // HandoverSkipStepModal,
  },
  props: {
    agreementId: {
      type: String,
      default: null,
    },
    agreementHandover: {
      type: Object,
      default: null,
    },
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
    isTenantView: {
      type: Boolean,
      default: true,
    },
    moveToStepIndex: {
      type: Number,
      default: null,
    },
  },

  emits: [
    'activeHandoverEditViewStep',
    'changeHandoverStatus',
    'closeHandoverEdit',
    'openHandoverProtocol',
    'openHandoverTenantNotesInfoModal',
  ],

  data: (vm) => ({
    activeEditStepsIndex: 0,
    editSteps: [
      vm.$t('agreement.handover_v2.condition.condition_title'),
      vm.$t('agreement.handover_v2.cleanliness.cleanliness_title'),
      vm.$t('agreement.handover_v2.meters.meters_title'),
      vm.$t('agreement.handover_v2.rooms.rooms_title'),
      vm.$t('agreement.handover_v2.others.others_title'),
    ],
    handoverDataTypes,
    validateStep: 0,
    handoverEditViewActiveSteps,
    showHandoverSkipStepModal: false,
    activeEntityValidationData: null,
    moveToStepNumber: null,
    conditionDataSelected: null,
    cleaningDataSelected: null,
  }),

  computed: {
    ...mapGetters({
      postHandoverQueueLocked: 'handover/postHandoverQueueLocked',
      putHandoverQueueLocked: 'handover/putHandoverQueueLocked',
    }),

    conditionIsValid() {
      return (
        !!this.agreementHandover?.handover?.condition || this.conditionDataSelected
      );
    },

    cleaningIsValid() {
      return !!this.agreementHandover?.handover?.cleaning || this.cleaningDataSelected;
    },

    activeConditionOrCleaningStepIsValid() {
      return !(
        (this.activeEditStepsIndex === handoverEditViewActiveSteps.CONDITION &&
          !this.conditionIsValid) ||
        (this.activeEditStepsIndex === handoverEditViewActiveSteps.CLEANING &&
          !this.cleaningIsValid)
      );
    },
  },

  watch: {
    activeEditStepsIndex(val) {
      this.$emit('activeHandoverEditViewStep', val);
    },
  },

  mounted() {
    this.activeEditStepsIndex = !this.agreementHandover?.handover?.status
      ? handoverEditViewActiveSteps.START
      : handoverEditViewActiveSteps.CONDITION;
    this.getHandover().then(() => this.continueFromStep());
  },

  methods: {
    ...mapActions({
      actionPostHandover: 'handover/postHandover',
      actionGetHandover: 'handover/getHandover',
      actionPutHandover: 'handover/putHandover',
      actionTrackAgreementFlowEvent: 'tracker/trackAgreementFlowEvent',
    }),

    validateConditionAndCleaningFrontData(data) {
      this.cleaningDataSelected = this.cleaningDataSelected
        ? this.cleaningDataSelected
        : handoverDataTypes.CLEANING in data;
      this.conditionDataSelected = this.conditionDataSelected
        ? this.conditionDataSelected
        : handoverDataTypes.CONDITION in data;
    },

    getHandover() {
      return this.actionGetHandover({ agreementId: this.agreementId });
    },

    postHandover() {
      this.actionPostHandover({
        type: handoverEntryTypes.HANDOVER,
        agreementId: this.agreementId,
      })
        .then(() => {
          this.getHandover();
          this.emitAgreementTrackerEvent(agreementTrackingEventNames.HANDOVER_CREATED);
        })
        .then(() => {
          this.nextStep();
        });
    },

    emitAgreementTrackerEvent(event) {
      this.actionTrackAgreementFlowEvent({
        eventName: event,
        processName: trackingProcessNames.AGREEMENT,
        agreement: this.agreementFromFirebase,
      });
    },

    stepIconName(stepIndex) {
      return stepIndex < this.activeEditStepsIndex ? 'circle-check' : 'scrubber';
    },

    stepClass(stepIndex) {
      const classes = ['handover-edit-view__steps__step'];

      if (stepIndex === this.activeEditStepsIndex) {
        classes.push('handover-edit-view__steps__step--active');
      } else if (stepIndex < this.activeEditStepsIndex) {
        classes.push('handover-edit-view__steps__step--passed');
      }

      return classes;
    },

    changeStatusOrClose() {
      if (this.agreementHandover?.handover?.status === handoverStatuses.DRAFT) {
        this.$emit('changeHandoverStatus');
      } else {
        this.$emit('closeHandoverEdit');
      }
    },

    openHandoverProtocolClick() {
      this.$emit('openHandoverProtocol', { focusedProtocolView: true });
      this.scrollPageUp();
    },

    openHandoverTenantNotesInfoModalClick() {
      this.$emit('openHandoverTenantNotesInfoModal');
    },

    prevStep() {
      this.activeEditStepsIndex =
        this.activeEditStepsIndex > 0
          ? --this.activeEditStepsIndex
          : this.$emit('closeHandoverEdit');
      this.scrollPageUp();
    },

    nextStep() {
      this.validateStep = ++this.validateStep;
      if (
        this.activeConditionOrCleaningStepIsValid &&
        this.activeEditStepsIndex <= handoverEditViewActiveSteps.CLOSURE
      ) {
        this.activeEditStepsIndex =
          this.moveToStepNumber || ++this.activeEditStepsIndex;
      }

      this.scrollPageUp();
    },

    validateEntityAndNextStep() {
      this.activeEntityValidationData = null;
      this.moveToStepNumber = null;

      this.validateStep = ++this.validateStep;
      if (!this.activeConditionOrCleaningStepIsValid) return;

      if (
        this.activeEditStepsIndex === handoverEditViewActiveSteps.METERS &&
        !this.validateEntityData(handoverDataTypes.METERS).success &&
        this.validateEntityData(handoverDataTypes.METERS)?.error ===
          handoverEntityValidationErrors.MISSING_ENTITY
      ) {
        this.showHandoverSkipStepModal = true;
      } else if (
        this.activeEditStepsIndex === handoverEditViewActiveSteps.ROOMS &&
        !this.validateEntityData(handoverDataTypes.ROOMS).success
      ) {
        this.showHandoverSkipStepModal = true;
      } else if (this.activeEditStepsIndex <= handoverEditViewActiveSteps.CLOSURE) {
        this.activeEditStepsIndex++;
      }

      this.scrollPageUp();
    },

    validateEntityData(handoverEntityType) {
      const result = {};
      result.entityType = handoverEntityType;

      if (!this.agreementHandover[handoverEntityType]) {
        result.success = false;
        result.error = handoverEntityValidationErrors.MISSING_ENTITY;
      } else if (
        this.agreementHandover[handoverEntityType].every(
          (obj) => Array.isArray(obj.files) && obj.files.length === 0,
        )
      ) {
        result.success = false;
        result.error = handoverEntityValidationErrors.MISSING_ENTITY_FILES;
      } else {
        result.success = true;
      }

      this.activeEntityValidationData = result;
      return result;
    },

    moveToStep(stepNumber) {
      this.moveToStepNumber = stepNumber;

      if (stepNumber > this.activeEditStepsIndex) {
        if (
          this.activeEditStepsIndex === handoverEditViewActiveSteps.CLEANING &&
          !this.cleaningIsValid
        ) {
          return;
        }
        if (
          this.activeEditStepsIndex === handoverEditViewActiveSteps.CONDITION &&
          !this.conditionIsValid
        ) {
          return;
        }
        if (
          this.activeEditStepsIndex === handoverEditViewActiveSteps.CONDITION &&
          stepNumber > handoverEditViewActiveSteps.CLEANING &&
          !this.cleaningIsValid
        ) {
          return;
        }
      }

      if (
        this.activeEditStepsIndex === handoverEditViewActiveSteps.METERS &&
        !this.validateEntityData(handoverDataTypes.METERS).success &&
        this.validateEntityData(handoverDataTypes.METERS)?.error ===
          handoverEntityValidationErrors.MISSING_ENTITY
      ) {
        this.showHandoverSkipStepModal = true;
        return;
      } else if (
        this.activeEditStepsIndex === handoverEditViewActiveSteps.ROOMS &&
        !this.validateEntityData(handoverDataTypes.ROOMS).success
      ) {
        this.showHandoverSkipStepModal = true;
        return;
      }

      this.activeEditStepsIndex = stepNumber;
    },

    continueFromStep() {
      if (this.moveToStepIndex) this.activeEditStepsIndex = this.moveToStepIndex;
      // if cleaning or condition is not saved to database start filling required steps
      else if (!this.conditionIsValid && this.agreementHandover?.handover)
        this.activeEditStepsIndex = handoverEditViewActiveSteps.CONDITION;
      else if (!this.cleaningIsValid && this.agreementHandover?.handover)
        this.activeEditStepsIndex = handoverEditViewActiveSteps.CLEANING;
      // else open last step with some data
      else if (this.agreementHandover?.others)
        this.activeEditStepsIndex = handoverEditViewActiveSteps.OTHERS;
      else if (this.agreementHandover?.rooms)
        this.activeEditStepsIndex = handoverEditViewActiveSteps.ROOMS;
      else if (this.agreementHandover?.meters)
        this.activeEditStepsIndex = handoverEditViewActiveSteps.METERS;
      else if (this.cleaningIsValid)
        this.activeEditStepsIndex = handoverEditViewActiveSteps.CLEANING;
      else if (this.conditionIsValid)
        this.activeEditStepsIndex = handoverEditViewActiveSteps.CONDITION;
    },

    scrollPageUp() {
      window.scrollTo({
        top: 0,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.handover-edit-view {
  .handover-edit-view__steps {
    @apply grid grid-cols-5 gap-2;

    &__step {
      @apply flex flex-col items-center cursor-pointer relative;

      &:not(:last-of-type)::after {
        @apply bg-gray-200 block absolute top-5 -translate-y-1/2 left-1/2 w-full h-0.5 content-[''];
      }

      &__icon {
        z-index: 1;
        @apply text-gray-50 text-2xl ring-2 ring-gray-200 bg-gray-200 rounded-full flex absolute top-2 left-1/2 -translate-x-1/2;
      }

      &--passed {
        &:not(:last-of-type)::after {
          @apply bg-green-500;
        }

        .handover-edit-view__steps__step__icon {
          @apply text-green-600 ring-green-500 bg-white;
        }
      }

      &--active {
        .handover-edit-view__steps__step__icon {
          @apply text-grayCool-400 ring-violet-100 ring-4 ring-offset-1;
        }
      }
    }
  }
}
</style>
