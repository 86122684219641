<template>
  <div class="profile-candidacies bg-gray-50 pb-20 relative flex-grow">
    <!--                background               -->
    <r-banner
      v-if="
        !(getCandidaciesInProgress || deleteCandidacyInProgress) &&
        (getCandidaciesResponse || getCandidaciesFailed)
      "
      :images="blok.headerImage"
    />

    <!--        Loader        -->
    <div
      v-if="getCandidaciesInProgress || deleteCandidacyInProgress"
      class="min-h-1/2-screen flex justify-center items-center"
    >
      <r-loader type="animatedLogo" />
    </div>
    <div
      v-if="!(getCandidaciesInProgress || deleteCandidacyInProgress)"
      class="relative py-10 md:py-20"
    >
      <!--        If profile have candidacies     -->
      <div v-if="showCandidacyList && blok.headerTitle" class="px-4">
        <div
          class="mx-auto max-w-3xl w-full bg-white bg-opacity-75 p-4 mb-8 rounded-lg text-center"
        >
          <h1 v-if="blok.headerTitle" class="text-4xl md:text-5xl font-bold">
            {{ blok.headerTitle }}
          </h1>
          <RichText
            v-if="blok.headerContent"
            :blok="blok.headerContent"
            class="mt-4 md:text-lg font-light -mb-4"
          />
        </div>
      </div>
      <!--        no candidacy     -->
      <div
        v-if="getCandidaciesResponse && getCandidaciesResponse.length == 0"
        class="px-4"
      >
        <div
          class="mx-auto max-w-3xl w-full bg-white bg-opacity-75 p-4 mb-8 rounded-lg text-center"
        >
          <h1 v-if="blok.noCandidacyHeaderTitle" class="text-4xl md:text-5xl font-bold">
            {{ blok.noCandidacyHeaderTitle }}
          </h1>
          <RichText
            v-if="blok.noCandidacyHeaderContent"
            :blok="blok.noCandidacyHeaderContent"
            class="mt-4 md:text-lg font-light -mb-4"
          />
        </div>
        <div
          class="bg-white rounded-lg shadow-lg text-center mx-auto max-w-3xl p-4 md:px-8 text-center px-4"
        >
          <component
            :is="theBlok.component"
            v-for="theBlok in blok.noAdvertisementsContent.filter((item) =>
              ['RichTextBlok', 'ButtonBlok'].includes(item.component),
            )"
            :key="theBlok._uid"
            :blok="theBlok"
            class="r-fade my-6 md:my-12 text-lg font-light"
          />

          <div class="bg-gray-50 rounded-lg px-4 py-1 max-w-xl mx-auto">
            <RichText
              v-if="blok.noAdvertisementsSupport"
              :blok="blok.noAdvertisementsSupport"
              class="md:text-lg font-light"
            />
          </div>
        </div>
      </div>
      <div v-if="getCandidaciesFailed" class="px-4">
        <div
          class="mx-auto max-w-3xl w-full bg-white bg-opacity-75 p-4 mb-8 rounded-lg text-center"
        >
          <h1
            v-if="blok.getCandidaciesFailedHeaderTittle"
            class="text-4xl md:text-5xl font-bold"
          >
            {{ blok.getCandidaciesFailedHeaderTittle }}
          </h1>
          <RichText
            v-if="blok.getCandidacyFailedHeaderContent"
            :blok="blok.getCandidacyFailedHeaderContent"
            class="mt-4 md:text-lg font-light -mb-4"
          />
        </div>
      </div>

      <!--        Candidacy lists    -->
      <div v-if="showCandidacyList">
        <div class="mx-auto max-w-5xl px-4">
          <div
            v-for="(candidacy, index) in getCandidaciesResponse"
            :key="index"
            class="mb-8"
          >
            <r-property-item
              v-if="candidacy.active && candidacy.advertisementDocId"
              additional-button-icon="xmark"
              :additional-button-text="$t('offer.cancel_candidacy')"
              :address="parseFullAddressFromAddressParameters(candidacy)"
              :area-label="$t('offer.size')"
              :currency="candidacy.workflow == 'EE' ? 'EUR' : 'PLN'"
              :deposit-label="$t('offer.deposit')"
              :fee-label="$t('offer.rendin_fee')"
              :floor="candidacy.floor"
              :floor-label="$t('offer.floor')"
              :floors-total="candidacy.floorsTotal"
              :image="candidacy.images ? candidacy.images[0] : ''"
              :link-text="$t('buttons.view')"
              :link-url="
                $localizedPath(blok.candidacyDetailViewPath.cached_url) +
                '/' +
                candidacy.shortCode
              "
              :object-area="candidacy.objectArea"
              :parking="candidacy.hasParking ? $t('yes') : $t('no')"
              :parking-label="$t('offer.parking')"
              :pets-allowed="candidacy.petsAllowed ? $t('yes') : $t('no')"
              :pets-label="$t('offer.pets')"
              :price="candidacy.rentAmount"
              :rendin-fee="candidacy.insuranceAmount"
              :rent-label="$t('offer.price')"
              :rooms="candidacy.numberOfRooms"
              :rooms-label="$t('offer.rooms')"
              :storage="candidacy.hasStorage ? $t('yes') : $t('no')"
              :storage-label="$t('offer.storage')"
              @additional-button-click="
                deleteCandidacy(candidacy.advertisementDocId, undefined)
              "
            />
            <r-property-item
              v-if="candidacy.active && candidacy.agreementDocId"
              additional-button-icon="xmark"
              :additional-button-text="$t('offer.cancel_candidacy')"
              :address="parseFullAddressFromAddressParameters(candidacy)"
              :area-label="$t('offer.size')"
              :currency="candidacy.workflow == 'EE' ? 'EUR' : 'PLN'"
              :deposit-label="$t('offer.deposit')"
              :fee-label="$t('offer.rendin_fee')"
              :floor="candidacy.floor"
              :floor-label="$t('offer.floor')"
              :floors-total="candidacy.floorsTotal"
              :image="candidacy.images ? candidacy.images[0] : ''"
              :link-text="$t('buttons.view')"
              :link-url="
                $localizedPath(blok.candidacyDetailViewPath.cached_url) +
                '/' +
                candidacy.shortCode
              "
              missing-images-label=""
              :object-area="candidacy.objectArea"
              :parking="candidacy.hasParking ? $t('yes') : $t('no')"
              :parking-label="$t('offer.parking')"
              :pets-allowed="candidacy.petsAllowed ? $t('yes') : $t('no')"
              :pets-label="$t('offer.pets')"
              :price="candidacy.rentAmount"
              :rendin-fee="candidacy.insuranceAmount"
              :rent-label="$t('offer.price')"
              :rooms="candidacy.numberOfRooms"
              :rooms-label="$t('offer.rooms')"
              :storage="candidacy.hasStorage ? $t('yes') : $t('no')"
              :storage-label="$t('offer.storage')"
              @additional-button-click="
                deleteCandidacy(undefined, candidacy.agreementDocId)
              "
            />
          </div>
        </div>

        <div v-if="isNotAvailableNeeded" class="py-8 w-full bg-gray-50">
          <div class="mx-auto max-w-5xl px-4">
            <h2
              class="mb-4 text-xl md:text-2xl flex justify-start border-b border-gray-500"
            >
              {{ blok.notAvailableTittle }}
            </h2>
            <div
              v-for="(candidacy, index) in notAvailableCandidacies.slice(
                0,
                notAvailablePropertiesVisible,
              )"
              :key="index"
              class="mb-8"
            >
              <r-property-item
                additional-button-icon="trash"
                :additional-button-text="$t('buttons.remove_object')"
                :address="parseFullAddressFromAddressParameters(candidacy)"
                :area-label="$t('offer.size')"
                :currency="candidacy.workflow == 'EE' ? 'EUR' : 'PLN'"
                :deposit-label="$t('offer.deposit')"
                :disabled="true"
                :fee-label="$t('offer.rendin_fee')"
                :floor="candidacy.floor"
                :floor-label="$t('offer.floor')"
                :floors-total="candidacy.floorsTotal"
                :image="candidacy.images ? candidacy.images[0] : ''"
                missing-images-label=""
                :object-area="candidacy.objectArea"
                :parking="candidacy.hasParking ? $t('yes') : $t('no')"
                :parking-label="$t('offer.parking')"
                :pets-allowed="candidacy.petsAllowed ? $t('yes') : $t('no')"
                :pets-label="$t('offer.pets')"
                :price="candidacy.rentAmount"
                :rendin-fee="candidacy.insuranceAmount"
                :rent-label="$t('offer.price')"
                :rooms="candidacy.numberOfRooms"
                :rooms-label="$t('offer.rooms')"
                status="NOT_AVAILABLE"
                :storage="candidacy.hasStorage ? $t('yes') : $t('no')"
                :storage-label="$t('offer.storage')"
                @additional-button-click="
                  deleteCandidacy(
                    candidacy.advertisementDocId,
                    candidacy.agreementDocId,
                  )
                "
              />
            </div>
            <div
              v-if="notAvailableCandidacies.length > notAvailablePropertiesVisible"
              class="w-full justify-center flex"
            >
              <r-button inverted @click="showAllNotAvailableCandidacies()">
                {{ $t('buttons.load_more') }}
              </r-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { rendinFeeCalculation } from '~/utils/rendinFeeCalculation';
import { parseFullAddressFromAddressParameters } from '~/utils/addressCollector';

export default {
  name: 'ProfileCandidacies',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      rendinFeeCalculation,
      parseFullAddressFromAddressParameters,
    };
  },

  data() {
    return {
      candidacies: [],
      deleteCandidacyInProgress: false,
      notAvailablePropertiesVisible: 3,
    };
  },

  computed: {
    ...mapGetters({
      hasSessionUser: 'users/hasSessionUser',
      profile: 'users/profile',

      getCandidaciesResponse: 'candidacies/getCandidaciesResponse',
      getCandidaciesInProgress: 'candidacies/getCandidaciesInProgress',
      getCandidaciesFailed: 'candidacies/getCandidaciesFailed',
      deleteCandidateInProgress: 'candidacies/deleteCandidateInProgress',
      deleteCandidateFailed: 'candidacies/deleteCandidateFailed',
      country: 'getCountry',
    }),

    showCandidacyList() {
      if (this.getCandidaciesResponse && this.getCandidaciesResponse.length > 0) {
        return true;
      }

      return false;
    },

    isNotAvailableNeeded() {
      if (this.getCandidaciesResponse) {
        for (
          let orderNumber = 0;
          orderNumber < this.getCandidaciesResponse.length;
          orderNumber++
        ) {
          if (!this.getCandidaciesResponse[orderNumber].active) {
            return true;
          }
        }
      }

      return false;
    },

    notAvailableCandidacies() {
      if (this.getCandidaciesResponse) {
        let candidaciesList = null;
        candidaciesList = this.getCandidaciesResponse.filter(
          (candidacy) => !candidacy.active,
        );

        return candidaciesList;
      }

      return false;
    },
  },

  beforeMount() {
    if (!this.hasSessionUser) {
      // redirect to login page and open SignInModal
      const path = this.$localizedPath(this.$router.currentRoute.value.path);
      navigateTo(
        {
          path: '/',
          query: {
            login: 'open',
            redirectUrl: path,
          },
        },
        { external: true },
      );
    }
  },

  mounted: function () {
    this.actionGetCandidacies();
  },

  methods: {
    ...mapActions({
      actionGetCandidacies: 'candidacies/getCandidacies',
      actionDeleteCandidacy: 'candidacies/deleteCandidacy',
      actionReportErrorToSentry: 'tracker/reportErrorToSentry',
    }),

    deleteCandidacy(advertisementId, agreementId) {
      this.actionDeleteCandidacy({
        profileId: this.profile.id,
        agreementId: agreementId,
        advertisementId: advertisementId,
      }).then(() => {
        this.actionGetCandidacies();
      });
    },

    showAllNotAvailableCandidacies() {
      this.notAvailablePropertiesVisible = 100;
    },
  },
};
</script>
