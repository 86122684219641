<template>
  <r-overlay
    body-classes="flex-h-screen sm:flex-h-auto"
    :bottom-bar="showModal"
    size="small"
    :visible="showModal"
    @close="closeOverlay()"
  >
    <div class="flex flex-col text-center h-full p-4 sm:px-12 pt-8">
      <h3 class="font-medium text-lg mb-4">
        {{ $t('agreement.dialog.terminate_pl_agreement.title') }}
      </h3>
      <p>{{ $t('agreement.dialog.terminate_pl_agreement.content') }}</p>
    </div>
    <template #bottom-bar>
      <div class="p-4 sm:px-12 flex gap-2 flex-col">
        <r-button
          border-color="light-gray"
          color="black"
          display="block"
          inverted
          :label="$t('buttons.close')"
          size="small"
          @click="closeOverlay()"
        />
        <r-button
          display="block"
          icon="copy"
          icon-class="ml-3"
          icon-position-after
          size="small"
          @click="copyEmailClick()"
        >
          <span ref="copyTerminatePLModal">{{ $t('buttons.copy_email') }}</span>
        </r-button>
      </div>
    </template>
  </r-overlay>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TerminatePLModal',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closeOverlay', 'copyEmailClick'],
  methods: {
    closeOverlay() {
      this.$emit('closeOverlay');
    },

    copyEmailClick() {
      this.$emit('copyEmailClick', this.$refs.copyTerminatePLModal);
    },
  },
});
</script>
