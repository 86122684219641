<template>
  <div class="bg-gray-50 pb-20 relative flex-grow">
    <!-- Background -->
    <r-banner
      v-if="
        !deleteRequestInProgress &&
        !postAdvertisementCreateCopyInProgress &&
        (getAdvertisementFailed || getAdvertisementResponse)
      "
      :images="blok.headerImage"
    />
    <!-- Loading -->
    <div
      v-if="
        (getAdvertisementInProgress && !advertisement) ||
        deleteRequestInProgress ||
        postAdvertisementCreateCopyInProgress
      "
      class="min-h-1/2-screen flex justify-center items-center relative z-10"
    >
      <r-loader type="animatedLogo" />
    </div>

    <!-- Advertisement  -->
    <div
      v-else-if="getAdvertisementFailed || getAdvertisementResponse"
      class="relative mx-auto max-w-5xl px-4 py-10 md:py-20 flex flex-col gap-2"
    >
      <!-- Advertisement not found -->
      <div
        v-if="getAdvertisementFailed || getAdvertisementResponse.deleted"
        class="bg-white rounded-lg shadow-lg text-center mx-auto max-w-3xl p-4 md:px-8 my-44"
      >
        <div v-if="blok.noAdvertisementsTittle" class="mt-8 text-5xl font-bold">
          {{ blok.noAdvertisementsTittle }}
        </div>
        <div v-if="blok.noAdvertisementContent">
          <component
            :is="theBlok.component"
            v-for="theBlok in blok.noAdvertisementContent.filter((item) =>
              ['RichTextBlok', 'ButtonBlok'].includes(item.component),
            )"
            :key="theBlok._uid"
            :blok="theBlok"
            class="r-fade my-6 md:my-12 text-lg font-light"
          />
        </div>
      </div>
      <!-- Advertisement view -->
      <div v-else-if="!deleteRequestInProgress && !getAdvertisementResponse.deleted">
        <div class="py-4">
          <r-button
            v-if="isEditMode"
            border-color="light-gray"
            color="black"
            hover="hover:text-rendin-500 hover:shadow-md"
            icon="arrow-left-long"
            icon-class="mr-2"
            inverted
            :label="blok.backButtonText"
            @click="closeEditMode()"
          />

          <div v-else class="flex justify-between gap-2">
            <div>
              <r-button
                border-color="light-gray"
                color="black"
                hover="hover:text-rendin-500 hover:shadow-md"
                icon="arrow-left-long"
                icon-class="mr-2"
                inverted
                :label="blok.backButtonText"
                :to="$localizedPath(blok.backButtonLink.cached_url)"
              />
            </div>
            <div>
              <listing-actions
                :listing-status="advertisement.status"
                :show-status-change-switch="showStatusChangeSwitch"
                @click-activate-listing="changeAdvertisementStatus()"
                @click-deactivate-listing="changeAdvertisementStatus()"
                @click-duplicate-listing="showDuplicateObjectModal()"
                @click-edit-listing="openEditMode()"
                @click-remove-listing="showDeleteObjectModal()"
                @click-share-listing="openSharingModal('Listing Info')"
              />
            </div>
          </div>
        </div>

        <div v-if="!isEditMode" class="py-2">
          <r-tabs-flat
            v-model="activeOverviewTab"
            justify-tabs="justify-center"
            :tabs="overviewTabs"
          />
        </div>

        <!--                Advertisement content           -->
        <div v-if="!isEditMode">
          <div
            v-show="activeOverviewTab === 'listing'"
            class="flex flex-col-reverse md:flex-row md:gap-2"
          >
            <!--             Property        -->
            <div class="w-full md:w-5/12 max-w-xl py-4 md:pt-0 mx-auto">
              <r-property
                v-if="advertisement"
                :additional-fees="advertisement.additionalFees"
                :address="parseFullAddressFromAddressParameters(advertisement, true)"
                :area-label="$t('offer.size')"
                :broker-fee-label="$t('offer.additional_fee.type.broker_fee')"
                class="w-full"
                :contract-fee-label="$t('offer.additional_fee.type.contract_fee')"
                :currency="getCurrency()"
                :damage-protection-label="$t('offer.damage_protection')"
                :damage-protection-multiplier="getDamageProtectionMultiplier()"
                damages-protection-details
                :deposit-label="$t('offer.deposit')"
                :description-label="$t('offer.description')"
                :edit-label="advertisementIsDisabled ? '' : $t('offer.edit_label')"
                edit-label-icon="pen"
                :fee-label="$t('offer.rendin_fee')"
                :fixed-utilities-label="
                  advertisement.workflow === workflow.POLAND
                    ? $t('offer.additional_fee.type.fixed_utilities.pl')
                    : $t('offer.additional_fee.type.fixed_utilities.ee')
                "
                :floor="
                  advertisement.propertyType === 'APARTMENT'
                    ? advertisement.floor
                    : null
                "
                :floor-label="$t('offer.floor')"
                :floors-total="
                  advertisement.propertyType === 'APARTMENT'
                    ? advertisement.floorsTotal
                    : null
                "
                :has-parking="advertisement.hasParking"
                :has-storage="advertisement.hasStorage"
                :images="advertisement.images"
                :insurance-invoice-to="advertisement.insuranceInvoiceTo"
                is-landlord-card
                :object-area="advertisement.objectArea"
                :parking-label="$t('offer.parking')"
                :pets-allowed="advertisement.petsAllowed"
                :pets-allowed-label="$t('offer.pets')"
                :property-type="
                  $t(
                    `offer.property_type.${advertisement.propertyType ?? adPropertyTypes.APARTMENT}`,
                  )
                "
                :property-type-label="$t('offer.property_type.label')"
                :read-more-label="$t('offer.read_more')"
                :rendin-fee="advertisement.insuranceAmount"
                :rendin-fee-details="showRendinFeeDetailsIcon"
                :rent-amount="advertisement.rentAmount"
                :rent-label="$t('offer.price')"
                :rooms="advertisement.numberOfRooms"
                :rooms-label="$t('offer.rooms')"
                :storage-label="$t('offer.storage')"
                @click-back-link="showObjectEditMode"
                @on-click-damages-protection-details="
                  () => (showDamagesProtectionModal = true)
                "
                @on-rendin-fee-details-click="showPropertyRendinFeeModal = true"
              >
                <template #description>
                  {{ advertisement.description }}
                </template>
              </r-property>
              <r-overlay
                size="small"
                :visible="showPropertyModal"
                @close="closeModals()"
              >
                <div class="pb-4">
                  <div class="mt-8 mx-4 sm:mx-8">
                    <component
                      :is="theBlok.component"
                      v-for="theBlok in propertyModalContent.filter((item) =>
                        ['RichTextBlok'].includes(item.component),
                      )"
                      :key="theBlok._uid"
                      :blok="theBlok"
                    />
                  </div>

                  <r-button
                    display="block"
                    :label="$t('buttons.close')"
                    no-background
                    uppercase
                    @click="closeModals()"
                  />
                </div>
              </r-overlay>
              <r-overlay
                size="small"
                :visible="showDamagesProtectionModal"
                @close="closeModals()"
              >
                <div class="pb-4">
                  <div class="mt-8 mx-4 sm:mx-8">
                    <component
                      :is="theBlok.component"
                      v-for="theBlok in damagesProtectionModalContent.filter((item) =>
                        ['RichTextBlok'].includes(item.component),
                      )"
                      :key="theBlok._uid"
                      :blok="theBlok"
                    />
                  </div>

                  <r-button
                    display="block"
                    :label="$t('buttons.close')"
                    no-background
                    uppercase
                    @click="closeModals()"
                  />
                </div>
              </r-overlay>
            </div>
            <!--              Object information         -->
            <div
              class="w-full md:w-7/12 max-w-xl pb-4 mx-auto flex flex-col gap-2 border-b-4 md:border-b-0"
            >
              <!--           Toggle ACTIVE NOT_ACTIVE status -->
              <div
                v-if="showStatusChangeSwitch"
                class="flex bg-white px-4 py-2 justify-end rounded-lg shadow-lg"
                :class="{
                  'justify-between': country.toUpperCase() === workflow.POLAND,
                }"
              >
                <div v-if="country.toUpperCase() === workflow.POLAND">
                  <r-button
                    border-color="light-gray"
                    color="black"
                    icon="share"
                    inverted
                    :label="$t('buttons.share')"
                    size="small"
                    @click="openSharingModal('Listing Info')"
                  />
                </div>
                <div class="flex flex-row justify-end w-full md:w-auto items-center">
                  <span
                    v-if="blok.candidateSwitcherText && advertisementStatusActive"
                    class="pr-2"
                  >
                    {{ blok.isSearchingCandidatesText }}
                  </span>
                  <span
                    v-if="blok.candidateSwitcherText && !advertisementStatusActive"
                    class="pr-2"
                  >
                    {{ blok.notSearchingCandidatesText }}
                  </span>
                  <r-switch
                    id="switch-default"
                    :disabled="putAdvertisementInProgress"
                    name="switch-default"
                    :model-value="advertisementStatusActive"
                    @change="
                      putAdvertisementInProgress ? null : changeAdvertisementStatus()
                    "
                  />
                </div>
              </div>
              <!--            Duplicate listing  -->
              <div
                v-if="advertisementIsDisabled"
                class="bg-white p-4 rounded-lg shadow-lg"
              >
                <div
                  v-if="blok.duplicateListingActiveAgreementTitle"
                  class="text-2xl mt-2"
                >
                  {{ blok.duplicateListingActiveAgreementTitle }}
                </div>

                <div v-if="blok.duplicateListingActiveAgreementContent">
                  <component
                    :is="theBlok.component"
                    v-for="theBlok in blok.duplicateListingActiveAgreementContent.filter(
                      (item) =>
                        ['RichTextBlok', 'FeatureCheckmarkList'].includes(
                          item.component,
                        ),
                    )"
                    :key="theBlok._uid"
                    :blok="theBlok"
                  />
                </div>
                <r-button
                  :is-loading="postAdvertisementCreateCopyInProgress"
                  :label="blok.duplicateListingActiveAgreementButtonText"
                  width="full"
                  @click="duplicateAdvertisement(advertisement.id)"
                />
              </div>
              <!--            How to invite -->
              <r-accordion
                v-if="
                  !advertisementIsDisabled &&
                  (advertisementStatusActive || advertisementStatusNotActive)
                "
                class="rounded-lg shadow-lg"
                flavor="noFalvor"
                icon="user-group"
                :open-on-mount="candidates.length ? candidates.length <= 3 : true"
                :title="blok.candidateTittle.toUpperCase()"
              >
                <div v-if="blok.candidateAccordionDescription">
                  <RichText :blok="blok.candidateAccordionDescription" />
                </div>

                <div v-if="blok.candidateAccordionExplainer">
                  <RichText :blok="blok.candidateAccordionExplainer" />
                </div>

                <r-accordion
                  v-if="blok.candidateFirstAccordionTitle"
                  :title="blok.candidateFirstAccordionTitle"
                >
                  <div v-if="blok.candidateFirstAccordionContent">
                    <component
                      :is="theBlok.component"
                      v-for="theBlok in blok.candidateFirstAccordionContent.filter(
                        (item) =>
                          ['RichTextBlok', 'ButtonBlok'].includes(item.component),
                      )"
                      :key="theBlok._uid"
                      :blok="theBlok"
                    />
                  </div>
                  <r-copy-to-clipboard :text-to-copy="candidatesClipboardText" />
                </r-accordion>
                <div
                  v-if="blok.candidateAccordionContent?.length > 0"
                  class="flex flex-col gap-4 pt-4"
                >
                  <component
                    :is="theBlok.component"
                    v-for="theBlok in blok.candidateAccordionContent.filter((item) =>
                      ['RichTextBlok', 'ButtonBlok', 'Accordion'].includes(
                        item.component,
                      ),
                    )"
                    :key="theBlok._uid"
                    :blok="theBlok"
                  />
                </div>

                <div v-if="country.toUpperCase() === workflow.ESTONIA">
                  <r-button
                    icon="share"
                    icon-class="ml-2"
                    icon-position-after
                    :label="$t('buttons.share_listing')"
                    size="small"
                    width="full"
                    @click="openSharingModal('Listing Info')"
                  />
                </div>
              </r-accordion>

              <!--            Ready to publish section, status DRAFT to ACTIVE -->
              <div
                v-if="advertisementStatusDraft"
                class="bg-white flex flex-col px-4 pb-4 rounded-lg shadow-lg text-lg"
              >
                <r-title-with-picture
                  :content="
                    advertisementReadyToBePublished
                      ? blok.readyToBePublishedContent
                      : blok.notActiveDisclaimerText
                  "
                  content-style="text-base text-justify"
                  :image="
                    advertisementReadyToBePublished
                      ? blok.readyToBePublishedImage.filename
                      : blok.notActiveDisclaimerImage.filename
                  "
                  image-size="max-h-40 md:max-h-52"
                  :title="
                    advertisementReadyToBePublished
                      ? blok.readyToBePublishedTitle
                      : blok.notActiveDisclaimerTitle
                  "
                  title-style="text-2xl"
                />
                <div class="border"></div>
                <r-button
                  class="mt-4"
                  :icon="advertisementReadyToBePublished ? 'check' : null"
                  :is-loading="
                    advertisementReadyToBePublished
                      ? addDataLoading ||
                        imagesUploadingInProgress ||
                        additionalFeeChangeInProgress
                      : false
                  "
                  width="full"
                  @click="
                    advertisementReadyToBePublished
                      ? publishAdvertisement()
                      : showObjectImagesEditMode()
                  "
                >
                  {{
                    advertisementReadyToBePublished
                      ? blok.readyToBePublishedButtonText
                      : blok.notActiveDisclaimerButtonText
                  }}
                </r-button>
              </div>

              <!--            Create agreement section-->
              <r-accordion
                v-if="blok.agreementAccordionTitle"
                icon="file"
                :title="blok.agreementAccordionTitle"
              >
                <div>
                  <div v-if="advertisement?.agreements?.length > 0">
                    <div v-if="blok.viewRelatedAgreementText" class="mt-3">
                      <RichText :blok="blok.viewRelatedAgreementText" />
                    </div>

                    <div
                      v-for="agreement in advertisement.agreements"
                      :key="agreement.id"
                      class="my-2"
                    >
                      <r-button
                        :label="blok.viewAgreementButton"
                        target="_blank"
                        :to="
                          $localizedPath(`/dashboard/agreement/${agreement.parentId}`)
                        "
                        width="full"
                      />
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="blok.createRelatedAgreementText" class="mt-3">
                      <RichText :blok="blok.createRelatedAgreementText" />
                    </div>
                    <div class="pb-2">
                      <r-button
                        :is-loading="createAgreementFromAdvertisementInProgress"
                        :label="blok.createAgreementButton"
                        target="_blank"
                        width="full"
                        @click="createAgreementFromAdvertisement()"
                      />
                      <div
                        v-if="createAgreementFromAdvertisementFailed"
                        class="text-error text-center text-md mt-2"
                      >
                        {{ $t('errors.something_wrong') }}
                      </div>
                    </div>
                  </div>
                </div>
              </r-accordion>

              <!--    Price calculator     -->
              <r-accordion
                v-if="blok.priceCalculatorTitle"
                icon="money-bill-wave"
                :title="blok.priceCalculatorTitle.toUpperCase()"
              >
                <component
                  :is="theBlok.component"
                  v-for="theBlok in blok.priceCalculatorContent.filter((item) =>
                    ['RichTextBlok', 'ButtonBlok'].includes(item.component),
                  )"
                  :key="theBlok._uid"
                  :blok="theBlok"
                />
              </r-accordion>
            </div>
          </div>

          <div v-show="activeOverviewTab === 'candidates'">
            <candidates-list
              :add-tenant-in-progress="addTenantInProgress"
              :advertisement-id="advertisement.id"
              :agreement-id="agreementId"
              :blok="blok"
              :button-label-add-tenant="blok.buttonLabelAddTenant"
              :candidate-list="candidates"
              :candidates-clipboard-text="candidatesClipboardText"
              :contact-info-disabled-content="blok.contactInfoDisabledContent"
              :contact-info-title="blok.contactInfoTitle"
              :create-agreement-content="blok.createAgreementContent"
              :create-agreement-title="blok.createAgreementTitle"
              :delete-candidacy-in-progress="deleteCandidacyInProgress"
              :get-candidates-in-progress="getCandidatesInProgress"
              :invite-url="
                linkToInvite(advertisement.invitationShortCode, advertisement.workflow)
              "
              :is-advertisement-active="advertisementStatusActive"
              :listing-address="parseFullAddressFromAddressParameters(advertisement)"
              :no-candidates-content="blok.noCandidatesContent"
              :no-candidates-image="blok.noCandidatesImage"
              :no-candidates-title="blok.noCandidatesTitle"
              :not-active-disclaimer-image="
                advertisementStatusDraft && advertisementReadyToBePublished
                  ? blok.readyToBePublishedImage
                  : blok.notActiveDisclaimerImage
              "
              :not-active-disclaimer-text="
                advertisementStatusDraft && advertisementReadyToBePublished
                  ? blok.readyToBePublishedContent
                  : blok.notActiveDisclaimerText
              "
              :not-active-disclaimer-title="
                advertisementStatusDraft && advertisementReadyToBePublished
                  ? blok.readyToBePublishedTitle
                  : blok.notActiveDisclaimerTitle
              "
              :tittle="blok.candidateListTittle"
              @add-tenant-clicked="addCandidateToAgreement"
              @click-activate-listing="
                advertisementReadyToBePublished
                  ? changeAdvertisementStatus()
                  : showObjectImagesEditMode()
              "
              @delete-button-clicked="deleteCandidacy"
            />
          </div>
        </div>

        <!--                Advertisement edit view             -->
        <div v-if="isEditMode">
          <AdvertisementEditView
            :advertisement-from-firebase="advertisement"
            :blok="blok"
            :is-mobile-screen="isMobileScreen"
            :move-to-tab="openAdvertisementEditViewTab"
          />
        </div>
      </div>

      <!-- Delete ad modal-->
      <RemoveListingModal
        :show-modal="showDeleteObjectOverlay"
        @click-remove-listing="deleteAdvertisement()"
        @close-overlay="showDeleteObjectOverlay = false"
      />

      <!-- Duplicate ad modal-->
      <DuplicateListingModal
        :show-modal="showDuplicateObjectOverlay"
        @click-duplicate-listing="duplicateAdvertisement"
        @close-overlay="showDuplicateObjectOverlay = false"
      />

      <!-- Add as tenant error modal -->
      <r-overlay
        size="small"
        :visible="!!addAsATenantError"
        @close="addAsATenantError = ''"
      >
        <div class="p-6 md:px-10 flex flex-col justify-center items-center">
          <div class="py-10">
            <div class="md:text-lg text-center">
              {{ addAsATenantError }}
            </div>
          </div>
          <div class="w-full grid gap-3 mt-12">
            <r-button
              :label="$t('buttons.cancel')"
              width="full"
              @click="addAsATenantError = ''"
            />
          </div>
        </div>
      </r-overlay>

      <!-- Duplicate agreement for listing-->
      <r-overlay
        :prevent-close="duplicateAgreementAndAddTenantInProgress"
        size="small"
        :visible="!!activeAgreementDuplicateModalOpen"
        @close="activeAgreementDuplicateModalOpen = false"
      >
        <div class="p-6 flex flex-col justify-center items-center">
          <div class="pt-10">
            <div
              v-if="blok.duplicateAgreementModalTitle"
              class="text-xl text-center mb-4"
            >
              {{ blok.duplicateAgreementModalTitle }}
            </div>
            <RichText
              :blok="blok.duplicateAgreementModalContent"
              class="text-gray-600"
            />
          </div>

          <div class="w-full">
            <r-button
              :is-loading="duplicateAgreementAndAddTenantInProgress"
              :label="blok.duplicateAgreementButtonText"
              width="full"
              @click="duplicateAgreementAndAddTenant()"
            />
            <div
              v-if="duplicateAgreementAndAddTenantFailed"
              class="text-error text-center text-md mt-2"
            >
              {{ $t('errors.something_wrong') }}
            </div>
          </div>
        </div>
      </r-overlay>

      <!-- Sharing modal for Invite -->
      <r-sharing-modal
        :description="parseFullAddressFromAddressParameters(advertisement)"
        :modal-button-text="$t('web_sharing_modal.copy_link')"
        :modal-description="$t('web_sharing_modal.or_share_in_social_media')"
        :modal-title="$t('web_sharing_modal.share_this_link')"
        :show-modal="sharingModalOpen"
        :title="parseFullAddressFromAddressParameters(advertisement)"
        :url="linkToInvite(advertisement.invitationShortCode, advertisement.workflow)"
        @close-overlay="sharingModalOpen = false"
        @copy-link-click="
          trackInviteLinkCopyEvent({
            objectType: trackingProcessNames.ADVERTISEMENT,
            openingLocation: sharingModalLocation,
            channel: 'Link',
          })
        "
        @custom-web-sharing-modal-open="
          trackInviteLinkOpenSharingEvent({
            objectType: trackingProcessNames.ADVERTISEMENT,
            openingLocation: sharingModalLocation,
            modalType: sharingModalTypes.DESKTOP_SHARE_MODAL,
          })
        "
        @native-web-sharing-modal-open="
          trackInviteLinkOpenSharingEvent({
            objectType: trackingProcessNames.ADVERTISEMENT,
            openingLocation: sharingModalLocation,
            modalType: sharingModalTypes.MOBILE_NATIVE_SHARE,
          })
        "
        @social-network-popup-open="
          (network) =>
            trackInviteLinkCopyEvent({
              objectType: trackingProcessNames.ADVERTISEMENT,
              openingLocation: sharingModalLocation,
              channel: network,
            })
        "
      />
    </div>

    <LoadingModal :show-modal="changeAdvertisementStatusInProgress" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { isNil, get } from 'lodash';
import { rendinFeeCalculation } from '~/utils/rendinFeeCalculation.js';
import CandidatesList from '~/components/section/profile-advertisement-object-view/CandidatesList';
import AdvertisementEditView from '~/components/section/profile-advertisement-object-view/AdvertisementEditView';
import ListingActions from '~/components/section/profile-advertisement-object-view/ListingActions';
import RemoveListingModal from '~/components/section/profile-advertisement-object-view/RemoveListingModal';
import DuplicateListingModal from '~/components/section/profile-advertisement-object-view/DuplicateListingModal';
import LoadingModal from '~/components/section/profile-advertisement-object-view/LoadingModal';
import {
  AdvertisementSourceType,
  additionalFeeType,
  additionalFeeInterval,
  webSourceType,
  workflow,
  sharingModalTypes,
  LocalStorageKeys,
  advertisementStatuses,
  userRole,
  adPropertyTypes,
  advertisementEditViewTabs,
} from '~/utils/constants';
import { trackingProcessNames } from '~/utils/trackerConstants';
import RSharingModal from '~/components/r-interface-components/r-sharing-modal';
import { validateAdvertisementDataForFinalize } from '~/utils/validateAdvertisementDataForFinalize.js';
import AddressSearchMolecule from '~/components/snippets/AddressSearchMolecule.vue';
import { parseFullAddressFromAddressParameters } from '~/utils/addressCollector.js';

export default {
  name: 'ProfileAdvertisementObjectView',
  components: {
    AddressSearchMolecule,
    RSharingModal,
    CandidatesList,
    AdvertisementEditView,
    ListingActions,
    RemoveListingModal,
    DuplicateListingModal,
    LoadingModal,
  },

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      rendinFeeCalculation,
      parseFullAddressFromAddressParameters,
    };
  },

  data() {
    return {
      showDeleteObjectOverlay: false,
      showDuplicateObjectOverlay: false,
      deleteRequestInProgress: false,
      advertisement: {},
      candidates: {},
      getCandidatesInProgress: false,
      deleteCandidacyInProgress: false,
      isEditMode: false,
      imagesUploadingInProgress: false,
      addDataLoading: false,
      additionalFeeChangeInProgress: false,
      isMobileScreen: true,
      fixedUtilitiesFee: {
        amount: null,
        type: additionalFeeType.FIXED_UTILITIES,
        paymentInterval: additionalFeeInterval.MONTHLY,
      },
      workflow,
      addTenantInProgress: false,
      addAsATenantError: '',
      source: webSourceType.WEB_ADVERTISEMENT_EDIT,
      sharingModalOpen: false,
      activeAgreementDuplicateModalOpen: false,
      candidateProfileIdForNewAgreement: null,
      duplicateAgreementAndAddTenantInProgress: false,
      duplicateAgreementAndAddTenantFailed: false,
      sharingModalLocation: null,
      trackingProcessNames,
      sharingModalTypes,
      webSourceType,
      insuranceInvoiceTo: null,
      showPropertyRendinFeeModal: false,
      showDamagesProtectionModal: false,
      openAdvertisementEditViewTab: null,
      changeAdvertisementStatusInProgress: false,
    };
  },

  computed: {
    adPropertyTypes() {
      return adPropertyTypes;
    },
    ...mapGetters({
      hasSessionUser: 'users/hasSessionUser',
      isENVIsProduction: 'isENVIsProduction',
      currencyLabel: 'getCurrencyLabel',
      country: 'getCountry',
      getLocale: 'getLocale',
      getAdvertisementResponse: 'advertisements/getAdvertisementResponse',
      getAdvertisementInProgress: 'advertisements/getAdvertisementInProgress',
      getAdvertisementFailed: 'advertisements/getAdvertisementFailed',
      putAdvertisementResponse: 'advertisements/putAdvertisementResponse',
      putAdvertisementInProgress: 'advertisements/putAdvertisementInProgress',
      putAdvertisementFailed: 'advertisements/putAdvertisementFailed',
      getAnalyticEventNames: 'tracker/getAnalyticEventNames',
      getAnalyticEventProperties: 'tracker/getAnalyticEventProperties',
      createAgreementFromAdvertisementInProgress:
        'agreements/createAgreementFromAdvertisementInProgress',
      createAgreementFromAdvertisementFailed:
        'agreements/createAgreementFromAdvertisementFailed',
      postAdvertisementCreateCopyInProgress:
        'advertisements/postAdvertisementCreateCopyInProgress',
    }),

    advertisementReadyToBePublished() {
      return (
        !this.advertisementStatusActive &&
        validateAdvertisementDataForFinalize(this.advertisement).success
      );
    },

    showPropertyModal() {
      return this.showPropertyRendinFeeModal;
    },

    propertyModalContent() {
      if (
        this.showPropertyRendinFeeModal &&
        this.blok.propertyRendinFeeLandlordPaysModal &&
        this.advertisement.insuranceInvoiceTo === userRole.LANDLORD
      ) {
        return this.blok.propertyRendinFeeLandlordPaysModal;
      } else if (
        this.showPropertyRendinFeeModal &&
        this.blok.propertyRendinFeeTenantPaysModal &&
        this.advertisement.insuranceInvoiceTo === userRole.TENANT
      ) {
        return this.blok.propertyRendinFeeTenantPaysModal;
      } else {
        return [];
      }
    },
    damagesProtectionModalContent() {
      const content = [];

      content.push(...this.blok.propertyDamagesProtectionModal);

      if (
        this.advertisement.propertyType === adPropertyTypes.ROOM &&
        this.blok.propertyDamagesProtectionModalRoomAddon
      ) {
        content.push(...this.blok.propertyDamagesProtectionModalRoomAddon);
      }

      return content;
    },
    showRendinFeeDetailsIcon() {
      const showTenantPays =
        this.advertisement.insuranceInvoiceTo === userRole.TENANT &&
        this.blok.propertyRendinFeeTenantPaysModal?.length > 0;
      const showLandlordPays =
        this.advertisement.insuranceInvoiceTo === userRole.LANDLORD &&
        this.blok.propertyRendinFeeLandlordPaysModal?.length > 0;
      return showLandlordPays || showTenantPays;
    },

    advertisementStatusActive() {
      return this.advertisement.status === advertisementStatuses.ACTIVE;
    },

    advertisementStatusNotActive() {
      return this.advertisement.status === advertisementStatuses.NOT_ACTIVE;
    },

    advertisementStatusDraft() {
      return this.advertisement.status === advertisementStatuses.DRAFT;
    },

    advertisementIsDisabled() {
      // Disabled advertisement is when advertisement.statusAllowed  is empty [] or [ “NOT_ACTIVE” ]
      if (Array.isArray(this.advertisement?.statusAllowed)) {
        return (
          !this.advertisement.statusAllowed?.length ||
          (this.advertisement.statusAllowed.length === 1 &&
            this.advertisement.statusAllowed[0] === advertisementStatuses.NOT_ACTIVE)
        );
      } else {
        return false;
      }
    },

    showStatusChangeSwitch() {
      const switchStatuses = [
        advertisementStatuses.ACTIVE,
        advertisementStatuses.NOT_ACTIVE,
      ];
      return (
        this.advertisement?.statusAllowed?.some((status) =>
          switchStatuses.includes(status),
        ) &&
        (this.advertisementStatusActive || this.advertisementStatusNotActive)
      );
    },

    overviewTabs() {
      return [
        {
          id: 'listing',
          title: this.blok.mainInfoTab,
        },
        {
          id: 'candidates',
          title: `${this.blok.candidateListTittle} ${this.candidatesCount}`,
        },
      ];
    },

    candidatesCount() {
      return this.candidates?.length > 0 ? `(${this.candidates?.length})` : '';
    },
    agreementId() {
      const agreementExists = this.advertisement?.agreements?.length > 0;
      return agreementExists ? this.advertisement.agreements[0].parentId : '';
    },

    activeOverviewTab: {
      get() {
        switch (this.$route.query.tab) {
          case 'candidates':
            return 'candidates';
          default:
            return 'listing';
        }
      },
      set(newVal) {
        switch (newVal) {
          case 'listing':
            this.$router.push({
              name: 'object_view',
              params: {
                objectid: this.$route.params.objectid,
                lang: this.$getLocale(),
              },
            });
            return;
          case 'candidates':
            this.$router.push({
              name: 'object_view',
              params: {
                objectid: this.$route.params.objectid,
                lang: this.$getLocale(),
              },
              query: { tab: 'candidates' },
            });
        }
      },
    },

    candidatesClipboardText() {
      return this.$t('clipboard_copy.share_advertisement', {
        address: this.parseFullAddressFromAddressParameters(this.advertisement, true),
        rooms: this.advertisement.numberOfRooms,
        area: this.advertisement.objectArea,
        floor: this.advertisement.floor,
        price: `${this.advertisement.rentAmount} ${this.currencyLabel}`,
        description: this.advertisement.description,
      });
    },
  },

  beforeMount() {
    if (!this.hasSessionUser) {
      // redirect to login page and open SignInModal
      const path = this.$localizedPath(this.$router.currentRoute.value.path);
      navigateTo(
        {
          path: '/',
          query: {
            login: 'open',
            redirectUrl: path,
          },
        },
        { external: true },
      );
    }

    this.onResize();
  },

  mounted() {
    this.getAdvertisement();
    this.getCandidates();

    window.addEventListener('resize', this.onResize);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    ...mapMutations({
      setOverridenSuccessfulRedirect: 'session/SET_OVERRIDDEN_SUCCESSFUL_REDIRECT',
    }),
    ...mapActions({
      actionGetAgreement: 'agreements/getAgreement',
      actionGetAdvertisement: 'advertisements/getAdvertisement',
      actionPutAdvertisement: 'advertisements/putAdvertisement',
      actionDeleteAdvertisement: 'advertisements/deleteAdvertisement',
      actionReportErrorToSentry: 'tracker/reportErrorToSentry',
      actionGetCandidates: 'candidacies/getCandidates',
      actionDeleteCandidacy: 'candidacies/deleteCandidacy',
      actionTrackDigibrokerEvent: 'tracker/trackDigibrokerEvent',
      actionSetSuccessModal: 'adds/setDigibrokerSuccessModal',
      actionPutAgreement: 'agreements/putAgreement',
      actionCopyAgreement: 'agreements/copyAgreement',
      actionCreateAgreementFromAdvertisement:
        'agreements/createAgreementFromAdvertisement',
      trackCandidatesAddAsATenantEvent: 'tracker/trackCandidatesAddAsATenantEvent',
      trackCandidatesRemoveCandidateEvent:
        'tracker/trackCandidatesRemoveCandidateEvent',
      trackCandidatesCopyAgreementWithATenantEvent:
        'tracker/trackCandidatesCopyAgreementWithATenantEvent',
      reportErrorToSentry: 'tracker/reportErrorToSentry',
      actionPutAdvertisementStatusAsActive:
        'advertisements/putAdvertisementStatusAsActive',
      actionPostAdvertisementCreateCopy: 'advertisements/postAdvertisementCreateCopy',
      trackInviteLinkOpenSharingEvent: 'tracker/trackInviteLinkOpenSharingEvent',
      trackInviteLinkCopyEvent: 'tracker/trackInviteLinkCopyEvent',
      actionSetDigibrokerSuccessModalInvite: 'adds/setDigibrokerSuccessModalInvite',
    }),

    /* Cleans up digibroker cache if user published add in Ad edit */
    cleanCache() {
      if (import.meta.server) return;

      const cache = JSON.parse(localStorage.getItem(LocalStorageKeys.DIGIBROKER_CACHE));
      if (!cache) {
        return;
      }

      if (
        !isNil(cache.advertisementFirebaseKey) &&
        cache.advertisementFirebaseKey === this.advertisement.id
      ) {
        localStorage.removeItem(LocalStorageKeys.DIGIBROKER_CACHE);
      }
    },

    publishAdvertisement() {
      this.addDataLoading = true;
      return this.actionPutAdvertisementStatusAsActive({
        id: this.advertisement.id,
        source: webSourceType.WEB_ADVERTISEMENT_EDIT,
      })
        .then(() => {
          this.actionSetSuccessModal({
            visible: true,
            parent: webSourceType.WEB_ADVERTISEMENT_EDIT,
          });
          this.cleanCache();
          this.getAdvertisement();
        })
        .then(() => {
          const eventProperties = {
            [this.getAnalyticEventProperties.ADVERTISEMENT_KEY]: this.advertisement.id
              ? this.advertisement.id
              : null,
          };

          this.actionTrackDigibrokerEvent({
            eventName: this.getAnalyticEventNames.DIGIBROKER_FINALIZE_AD,
            props: eventProperties,
          });
        })
        .finally(() => {
          this.addDataLoading = false;
        })
        .catch((err) => this.actionReportErrorToSentry(err));
    },

    changeAdvertisementStatus() {
      const newStatus =
        this.advertisement.status === 'ACTIVE' ? 'NOT_ACTIVE' : 'ACTIVE';
      const object = {
        status: newStatus,
      };
      this.changeAdvertisementStatusInProgress = true;
      this.putAdvertisementStatus(newStatus)
        .then(() => (this.advertisement = { ...this.advertisement, ...object }))
        .then(() => this.getAdvertisement())
        .catch((err) => this.actionReportErrorToSentry(err))
        .finally(() => {
          this.changeAdvertisementStatusInProgress = false;
        });
    },

    showObjectEditMode() {
      this.openEditMode();
    },

    showObjectImagesEditMode() {
      this.openAdvertisementEditViewTab = advertisementEditViewTabs.IMAGES;
      this.openEditMode();
    },

    showDeleteObjectModal() {
      this.showDeleteObjectOverlay = true;
    },

    showDuplicateObjectModal() {
      this.showDuplicateObjectOverlay = true;
    },

    createAgreementFromAdvertisement() {
      return this.actionCreateAgreementFromAdvertisement({
        id: this.advertisement.id,
      }).then((result) => {
        this.$router.push({
          path: this.$localizedPath(`/dashboard/agreement/${result.response}`),
        });
      });
    },

    duplicateAdvertisement() {
      this.actionPostAdvertisementCreateCopy({
        id: this.advertisement.id,
        source: AdvertisementSourceType.WEB_COPY,
      }).then((response) => {
        if (response.response) {
          this.$router.push({
            path: this.$localizedPath(`/dashboard/object/${response.response}`),
          });

          const eventName = this.getAnalyticEventNames.ADVERTISEMENT_DUPLICATE_LISTING;
          const eventProperties = {
            [this.getAnalyticEventProperties.FROM_ADVERTISEMENT_KEY]:
              this.advertisement.id,
            [this.getAnalyticEventProperties.ADVERTISEMENT_KEY]: response.response,
          };
          this.actionTrackDigibrokerEvent({
            eventName,
            props: eventProperties,
          });
        }
      });
    },

    addCandidateToAgreement(candidateProfileId) {
      if (this.advertisementIsDisabled) {
        this.candidateProfileIdForNewAgreement = candidateProfileId;
        this.activeAgreementDuplicateModalOpen = true;
      } else {
        this.addTenantToAgreement(candidateProfileId);
      }
    },

    duplicateAgreementAndAddTenant() {
      this.duplicateAgreementAndAddTenantInProgress = true;
      let copyAgreementId;
      this.actionCopyAgreement({ id: this.agreementId })
        .then((result) => {
          copyAgreementId = result.response;
        })
        .then(() =>
          this.actionPutAgreement({
            id: copyAgreementId,
            agreement: { tenantProfileId: this.candidateProfileIdForNewAgreement },
          }),
        )
        .then(() => {
          this.$router.push({
            path: this.$localizedPath(`/dashboard/agreement/${copyAgreementId}`),
          });
        })
        .then(() =>
          this.trackCandidatesCopyAgreementWithATenantEvent({
            candidateId: this.candidateProfileIdForNewAgreement,
            processName: trackingProcessNames.ADVERTISEMENT,
            advertisementId: this.advertisement.id,
            agreementId: copyAgreementId,
          }),
        )
        .finally(() => {
          this.duplicateAgreementAndAddTenantInProgress = false;
        });
    },

    deleteAdvertisement() {
      this.showDeleteObjectOverlay = false;
      this.deleteRequestInProgress = true;
      this.changeAdvertisementDeleteStatus(true)
        .then(() => {
          this.$router.push({
            path: this.$localizedPath(this.blok.backButtonLink.cached_url),
          });
        })
        .catch((err) => this.actionReportErrorToSentry(err));
    },
    addTenantToAgreement(candidateProfileId) {
      this.addTenantInProgress = true;
      /* TODO - currently we just take the first agreement, should build a modal for selection or limit agreements per ad to one in API */
      let agreementId = this.agreementId;

      return Promise.resolve()
        .then(async () => {
          /* Quickfix - we have to check that the agreement tied to the advertisement is in DRAFT status and throw error otherwise */
          if (agreementId) {
            const agreements = (await this.actionGetAgreement({ id: agreementId }))
              .response;
            agreements.forEach((agreement) => {
              if (agreement.status !== 'DRAFT') {
                throw new Error(this.blok.alreadyActiveAgreementError);
              }
            });
          }
        })
        .then(async () => {
          if (!agreementId) {
            const result = await this.actionCreateAgreementFromAdvertisement({
              id: this.advertisement.id,
            });
            if (result.response) {
              agreementId = result.response;
            }
          }
        })
        .then(() =>
          this.actionPutAgreement({
            id: agreementId,
            agreement: { tenantProfileId: candidateProfileId },
          }),
        )
        .then(() =>
          this.trackCandidatesAddAsATenantEvent({
            candidateId: candidateProfileId,
            processName: trackingProcessNames.ADVERTISEMENT,
            agreementId: agreementId,
            advertisementId: this.advertisement.id,
          }),
        )
        .then(() => {
          this.$router.push({
            path: this.$localizedPath(`/dashboard/agreement/${agreementId}`),
          });
        })
        .catch((err) => {
          this.reportErrorToSentry(err);
          this.addAsATenantError = err;
        })
        .finally(() => {
          this.addTenantInProgress = false;
        });
    },
    deleteCandidacy(emitCandidateProfileId) {
      this.deleteCandidacyInProgress = true;
      this.actionDeleteCandidacy({
        profileId: emitCandidateProfileId,
        agreementId: undefined,
        advertisementId: this.advertisement.id,
      })
        .then(() =>
          this.trackCandidatesRemoveCandidateEvent({
            candidateId: emitCandidateProfileId,
            processName: trackingProcessNames.ADVERTISEMENT,
            advertisementId: this.advertisement.id,
            agreementId: this.agreementId,
          }),
        )
        .then(() => this.getCandidates())
        .finally(() => {
          this.deleteCandidacyInProgress = false;
        })
        .catch((err) => this.actionReportErrorToSentry(err));
    },
    getCandidates() {
      this.getCandidatesInProgress = true;
      return this.actionGetCandidates({ advertisementId: this.$route.params.objectid })
        .then((result) => {
          if (result.response) this.candidates = result.response;
        })
        .finally(() => {
          this.getCandidatesInProgress = false;
        });
    },

    getAdvertisement() {
      if (this.hasSessionUser) {
        if (this.$route.params.objectid) {
          this.actionGetAdvertisement({ id: this.$route.params.objectid })
            .then((result) => {
              if (result.response) this.advertisement = result.response;
            })
            // Store listing link and address for DigibrokerSuccessModal
            .then(() => {
              this.actionSetDigibrokerSuccessModalInvite({
                link: this.linkToInvite(
                  this.advertisement.invitationShortCode,
                  this.advertisement.workflow,
                ),
                address: this.parseFullAddressFromAddressParameters(this.advertisement),
              });
            })
            .catch((err) => this.actionReportErrorToSentry(err));
        } else {
          this.$router.push({
            path: this.$localizedPath(this.blok.backButtonLink.cached_url),
          });
        }
      } else {
        const path = this.$localizedPath(this.$router.currentRoute.value.path);
        navigateTo(
          {
            path: '/',
            query: {
              login: 'open',
              redirectUrl: path,
            },
          },
          { external: true },
        );
      }
    },

    putAdvertisementStatus(newStatus) {
      return this.actionPutAdvertisement({
        id: this.advertisement.id,
        advertisement: { status: newStatus },
        source: AdvertisementSourceType.WEB_ADVERTISEMENT_EDIT,
      });
    },

    changeAdvertisementDeleteStatus(needToDelete) {
      return this.actionDeleteAdvertisement({
        id: this.advertisement.id,
        deleteStatus: needToDelete,
        source: AdvertisementSourceType.WEB_ADVERTISEMENT_EDIT,
      });
    },

    getDamageProtectionMultiplier() {
      // PL flow 10x, defaults to 10 in any case.
      return get(this.advertisement, 'workflow', undefined) === 'PL' ? 10 : 10;
    },

    getCurrency() {
      // PL flow PLN, defaults to EUR in any case.
      return get(this.advertisement, 'workflow', undefined) === 'PL' ? 'PLN' : 'EUR';
    },

    linkToInvite(invitationShortCode, workflow) {
      if (this.isENVIsProduction) {
        if (workflow === 'EE') {
          return `https://rendin.ee${this.$localizedPath(`/invite/form/${invitationShortCode}`)}`;
        } else {
          return `https://rendin.pl${this.$localizedPath(`/invite/form/${invitationShortCode}`)}`;
        }
      } else {
        return (
          window.location.origin +
          this.$localizedPath(`/invite/form/${invitationShortCode}`)
        );
      }
    },

    openEditMode() {
      this.isEditMode = true;
      this.scrollPageUp();
    },

    closeEditMode() {
      this.isEditMode = false;
      this.openAdvertisementEditViewTab = null;

      this.getAdvertisement();
      this.scrollPageUp();
    },

    onResize() {
      const width = window.innerWidth;
      this.isMobileScreen = width <= 767;
    },

    scrollPageUp() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },

    openSharingModal(location) {
      this.sharingModalLocation = location;
      this.sharingModalOpen = true;
    },

    closeModals() {
      this.showPropertyRendinFeeModal = false;
      this.showDamagesProtectionModal = false;
    },
  },
};
</script>
