<template>
  <r-overlay
    class="handover-skip-step-modal"
    size="small"
    :visible="showModal"
    @close="closeOverlay()"
  >
    <section class="p-4 md:px-8">
      <div
        class="bg-amber-100 w-12 h-12 rounded-full flex justify-center items-center mb-4"
      >
        <r-icon class="text-rendin-300 text-2xl" icon-name="circle-info" />
      </div>

      <div v-if="activeEntityValidationData?.entityType === handoverDataTypes.METERS">
        <h2 class="text-lg font-semibold my-2">
          {{
            $t(
              'agreement.handover_v2.edit_step_change_confirmation.skip_without_meter.title',
            )
          }}
        </h2>

        <div class="text-gray-600">
          {{
            $t(
              'agreement.handover_v2.edit_step_change_confirmation.skip_without_meter.content',
            )
          }}
        </div>
      </div>

      <div v-else>
        <h2 class="text-lg font-semibold my-2">
          {{
            $t(
              'agreement.handover_v2.edit_step_change_confirmation.skip_without_photos.title',
            )
          }}
        </h2>

        <div class="text-gray-600">
          {{
            $t(
              'agreement.handover_v2.edit_step_change_confirmation.skip_without_photos.content',
            )
          }}
        </div>
      </div>
    </section>

    <div class="p-4 w-full flex gap-4 items-center justify-center">
      <r-button
        border-color="light-gray"
        color="black"
        inverted
        :label="
          $t('agreement.handover_v2.edit_step_change_confirmation.continue_editing')
        "
        width="full"
        @click="closeOverlay()"
      />
      <r-button
        :label="$t('agreement.handover_v2.edit_step_change_confirmation.skip_for_now')"
        width="full"
        @click="onClickNextStep()"
      />
    </div>
  </r-overlay>
</template>
<script>
import { handoverDataTypes } from '~/utils/constants';

export default {
  name: 'HandoverSkipStepModal',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    activeEntityValidationData: {
      type: Object,
      default: null,
    },
  },
  emits: ['closeOverlay', 'clickNextStep'],

  data: () => ({
    handoverDataTypes,
  }),

  methods: {
    closeOverlay() {
      this.$emit('closeOverlay');
    },

    onClickNextStep() {
      this.$emit('clickNextStep');
      this.closeOverlay();
    },
  },
};
</script>
